.c-news-slide {
  text-decoration: none;
  background-image: none;
  display: block;
  padding-bottom: rem(2);

  .news-slide__date-category {
    display: flex;
    margin-top: rem(6);
    text-transform: uppercase;
    font-size: $font-size-small;
    line-height: rem(26);
    font-weight: $font-weight-semi-medium;
  }

  .news-slide__title {
    margin-top: $gs4;
    font-size: $font-size-set-text;
    line-height: $line-height-set-h3;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  .news-slide__title--bigger-space {
    margin-top: $gs4;
  }

  .news-slide__image-wrapper {
    position: relative;
    overflow: hidden;
  }

  .news-slide__image--hover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 500ms;
  }

  &[href] {
    @include hover-focus {
      .image__default {
        transform: scale(1.05);
      }
    }

    .image__default {
      transition: transform 400ms $ease-out-quad;
    }
  }

  @include hover-focus {
    .news-slide__image--hover {
      opacity: 1;
    }
  }
}
