.c-content-tile {
  @include hover-focus {
    .content-tile__image-wrapper .image__default {
      transform: scale(1.05);
    }
  }
}

.content-tile__anchor {
  text-decoration: none;
  display: block;
  background-image: none;
}

.content-tile__image-wrapper {
  overflow: hidden;

  .image__default {
    transition: 400ms transform $ease-out-quad, opacity 200ms ease-out;
  }
}
