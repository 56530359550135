@mixin dropdown-absolute-state {
  position: absolute;
  top: 130%;
  left: 0;
  background: var(--color-background-html);
  width: 380px;
  max-height: 484px;
  border: 1px solid var(--color-background);
  @include scroll-container($color-grey, $color-black);
  padding-bottom: 0;
  z-index: 1;
}

.c-dropdown-menu {
  position: relative;
}

.dropdown-menu__title {
  width: 100%;
  align-items: center;
}

.c-dropdown-menu--color {
  @include screen-tablet-landscape-and-bigger {
    .dropdown-menu__dropdown {
      width: 380px;
    }
  }
}

.dropdown-menu__dropdown {
  transition: 200ms ease-in-out;
  transition-property: opacity;
  padding-bottom: $global-spacing * 1.5;

  &[hidden] {
    opacity: 0;
    pointer-events: none;
    display: none;
    visibility: hidden;
  }

  @include screen-tablet-landscape-and-bigger {
    @include dropdown-absolute-state;
  }
}

.dropdown-menu__refinement-item {
  padding: $global-spacing * 1.5 $global-spacing;
  align-items: center;
  width: 100%;
  transition: $button-transition-time $button-transition-ease;
  transition-property: color, background-color, box-shadow;

  .radio__label {
    padding-right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include hover-focus(false) {
    background-color: $color-grey;
  }

  @include keyboard-focus {
    outline: none;
    box-shadow: inset 0 0 0 1px $color-black;
  }

  @include screen-tablet-landscape-and-bigger {
    border-bottom: 1px solid var(--color-background);
    padding: $global-spacing-sm $global-spacing-md;
  }
}
