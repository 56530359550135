.c-product-attribute {
  &:not(.product-attribute--radio) {
    display: flex;
    align-items: center;
    margin-bottom: $global-spacing-sm;
    border: 1px solid var(--color-form-item-default);
  }
}

.product-attribute__label {
  flex-shrink: 0;
  font-weight: $font-weight-bold;
  padding: $global-spacing-xs 0 $global-spacing-xs $global-spacing-sm;
}

.product-attribute__select,
.product-attribute__displayValue {
  flex-grow: 1;
  padding: $global-spacing-xs $global-spacing-sm;
  margin-bottom: 0;

  .select__label {
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
  }

  .select__input {
    border-bottom: 0;
  }

  @include screen-tablet-portrait-and-bigger {
    margin-bottom: 0;
  }
}

.product-attribute__displayValue {
  padding: $global-spacing-sm;
}
