.c-paged-navigation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: $gs48;

  &.paged-navigation--entering {
    animation: fade-in 0.3s linear both;
  }

  &.paged-navigation--exiting {
    animation: fade-out 0.3s linear both;
  }

  &.paged-navigation--exited {
    display: none;
    opacity: 0;
  }
}
