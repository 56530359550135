.product-card__wrapper {
  margin-left: -$global-spacing;
  margin-right: -$global-spacing;

  &--spare-parts {
    .c-product-card {
      &:not(.product-card--horizontal) {
        width: calc(50% - #{$global-spacing-sm});
        margin-bottom: $global-spacing-sm;

        @include screen-tablet-portrait-and-bigger {
          width: calc(25% - #{$global-spacing-sm});
          margin-bottom: 0;
        }
      }
    }
  }
}

.c-product-card {
  text-decoration: none;
  display: block;
  margin-left: $global-spacing;
  margin-right: $global-spacing;

  &:not(.product-card--horizontal) {
    width: 200px;

    @include screen-tablet-portrait-and-bigger {
      width: calc(25% - #{$global-spacing-sm});
    }
  }

  @include hover-focus {
    .product-card__image .image__default {
      transform: scale(1.05);
    }
  }
}

.product-card--horizontal {
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-divider-line-light);
    padding-bottom: $global-spacing-sm;
    margin-bottom: $global-spacing-sm;
  }
}

.product-card--compact {
  width: 100%;

  @include screen-tablet-portrait-and-bigger {
    width: calc(50% - #{$global-spacing-lg});

    &:nth-child(odd) {
      margin-right: $global-spacing-sm;
    }

    &:nth-child(even) {
      margin-left: $global-spacing-sm;
    }

    &:nth-last-child(2):not(:nth-child(even)) {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.product-card--light-source {
  display: flex;
  margin-bottom: rem(16);

  .product-card__image-wrapper {
    width: rem(84);
    height: rem(84);

    @include screen-tablet-landscape-and-bigger {
      width: rem(48);
      height: rem(48);
    }
  }
}

.product-card--suits-for-products {
  display: flex;
  margin: 0 !important;
  padding-bottom: rem(21);
  border-bottom: rem(1) solid $color-light-grey;

  &:not(.product-card--horizontal) {
    width: 100%;

    @include screen-tablet-portrait-and-bigger {
      width: 100%;
    }
  }

  .product-card__image-wrapper {
    display: block;
    width: rem(84);
    min-width: rem(84);
    height: rem(84);

    @include screen-tablet-landscape-and-bigger {
      width: rem(104);
      height: rem(104);
    }
  }

  .product-card__arrow {
    margin: auto rem(3) auto auto;

    .c-icon  {
      polygon {
        fill: $color-black-transparent-60;
      }
    }
  }

  .product-card__text {
    margin-left: 0 !important;
    align-self: unset;

    @include screen-tablet-landscape-and-bigger {
      width: 100%;
    }
  }

  .product-card__product-info  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-black-transparent-60;
    margin-top: rem(16);
  }
}

.product-card__wrapper--bulbs {
  width: 100%;
}

.product-card__wrapper--bulbs:not(:nth-child(1)) {
  margin-top: rem(21) !important;

  @include screen-tablet-landscape-and-bigger {
    border-top: 1px solid $color-light-grey;
    padding-top: rem(32);
    margin-top: rem(32) !important;
  }
}

.c-product-card:not(.product-card--horizontal).product-card--light-source {
  width: 100%;
}

.product-card__image-wrapper {
  width: inherit;

  .product-card--horizontal & {
    width: 100px;
    margin-right: $global-spacing-lg;
  }

  .product-card--compact & {
    width: 50px;
    margin-right: $global-spacing-md;

    @include screen-tablet-portrait-and-bigger {
      width: 80px;
    }
  }
}

.product-card__image {
  overflow: hidden;

  .image__default {
    transition: 400ms transform $ease-out-quad, opacity 200ms ease-out;
  }
}

.product-card__body {
  display: block;
  padding-top: $global-spacing-sm;

  .product-card--horizontal & {
    padding-top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.product-card__arrow {
  display: none;

  .product-card--horizontal & {
    display: flex;
    align-items: center;
    margin-left: $global-spacing-md;
  }
}

.product-card__text {
  margin: 0 0 0 rem(24) !important;
  color: $color-black-grey;
  align-self: center;
  font-weight: $font-weight-bold;

  @include screen-tablet-landscape-and-bigger {
    width: 52%;
  }
}

.product-card__link {
  @include text-small;
  text-decoration: underline;
  color: $color-black-grey;
  align-self: center;
}
