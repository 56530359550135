.c-link-list {
  line-height: 2;

  @include screen-tablet-landscape-and-bigger {
    line-height: 3;
  }

  .experience-assets-textLink {
    @include h3;
    font-weight: $font-weight-regular;
    display: inline;

    &::after {
      display: inline-block;
      margin-left: -9px;
      content: ',';

      @include screen-tablet-landscape-and-bigger {
        margin-left: -12px;
      }
    }

    &:last-child {
      &::after {
        content: '.'
      }
    }
  }

  .c-text-link {
    position: relative;
    text-decoration: none;

    @include screen-tablet-portrait-and-smaller {
      @include multi-line-text--underline(currentColor, 2px, 0);
    }

    @include screen-tablet-landscape-and-bigger {
      @include multi-line-text--underline(currentColor, 3px, 0);
    }

    @include hover-focus {
      color: var(--color-button-ghost-hover);
    }
  }
}
