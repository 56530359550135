.modal--swatch-detail {
  @include z(modalPriority);

  .modal__content {
    padding: 0;
    max-width: 1000px;
  }
}

.c-swatch-detail {
  @include screen-tablet-portrait-and-bigger {
    display: flex;
  }
}

.swatch-detail__toggle {
  align-self: center;
  transition: opacity 300ms;

  @include hover-focus {
    opacity: .85;
  }
}

.swatch-detail__toggle-icon {
  position: absolute;
  bottom: $global-spacing-xxs;
  right: $global-spacing-xxs;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  background-color: var(--color-image-background);
  border: 1px solid currentColor;
}

.swatch-detail__image-wrapper {
  position: relative;
  align-self: flex-start;

  @include screen-tablet-portrait-and-bigger {
    width: 50%;
  }
}

.swatch-detail__download-button {
  position: absolute;
  bottom: $global-spacing-md;
  right: $global-spacing-md;
  width: $global-spacing-xlg;
  height: $global-spacing-xlg;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  background-color: var(--color-image-background);
  border: 1px solid currentColor;
  transition: background-color 300ms;

  @include screen-tablet-portrait-and-bigger {
    bottom: $global-spacing-lg;
    right: $global-spacing-lg;
  }

  @include hover-focus {
    background-color: var(--color-image-background-70);
  }
}

.swatch-detail__content {
  padding: $global-spacing-md;

  @include screen-tablet-portrait-and-bigger {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-xxlg;
  }
}

.swatch-detail__content-info {
  position: relative;
}

.swatch-detail__content-footer {
  margin-top: auto;
}

.swatch-detail__title {
  padding-right: $global-spacing-xlg;
}

.swatch-detail__icon-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.swatch-detail__icon {
  max-width: 30px;
  max-height: 30px;
}

.swatch-detail__close {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 0;


  @include screen-tablet-portrait-and-bigger {
    top: -$global-spacing-sm;
    right: -$global-spacing-sm;
  }

  @include hover-focus {
    color: var(--color-text-dark);
  }

  svg * {
    transition: fill 300ms ease;
  }
}
