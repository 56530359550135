.product-tile__color-swatches {
  min-height: rem(40);
  max-height: rem(40);
  padding-bottom: $global-spacing-sm;

  @include screen-mobile-plus-and-smaller {
    min-height: rem(45);
    max-height: rem(45);
    padding-bottom: $global-spacing-md;
  }

  .product-tile__color-swatches-wrapper {
    display: flex;
    align-items: center;

    .c-product-tile-swatch {
      display: block;
      width: rem(15);
      height: rem(15);
      border-radius: 50%;
      background-color: var(--color-background);
      margin-right: rem(16);
      border: .2px solid var(--color-text-light);
      background-position: center center;
      cursor: pointer;
    }

    .product-tile-swatch--selected {
      border: none;
      outline: .25px solid var(--color-text-light);
      outline-offset: 2px;
    }

    .c-product-tile-swatch--more-colors {
      margin-top: rem(2);
      color: var(--color-text-light);
    }
  }
}
