$input-border-width: 1px;
$input-border-radius: 0;
$input-border-color: var(--color-form-input);
$input-padding-top: $global-spacing;
$input-padding-bottom: $global-spacing;
$input-padding-left: 0;
$input-padding-right: $global-spacing-md;
$input-line-height: normal;
$input-transition-time: 200ms;
$input-transition-ease: $ease-in-out-quad;
$input-search-icon-size: 15px;
$input-search-divider-width: 1px;

.c-input {
  position: relative;
  z-index: 0;

  &:not(.form__item--error):not(.form__item--disabled):not(.form__item--readonly) {
    @include hover-focus(false) {
      .input__input,
      .input__textarea {
        border-color: var(--color-form-item-hover);
      }
      .input__group-label,
      .input__label {
        color: var(--color-form-item-hover-text);
      }
    }
  }
}

.input__group-label,
.input__label {
  font-size: $font-size-input;
  margin-bottom: $global-spacing-xs;
  display: block;
  color: var(--color-form-item-placeholder);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input__input,
.input__textarea {
  display: block;
  appearance: none;
  width: 100%;
  text-overflow: ellipsis;
  padding-top: $input-padding-top;
  padding-bottom: $input-padding-bottom;
  padding-left: $input-padding-left;
  padding-right: $input-padding-right;
  border-radius: $input-border-radius;
  border: 0;
  line-height: $input-line-height;
  border-bottom: $input-border-width solid var(--color-form-item-default);
  background-color: transparent;
  transition: border-color $input-transition-time $input-transition-ease;
  font-size: $font-size-input;
  color: var(--color-form-item-focus-text);

  @include placeholder(var(--color-form-item-placeholder));

  &[disabled],
  &[readonly] {
    color: var(--color-form-item-disabled-text);
    border-color: var(--color-form-item-disabled);
    cursor: default;
  }

  &::-ms-expand {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &:-webkit-auto-fill,
  &:-internal-autofill-selected {
    font-size: $font-size-input;
    font-family: $font-stack-body;
  }

  .form__item--error & {
    border-color: var(--color-error);
    padding-right: $global-spacing-md;
  }
}

.input__textarea {
  border: $input-border-width solid var(--color-form-item-default);
  padding: $input-padding-top;
}

.input__cta {
  position: absolute;
  right: 20px;
  bottom: 15px;
  color: $color-dark-grey;

  &.is--active {
    color: $color-black;
  }

  @include keyboard-focus {
    @include focus-state-pulse($round: true);
  }
}

.input__wrapper {
  position: relative;
}

/**
 * Info toggle
 */
.input__info {
  position: absolute;
  right: 20px;
  bottom: 12px;
}

/**
 * Password toggle
 */
.input--password {
  .input__wrapper {
    .input__label {
      padding-right: $global-spacing-md;
    }

    .input__input {
      padding-right: $global-spacing-md;

      &::-ms-clear,
      &::-ms-reveal {
        display: none;
      }

      &::-webkit-credentials-auto-fill-button {
        position: absolute;
        right: 20px;
        bottom: 15px;
      }
    }

    .input__toggle-password {
      line-height: 0;
      position: absolute;
      right: 0;
      bottom: 11px;
      color: var(--color-form-item-placeholder);

      @include hover-focus(false) {
        color: var(--color-form-hover);
      }

      @include keyboard-focus {
        @include focus-state-pulse($round: true);
      }

      .icon--eye-closed {
        display: none;
      }

      &.is--active {
        .icon--eye {
          display: none;
        }
        .icon--eye-closed {
          display: block;
        }
      }

      svg * {
        transition: fill 300ms $ease-out-quad;
      }
    }
  }
}

/**
 * Not required text / note
 */
.input__note,
.input__not-required-text {
  display: inline-block;
  font-style: italic;
  font-weight: $font-weight-regular;
  color: var(--color-form-item-disabled-text);
  margin-left: $global-spacing;
}

/**
 * Description
 */
.input__description {
  @include text-xsmall;
  display: block;
  margin-top: $global-spacing-xs;
  color: var(--color-form-item-placeholder);
}

/**
 * File
 */
.input--file {
  .input__input {
    cursor: pointer;
    padding-left: $input-padding-right;

    &::before {
      position: absolute;
      text-align: center;
      width: $input-padding-right;
      left: 0;
      content: '\21EA';
    }
  }
}

.transparent {
  color: transparent;
}

.input--has-counter {
  .form__item-error {
    padding-right: 3rem;
  }
}

.input__counter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 7px;
  position: absolute;
  right: 0;

  .input__counter__error {
    display: none;
  }

  span {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    text-align: right;
    color: $color-dark-grey;
  }
}

.is-counter-error {
  .input__counter__error {
    display: block;
  }

  .input__input, .input__counter span {
    color: map-get($colors-map, 'warm-red');
  }

  &.form__item--error {
    .input__counter__error {
      display: none;
    }
  
    .input__input, .input__counter span {
      color: $color-dark-grey;
    }
  }
}
