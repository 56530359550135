//.c-icon-list {}

.icon-list__item {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  font-style: italic;
  @include text-small;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-divider-line);
  }
}

.icon-list__item-icon {
  width: 30px;
  margin-right: 10px;
  flex-shrink: 0;
  line-height: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon-list__url {
  text-decoration: none;

  &:hover {
    .icon-list__item-arrow-wrapper {
      transform: translateX(5px);
    }
  }
}

.icon-list__item-label {
  padding-top: 3px;
}

.icon-list__item-heading {
  font-size: $font-size-set-text-small;
  font-weight: $font-weight-regular;
  text-transform: none;
  transform: translateX(0);
}

.icon-list__item-arrow-wrapper {
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Clean variant */
.icon-list--clean {
  .icon-list__item {
    &:not(:last-child) {
      border: 0;
    }
  }
}


/* Horizontal variant */
.icon-list--horizontal {
  @include screen-tablet-portrait-and-bigger {
    display: inline-flex;
    flex-wrap: wrap;
    transform: translate3d(0, $global-spacing-sm, 0);
  }

  .icon-list__item {
    border-bottom: 0;

    @include screen-tablet-portrait-and-bigger {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: $global-spacing-sm;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .icon-list__item-icon {
    @include screen-tablet-landscape-and-bigger {
      width: auto;
    }
  }
}

/* Carousel variant */
.icon-list--carousel {
  position: relative;

  .icon-list__item {
    height: $header-height-top;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 300ms ease;

    &:not(:last-child) {
      border: 0;
    }

    &.is--active {
      opacity: 1;
      transition: opacity 300ms ease 500ms;
    }
  }
}
