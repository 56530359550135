.c-account-header {
  padding: 0 0 $global-spacing-xlg 0;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

  .c-order-details & {
    padding: 0;
  }

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-xlg 0;
  }
}

.account-header__nav-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.account-header__title-wrapper {
  .c-account-header--cta & {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include screen-tablet-landscape-and-bigger {
      flex-wrap: nowrap;
    }

    .c-button {
      margin-top: $global-spacing-xxxlg;
      margin-bottom: auto;

      @include screen-tablet-landscape-and-bigger {
        margin-top: 0;
      }
    }
  }

  .order-details__card & {
    margin-bottom: $global-spacing-xxlg;
  }
}

.account-header__title {
  @include h2;
  color: var(--color-text);
  margin-bottom: $global-spacing-xs;
  text-align: initial;
  word-break: keep-all;
}

.account-header__intro {
  @include text;
  color: var(--color-text);
  margin-bottom: $global-spacing-sm;
  @include screen-tablet-landscape-and-bigger {
    margin-bottom: $global-spacing-md;
  }
}

.account-header__buttons {
  display: flex;
  justify-content: center;
}

.account-header__cta-logout {
  width: auto;

  @include screen-tablet-landscape-and-bigger {
    display: none;
  }
}
