//
//    Adds a pseudo element that can be used as a focus state for any parent element
//
//    @param round      { boolean }             - square animation per default, with the option to be round
//    @param size       { string }              - amount of pixels/percentage/rem value that the focus state overflows its parent
//    @param color      { string }              - base color for the focus state
//
//    example usage:
//    --------------
//
//    .foo {
//      position: relative;
//      @include focus-state-pulse(true, 20px, $color-foo)
//    }
//

$button-hover-box-extra-width: 9px;
$button-hover-box-extra-height: 6px;

@mixin focus-state-pulse($round: false, $size: 14px, $color: var(--color-error)) {
  &::before {
    display: block;
    width: calc(100% + #{$size});
    height: calc(100% + #{$size});
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: $color;
    animation: focus-pulse-scale 1500ms $ease-in-out-circ infinite;
    opacity: 0.3;
    content: '';

    @if ($round) {
      border-radius: 50%;
    }
  }
}

@mixin focus-state-background-pulse($color: var(--color-error), $pseudo: before) {
  outline: none;

  &::#{$pseudo} {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 0;
    background-color: $color;
    opacity: 0.3;
    animation: focus-pulse 1350ms $ease-in-out-circ infinite;
    content: '';
  }
}

@mixin focus-state-input($height: 2px, $color: var(--color-error)) {
  &::before {
    display: block;
    width: 100%;
    height: $height;
    bottom: -($height / 2);
    position: absolute;
    background-color: $color;
    content: '';
  }
}

@mixin keyboard-focus {
  .has--keyboard-focus & {
    &:focus {
      @content;
    }
  }
}

@mixin focus-state-line-crawl($color: var(--color-error), $pseudo: before, $size: 5px) {
  outline: none;
  background-color: var(--color-button-ghost-focus-background);

  &::#{$pseudo} {
    display: block;
    width: 100%;
    height: $size;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    background-color: $color;
    opacity: 0.3;
    animation: focus-pulse 1350ms $ease-in-out-circ infinite;
    content: '';
  }
}

@mixin hover-focus-line(
  $color: $color-black,
  $top: 100%,
  $pseudo: before,
  $size: 1px,
  $opacity: 0.5
) {
  position: relative;
  transition: color 200ms ease-in-out;

  &::#{$pseudo} {
    position: absolute;
    top: $top;
    left: 0;
    width: 100%;
    height: $size;
    background: $color;
    content: '';
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
    transform: translateY(4px);
  }

  @include hover-focus {
    color: $color;

    &::#{$pseudo} {
      opacity: $opacity;
      transform: translateY(-2px);
    }
  }
}

@mixin hover-focus-outline {
  &::after {
    content: '';
    position: absolute;
    left: -$button-hover-box-extra-width / 2;
    top: -$button-hover-box-extra-height / 2;
    width: calc(100% + #{$button-hover-box-extra-width});
    height: calc(100% + #{$button-hover-box-extra-height});
    border: 1px solid currentColor;
    opacity: 0;
    transition: 300ms ease opacity;
    pointer-events: none;
  }

  @include keyboard-focus {
    text-decoration: none;

    &::after {
      opacity: 1;
    }

    &::before {
      display: none !important;
    }
  }
}
