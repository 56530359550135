.image-zoom__gallery-wrapper {
  width: 100%;
}

.c-image-zoom {
  position: relative;
  overflow: hidden;

  &:not(.has--loader) {
    .c-loader {
      opacity: 0;
    }
  }

  .c-loader {
    transition: none;
  }
}

.image-zoom__click-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: var(--cursor-zoom-in);
  z-index: 3;

  .image-zoom--zoomed & {
    cursor: var(--cursor-zoom-out);
  }
}

.image-zoom--fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--color-image-background);
}

.image-zoom__image-wrapper {
  width: 100%;
  min-height: 100px;
  position: relative;
  transform-origin: center center;
  transition: transform 250ms $ease-out-quad;

  .image-zoom--fullscreen & {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100vh;
    }
  }
}

.image-zoom__image {
  &.image--hidden {
    opacity: 0;
  }
}

.image-zoom__highres-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

//need importants to override product-detail object fit styling
.image-zoom__highres-image {
  background-color: transparent !important; //sass-lint:disable no-important;
  z-index: 2;
}

.image-zoom__button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  background-color: var(--color-image-background-70);
  color: var(--color-text);
  border: 1px solid currentColor;
  transition: background-color 300ms $ease-out-quad;
  z-index: 5;
  line-height: 0;

  @include hover-focus {
    background-color: var(--color-background-inverted-10);
  }

  svg {
    max-width: 16px;
    max-height: 16px;
  }

  @include screen-tablet-portrait-and-bigger {
    width: 40px;
    height: 40px;

    svg {
      max-width: 20px;
      max-height: 20px;
    }
  }
}

.image-zoom__full-screen-button {
  right: 10px;
  bottom: 10px;

  @include screen-tablet-portrait-and-bigger {
    right: 20px;
    bottom: 20px;
  }

  .image-zoom--fullscreen & {
    visibility: hidden;
    opacity: 0;
  }
}

.image-zoom__close-button {
  right: 20px;
  top: 20px;
  visibility: hidden;
  opacity: 0;

  .image-zoom--fullscreen & {
    visibility: visible;
    opacity: 1;
  }
}

.image-zoom__prev-button,
.image-zoom__next-button {
  display: none;
  position: absolute;
  bottom: 25px;
  z-index: 5;

  @include screen-tablet-portrait-and-bigger {
    bottom: auto;
    top: calc(50% - 20px);
  }

  .image-zoom--fullscreen & {
    display: block;
  }
}

.image-zoom__prev-button {
  right: 70px;

  @include screen-tablet-portrait-and-bigger {
    right: auto;
    left: 20px;
  }
}

.image-zoom__next-button {
  right: 20px;
}
