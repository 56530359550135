.c-search-suggestions {
    @include screen-tablet-landscape-and-bigger {
      min-height: 100%;
      display: flex;
      flex-direction: row;
      position: relative;
      padding-bottom: rem(100);
      overflow: hidden;
    }
  }
  
  .search-suggestions__result {
    .c-button--primary {
      color: var(--color-button-primary-text) !important;
  
      @include hover-focus(false) {
        color: var(--color-button-primary-text) !important;
      }
    }
  }
  
  .search-suggestions__label {
    font-weight: $font-weight-medium;
  }
  
  .search-suggestions__list {
    @include list-reset;
  }
  
  .search-suggestions__link {
    text-decoration: none;
    padding-top: $global-spacing-sm;
    display: block;
    background-image: none;
  
    @include hover-focus {
      outline: none;
      color: var(--color-button-ghost-focus-text);
  
      .image__default {
        transform: scale(1.15);
      }
    }
  }
  
  .search-suggestions__link-body {
    line-height: 1.2;
  }
  
  .search-suggestions__link-image-wrapper {
    width: rem(215);    
    overflow: hidden;
    background-color: var(--color-background-inverted-10);
  }
  
  .search-suggestions__link-image {
    img {
      width: 100%;
      height: 100%;
    }
  
    .image__default {
      transition: 400ms transform $ease-out-quad, opacity 200ms ease-out;
    }
  }
  