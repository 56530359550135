.c-explore-link-list {
  &--secondary-background {
    background-color: $color-light-grey;
  }

  &--secondary {
    .c-explore-link {
      align-items: flex-end;
      padding-bottom: rem(28);

      @include screen-tablet-landscape-and-bigger {
        padding-bottom: rem(38);
      }

      .explore-link__arrow {
        display: none;
      }

      .explore-link__label {
        font-size: rem(35);
        line-height: rem(40);

        @include screen-tablet-landscape-and-bigger {
          font-size: rem(50);
          line-height: 100%;
          font-weight: $font-weight-semi-medium;
        }
      }
    }
  }

  .experience-assets-exploreLink:first-child {
    .c-explore-link {
      .explore-link__image {
        opacity: 1;
      }
    }
  }

  .explore-block__column {
      width: initial;
      padding: rem(45) 0 rem(58);

      @include screen-tablet-landscape-and-bigger {
          padding: $gs72 0 $gs64;
      }

      .o-grid {
          position: relative;
          display: flex;
          justify-content: space-between;
      }
  }
}

.c-explore-link-list--no-image {
  .explore-link__image {
      display: none;
  }

  .c-explore-link {
      width: 100%;
  }

  .explore-link-list__links {
    min-height: unset;
  }
}

.explore-text__content {
  margin-bottom: rem(38);

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: rem(54);
  }
}

.explore-link-list__content {
  position: relative;

  @include screen-tablet-landscape-and-bigger {
    min-height: rem(495);
  }
}

.explore-link-list__links {
  @include screen-tablet-landscape-and-bigger {
      min-height: rem(280);
  }

  @include screen-desktop-plus-and-bigger {
      min-height: rem(400);
  }
}

.explore-link-list__title {
  @include h5;

  @include screen-desktop-and-bigger {
      width: 60%;
  }
}

.explore-link-list__subtitle {
  margin-top: rem(11);
  font-size: rem(40);
  line-height: 100%;
  font-weight: $font-weight-semi-medium;

  @include screen-tablet-landscape-and-bigger {
    margin-top: $gs16;
  }
}

.experience-exploreLinkList {
  .experience-assets-exploreLink {
    &:last-child {
      .c-explore-link {
        padding-bottom: 0;
      }
    }
  }
}
