.c-usp-banner {
  @include z(search);
  position: relative;
  padding: $global-spacing * 1.5 0;
  background-color: var(--color-success);

  a {
    text-decoration: none;

    @include hover-focus(false) {
      text-decoration: underline;
    }
  }

  ul {
    @include list-reset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  li {
    color: $color-white;

    &:not(:first-child) {
      display: none;
    }
  }

  .is--account & {
    background-color: var(--color-info);

    li {
      color: var(--color-info);
    }
  }

  @include screen-tablet-landscape-and-bigger {
    li {
      padding: 0 $global-spacing * 6;

      &:not(:first-child) {
        display: block;
      }
    }
  }
}
