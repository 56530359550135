$country-gateway-bar-height: rem(48);
$country-gateway-bar-border-size: rem(1);

.c-country-gateway {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.country-gateway__bar {
  @include screen-tablet-portrait-and-smaller {
    border-bottom: $country-gateway-bar-border-size solid var(--color-divider-line);
  }
}

.country-gateway__bar--bottom {
  margin-top: auto;

  @include screen-tablet-portrait-and-smaller {
    border-bottom: none;
    border-top: $country-gateway-bar-border-size solid var(--color-divider-line);
  }

  @include screen-tablet-landscape-and-bigger {
    padding-top: $global-spacing-xxxlg;
  }
}

.country-gateway__bar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: $country-gateway-bar-height;
  padding: $global-spacing-xs 0;

  .c-button {
    margin-bottom: 0;
  }

  .o-button-holder {
    white-space: nowrap;
  }

  @include screen-tablet-portrait-and-smaller {
    .country-gateway__bar--bottom & {
      display: block;
    }
  }

  @include screen-tablet-landscape-and-bigger {
    border-bottom: $country-gateway-bar-border-size solid var(--color-divider-line);

    .country-gateway__bar--bottom & {
      border-bottom: none;
      border-top: $country-gateway-bar-border-size solid var(--color-divider-line);

      .country-gateway__bar-content-description {
        margin-bottom: 0;
      }
    }
  }
}

.country-gateway__content {
  @include screen-tablet-landscape-and-bigger {
    display: flex;
    min-height: calc(100% - #{$country-gateway-bar-height + $country-gateway-bar-border-size});
  }
}

.country-gateway__list {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $global-spacing * 4.5;
  margin-bottom: $global-spacing * 7;

  @include screen-tablet-portrait-only {
    grid-template-columns: repeat(3, 1fr);
  }

  @include screen-tablet-landscape-and-bigger {
    grid-template-columns: repeat(5, 1fr);
  }
}

.country-gateway__item {
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: $global-spacing-xs;
  }
}

.country-gateway__item-image {
  display: inline-block;
  position: relative;
  margin-right: $global-spacing-xs;
  width: rem(24);
  top: rem(-2);

  .image__default {
    border-radius: rem(2);
  }
}

.country-gateway__item-label {
  display: block;
  font-weight: $font-weight-bold;
}

.country-gateway__options-wrapper {
  display: flex;
  flex-direction: column;
  column-gap: $global-spacing-xlg;

  @include screen-tablet-portrait-and-bigger {
    flex-direction: row;
    align-items: center;
  }
}

.country-gateway__language-selector {
  font-weight: $font-weight-bold;
  margin-bottom: $global-spacing-xlg;

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: 0;
  }
}
