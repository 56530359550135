.u-h1 {
  @include h1;
}

.u-h1--large {
  @include h1-large;
}

.u-h1-secondary {
  @include h1-secondary;
}

.u-h2 {
  @include h2;
}

.u-h2-secondary {
  @include h2-secondary;
}

.u-h3 {
  @include h3;
}

.u-h3-secondary {
  @include h3-secondary;
}

.u-h4 {
  @include h4;
}

.u-h5 {
  @include h5;
}

.u-h5--large {
  @include h5-large;
}

.u-h6 {
  @include h6;
}

.u-p-large {
  @include p-large;
}
