.o-button-holder {
  .c-button {
    margin: 0 $global-spacing-sm $global-spacing-sm 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &--fill {
    display: flex;
    flex-direction: column;
    margin-top: $global-spacing-sm;

    .c-button {
      width: 100%;
      margin: 0;

      + .c-button {
        margin: $global-spacing-sm 0 0 0;
      }
    }

    @include screen-tablet-portrait-and-bigger {
      flex-direction: row;
      justify-content: center;

      .c-button {
        width: 50%;

        + .c-button {
          margin: 0 0 0 $global-spacing-sm;
        }
      }
    }
  }
}
