/**
 * With tooltip
 */

.input--has-tooltip {
  display: flex;
  flex-wrap: wrap;

  .input__wrapper {
    flex-grow: 1;
  }

  .input__tooltip {
    align-self: flex-end;
    margin-bottom: 6px;
    margin-left: $global-spacing-xs;
  }
}

.registerTooltip {
  background-color: unset !important;
  margin-left: - $global-spacing * 3;
  padding-top: 8px !important;

  @include screen-tablet-landscape-and-smaller {
    margin-left: 0;
  }
}

.tooltip-container {
  display: flex;
}

