.c-sample-products {
  .modal__content {
    overflow: hidden;
  }

  .c-sample-products__sample-button {
    display: flex;
    padding: $global-spacing-lg;
    background-color: $color-light-grey;
    border: 1px solid $color-grey;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .c-samples-products__sample-button-text {
      text-align: left;
      margin-left: $global-spacing-sm;
    }

    .c-samples-products__sample-button-title {
      font-weight: $font-weight-medium;
      font-size: 16px;
      line-height: 16px;
    }

    .c-samples-products__sample-button-description {
      font-weight: $font-weight-regular;
      font-size: 14px;
      line-height: $font-size-set-text-xsmall;
    }
  }

  .modal__background {
    display: none;
  }
}

.sample-products__modal {
  height: 100%;
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  z-index: 2;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row;
    justify-content: space-between;
  }
}

.sample-products__form,
.sample-products__start-pack {
  height: 100%;
  overflow: hidden;

  &.is--disabled {
    .checkbox__input:not(:checked) + .checkbox__label {
      opacity: .5;
      pointer-events: none;
    }
  }

  @include screen-tablet-landscape-and-bigger {
    width: 50%;
  }

  @include screen-laptop-and-bigger {
    width: 40%;
  }
}

.sample-products__start-pack {
  position: relative;
}

.sample-products__modal-collection {
  position: absolute;
  top: 24px;
  left: 25px;
  font-size: $font-size-set-text-large;
  line-height: $line-height-set-text-large;
  font-weight: $font-weight-medium;
  z-index: 1;

  @include screen-tablet-landscape-and-bigger {
    top: 68px;
    left: 42px;
    font-size: 42px;
  }
}

.sample-products__modal-collection--min {
  display: none;

  @include screen-tablet-landscape-and-bigger {
    display: block;
  }
}

.sample-products__homepage-usps {
  .icon-list__item {
    font-style: normal;
  }
}

.sample-products__modal-close {
  position: absolute;
  top: 24px;
  right: 25px;
  @include text-small;
  font-weight: $font-weight-medium;
  z-index: 1;

  @include screen-tablet-landscape-and-bigger {
    right: 42px;
  }
}

.sample-products__modal-action {
  display: none;

  @include screen-tablet-landscape-and-bigger {
    display: block;
    position: absolute;
    @include text-small;
    @include z(modal);
    font-weight: $font-weight-medium;
    top: 124px;
    left: 42px;
  }
}

.sample-products__product-images {
  display: flex;
  position: relative;
  height: 40%;
  justify-content: center;
  align-items: center;
  background-color: var(--color-image-background);

  .image__default {
    mix-blend-mode: multiply;
  }

  @include screen-tablet-landscape-and-bigger {
    flex: 1;
    height: unset;
  }
}

.sample-products__product-images--image-min {
  display: none;

  @include screen-tablet-landscape-and-bigger {
    display: flex;
  }
}

.sample-products__product-image,
.sample-products__packshot-image {
  height: 100%;

  .c-image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sample-products__content {
  height: 100%;
  position: relative;
  overflow-y: auto;
  padding: $global-spacing-md;
  background: linear-gradient(170deg, $color-white 0 80%, $color-grey);

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-xlg;
  }
}

.sample-products__content--new-pack {
  background: unset;
}

.sample-products__items {
  display: flex;
  flex-wrap: wrap;
}

.sample-products__item {
  width: 80px;
  flex-direction: column;

  .checkbox__label {
    flex: 1;
    padding: 0;
    transition: opacity 300ms ease;
    text-align: center;
    text-decoration: underline;
    justify-content: flex-start;

    &::before,
    &::after {
      display: none;
    }

    &::after {
      background-color: var(--color-background-html);
    }

  }

  label {
    color: var(--color-text);
    transition: background-color 300ms ease;
  }

  .checkbox__input:checked + label {
    .sample-products__item-button {
      display: none;
    }

    .sample-products__item-button--remove {
      display: block;
    }
  }
}

.sample-products__footer-item-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $color-black;
}

.sample-products__item-button {
  user-select: none;
  color: $color-black;
}

.sample-products__content-right-title {
  font-size: 42px;
  line-height: 42px;
  font-weight: $font-weight-medium;
  margin-top: $global-spacing-xlg - 4;
}

.sample-products__sidebar-button {
  @include text-small;
  padding: 15px 20px 10px;
}

.sample-products__homepage-right-content-group {
  @include screen-tablet-landscape-and-bigger {
    max-width: 450px
  }
}

.sample-products__homepage-right-title {
  font-size: 42px;
  line-height: 42px;
  font-weight: $font-weight-medium;
  margin-top: $global-spacing-xlg - 4;
}

.sample-products__item-button--remove {
  display: none;
}

.sample-products__status-bar {
  padding: $global-spacing $global-spacing-md;
  border: 1px solid var(--color-divider-line);

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing $global-spacing-xlg;
  }
}

.sample-products__check-icon {
  position: absolute;
  top: 0;
  left: 0;
  color: $color-black;
  border: 1px solid $color-black;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sample-products__item-line {
  display: block;
  background-color: currentColor;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -62px;
  opacity: 0;
  transition: opacity 300ms ease;
}

.sample-products__item-image {
  width: 80px;
  height: 80px;
}

.sample-products__footer {
  flex-shrink: 0;
  width: 100%;
  padding: $global-spacing-sm $global-spacing-md;
  border: 1px solid var(--color-divider-line);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0 20%, $color-white);

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-md $global-spacing-xlg;
  }

  .c-button {
    width: 100%;
    flex: 1;
  }
}

.sample-products__footer-items {
  flex: 1;
  position: relative;
  min-height: 48px;

  @include screen-tablet-landscape-and-bigger {
    min-height: 52px;
    flex: 2;
  }
}

.sample-products__footer-items-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sample-products__footer-item {
  position: relative;
  width: 48px;
  height: 48px;
  margin-right: 4px;
  display: none;
  background-color: $color-grey;

  @include screen-tablet-landscape-and-bigger {
    width: 52px;
    height: 52px;
  }

  &.is--active {
    display: block;
  }

  .sample-products__footer-items-shadow & {
    display: block;
  }

  &.is--cart {
    &::after {
      content: '';
      display: block;
      width: 140%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(45deg);
      transform-origin: 0 0;
      background-color: var(--color-divider-line);
    }
  }
}

.sample-products__item-name {
  text-align: left;
  display: flex;
  flex-grow: 1;
  @include text-small;
}

.sample-products__footer-item-outline {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-divider-line);
}

.sample-products__footer-error {
  color: var(--color-error);
}
