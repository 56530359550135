// sass-lint:disable no-vendor-prefixes

@mixin placeholder($color: currentColor) {
  &::-webkit-input-placeholder {
    color: $color;
    @content;
  }

  &::-moz-placeholder {
    color: $color;
    @content;
  }

  &:-ms-input-placeholder {
    color: $color;
    @content;
  }
}
