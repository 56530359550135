$line-item-image-width-desktop: 104px;
$line-item-image-height-desktop: 130px;
$line-item-image-width-mobile: 86px;
$line-item-image-height-mobile: 100px;

.c-placeholder {
  background-color: $color-light-grey;
  display: flex;
  align-items: center;
  justify-items: center;
  min-height: 100%;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: $font-size-root;
  font-weight: $font-weight-medium;
  letter-spacing: 0.15rem;
  padding: $global-spacing;
  color: $color-dark-grey;

  @include screen-tablet-portrait-and-bigger {
    font-size: $font-size-small;
  }
}

.c-placeholder--line-item {
  min-height: $line-item-image-height-mobile;
  width: $line-item-image-width-mobile;

  @include screen-tablet-portrait-and-bigger {
    min-height: $line-item-image-height-desktop;
    width: $line-item-image-width-desktop;
  }

  .c-product-line-item--order & {
    max-width: 100%;
  }
}
