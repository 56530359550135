.c-card {
  &--border {
    background-color: var(--color-background-html);
    padding: $global-spacing * 3 $global-spacing * 2;
    border: 1px solid var(--color-divider-line);
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &--standalone {
    margin-bottom: $global-spacing-xxxlg;

    .button--block-md + .button--block-md {
      margin-top: $global-spacing-md;
    }

    @include screen-tablet-landscape-and-bigger {
      margin-bottom: $global-spacing-xxxxlg;

      .button--block-md + .button--block-md {
        margin-top: auto;
      }
    }
  }

  &--block {
    display: flex;
    flex-direction: column;
    border: none;
    padding-bottom: $global-spacing-xxxlg;

    @include screen-tablet-landscape-and-bigger {
      padding-bottom: $global-spacing-xlg;
    }
  }
}

.card__header {
  padding-bottom: $global-spacing-md;
  margin-bottom: $global-spacing-md;
  border-bottom: 1px solid var(--color-divider-line);
}

//.card__title {}

.card__body-heading {
  @include text-xsmall;
  color: var(--color-text-light);
  margin-bottom: $global-spacing / 2;
}

.card__body-data {
  margin-bottom: $global-spacing / 2;

  &--phone {
    margin-top: $global-spacing-sm;
    text-decoration: none;
    display: block;
  }
}

.card__footer {
  margin-top: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: $global-spacing-lg;
}

.card__body-list {
  &:not(:last-child) {
    margin-bottom: $global-spacing-lg;
  }
}

.card__order-items {
  min-width: 100%;

  .c-card--history & {
    @include screen-tablet-landscape-and-bigger {
      padding-bottom: $global-spacing-md;
      border-bottom: 1px solid var(--color-divider-line);
    }
  }
}

.card__order-item {
  @include text-xsmall;
  background-color: var(--color-background);
  flex-basis: 80px;
  min-width: 80px;
  height: auto;
  color: var(--color-text-light);

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.card__order-info {
  max-width: calc(4 * 80px + 3 * #{$global-spacing-sm});
}

.card__action {
  .c-card--block & {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .c-button {
      margin: 0 0 auto auto;
      font-weight: normal;
      color: var(--color-text-light);
    }
  }
}

.account-type-switch {
  margin-left: 8.33333%;
  padding-left: 4px;
  width: 83%;
  margin-bottom: $global-spacing-xxxlg;

    @include screen-tablet-landscape-and-bigger {
      margin-bottom: $global-spacing-xxxxlg;
    }
}

.account-card-hidden {
  display: none;
}


