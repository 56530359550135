@mixin radio-checked {
  &::before {
    transform: scale(1);
  }

  &::after {
    border-color: var(--color-form-item-focus);
  }

  .radio__count {
    color: var(--color-button-primary-background-hover);
  }
}

.c-radio {
  &.checked {
    > .radio__label {
      @include radio-checked;
    }
  }
}
