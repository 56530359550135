@mixin divider-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  &::before,
  &::after {
    display: block;
    width: 50%;
    flex: 1;
    height: 1px;
    background-color: $color-light-grey;
    content: '';
  }
}

@mixin divider-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &::before,
  &::after {
    height: 50%;
    display: block;
    flex: 1;
    width: 1px;
    background-color: $color-light-grey;
    content: '';
  }
}

.u-divider-horizontal {
  @include generate-mediaquery-rules {
    @include divider-horizontal;
  }
}

.u-divider-vertical {
  @include generate-mediaquery-rules {
    @include divider-vertical;
  }
}
