.c-secondary-menu {
  display: none;
  padding: 0;
  list-style: none;
  align-items: center;
  margin-right: $global-spacing;

  @include screen-tablet-portrait-and-bigger {
    display: flex;
  }
}

.secondary-menu__item {
  padding: 0 0 0 $global-spacing;
}

.secondary-menu__link {
  display: block;
  position: relative;
}
