.c-store-locator {
  height: 100%;
  display: flex;
  flex-direction: column;

  .input__cta {
    right: unset;
    color: $color-black;
    height: rem(15);
    width: rem(15);
  }

  .c-loader {
    position: fixed;
  }

  .search-results__filter-button {
    font-size: rem(13);
    margin-left: 0;

    svg {
      width: rem(16);
      height: rem(16);
      padding-top: rem(6);
    }
  }

  .c-paged-navigation.refinement-menu--landing {
    padding: rem(16);

    @include screen-tablet-landscape-and-bigger {
      padding: rem(153) rem(16) rem(24) rem(48);
      margin-top: 0;
    }
  }

  .refinement-menu-title {
    @include text;

    font-weight: $font-weight-bold;
    text-transform: uppercase;
    margin-bottom: rem(81);
  }

  .refinement-menu__actions {
    margin-top: rem(84);
  }

  .c-refinement-options--section {
    margin: rem(34) 0 0;
  }
}

.store-locator__header {
  margin-bottom: $global-spacing * 4;

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: 0;
  }
}

.store-locator__cookie-message {
  padding: rem(20) 0 0;
  margin-bottom: 0;
}

.store-locator__result {
  display: flex;
  flex-direction: column;
  width: 100%;

  .paged-navigation-modal__content {
    margin: 0 auto 0 0;
  }
}

.store-locator__result-title {
  display: block;
  font-size: $font-size-set-h6;
}

.store-locator__result-nearest {
  display: none;

  @include screen-tablet-landscape-and-bigger {
    display: block;
    margin-bottom: $global-spacing * 4;
    line-height: $line-height-set-text;
    color: $color-tinted-grey;
  }
}

.store-locator__location-not-found {
  display: block;
}



.store-locator__result-title--hidden,
.store-locator__result-nearest--hidden,
.store-locator__location-not-found--hidden {
  display: none !important;
}

.store-locator__title {
  @include text;
}

.store-locator__content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.store-locator__sidebar {
  @include screen-tablet-landscape-and-bigger {
    display: flex;
    flex-direction: column;
    max-width: rem(437);
  }
}

.store-locator__sidebar--map-is-active {
  visibility: visible !important;
}

.store-locator__form {
  @include screen-tablet-landscape-and-bigger {
    width: 50%;
    position: relative;
    z-index: 2;
  }
}

.store-locator__filter-results {
  align-items: center;
  width: 100%;
  padding-bottom: rem(18);
  border-bottom: 1px solid $color-black;

  @include screen-tablet-landscape-and-bigger {
    border-bottom: none;
  }
}

.store-locator__form-input {
  display: flex;
  align-items: flex-start;
  margin: rem(45) 0 rem(16);

  @include screen-tablet-landscape-and-bigger {
    margin: rem(45) 0 rem(33);
  }

  img {
    cursor: pointer;
  }

  .form__item {
    width: 100%;
    margin: 0;
  }

  .input__input {
    line-height: $line-height-set-text;
    background-color: transparent !important;
    padding-left: rem(26);
    border-bottom: none;
    padding-top: 0;

    &::placeholder {
      color: $color-grey;
    }
  }

  .c-icon.icon--arrow-storelocator-search-arclinea {
    margin-top: rem(3);
  }
}

.store-locator__form-input--address {
  flex: 1 0 65%;

  @include screen-tablet-portrait-and-bigger {
    flex: 1;
  }
}

.store-locator__amount-found {
  color: $color-black;
  margin-bottom: $global-spacing * 4;

  .c-modal & {
    display: none;
  }
}

.store-locator__results-row {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row;
  }
}

.store-locator__results {
  overflow: auto;
  height: 100%;
  background-color: $color-white;
}

.store-locator__tabs {
  margin: rem(40) 0 0;

  .tabs__nav {
    padding: 0;
    font-size: $font-size-set-h6;

    &::before {
      content: initial;
    }
  }
  .tabs__nav-item {
    width: unset;
  }

  .tabs__nav-item:first-child {
    margin: 0 $global-spacing / 2 0 0;
  }

  .tabs__nav-button {
    padding: 0 rem(30) 0 0;
    cursor: pointer;
    font-weight: $font-weight-semi-medium;
    color: $color-black;
    text-transform: uppercase;

    &:not(.tabs__nav-button--is-active) {
      opacity: 1;
      background-color: unset;
    }
  }

  .tabs__nav-button--is-active {
    font-weight: $font-weight-bold;
    box-shadow: initial;
  }
}

.store-locator__tabpanel {
  visibility: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  &.is--active,
  &.is--active-desktop {
    visibility: visible;
  }

  &.is--active-desktop {
    @include screen-tablet-landscape-and-bigger {
      position: static;
    }
  }
}

.store-locator__no-results,
.store-locator__location-not-found {
  @include h6;
  font-weight: $font-weight-bold;
  margin-top: rem(16);

  .cookies-message {
    font-weight: $font-weight-regular;
  }
}

.store-locator__location-cookie-link {
  display: block;
  font-weight: 400;
}

.store-locator__store {
  border-bottom: 1px solid $color-tinted-grey;
}

.store-locator__map-wrapper {
  display: none;
  height: rem(675);

  @include screen-tablet-landscape-and-bigger {
    display: flex;
    position: sticky !important;
    top: 5%;
    flex: 1 0 63%;
    margin-left: $gs16;
  }
}

.store-locator__selected-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  background-color: $color-white;
  z-index: 10;
  padding: rem(17) rem(15) rem(0);
  box-shadow: rem(0) rem(-3) rem(10) $color-black-transparent-25;
  border-radius: rem(5);
  transition: transform 0.5s;

  @include screen-tablet-landscape-and-bigger {
    display: none;
  }

  .store-locator__selected-modal-line {
    width: rem(50);
    height: rem(3);
    background-color: $color-grey;
    border-radius: rem(3);
    margin-bottom: rem(35);
  }

  .c-icon.icon--arrow-right-arclinea {
    margin-left: rem(5);
    width: rem(20);
    height: rem(16);
  }
}

.store-locator__selected-content--active {
  margin-bottom: rem(67);
}

.store-locator__map-wrapper.store-locator__tabpanel.is--active {
  display: flex;
}

.store-locator__map-selected-store {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-white;
  padding: $global-spacing * 2 $global-spacing;

  @include screen-tablet-landscape-and-bigger {
    display: none;
  }

  .store__hours {
    display: none;

    &.is--expanded {
      display: flex;
    }
  }
}

.c-store-locator--page {
  .store__maps-link {
    transition: 0s;
    font-size: $font-size-set-h6;
  }

  .store-locator__item {
    width: 100%;
    text-align: left;

    &:first-child {
      padding-top: 0;
  
      @include screen-tablet-landscape-and-bigger {
        border-top: 1px solid $color-black;
        padding-top: rem(30);
      }
    }
  
    &:last-child {
      border-bottom: 1px solid $color-darker-grey;
    }
  }

  .store-locator__item--active {
    border-color: transparent;

    .store__maps-link {
      @include hover-focus {
        color: $color-white;
      }
    }
    
    * {
      color: $color-white;
      position: relative;
      z-index: 2;
    }

    &:first-child {
      &::before {
        top: rem(-20);

        @include screen-tablet-landscape-and-bigger {
          top: rem(-1);
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: rem(-16);
      right: rem(-16);
      top: rem(-1);
      bottom: rem(-1);
      background-color: $color-dark-grey;
      z-index: 1;
    }
  }

  .accordion__item--is-active {
    border-top: 0;
  }

  .store-locator__map-selected-store {
    position: absolute;
  }

  .store-locator__form {
    margin-bottom: 0;
    width: 100%;
    display: flex;
  }

  .store-locator__form-row {
    display: flex;
    flex-direction: column;
  }

  .store-locator__amount-found {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .store-locator__stores-found-text {
    margin-left: $global-spacing / 2;
  }

  .store-locator__form-input--address {
    margin-left: 0;
  }

  .store-locator__results--map-is-active {
    display: none;

    @include screen-tablet-landscape-and-bigger {
      display: block;
    }
  }
}

.store-locator-legend {
  background-color: $color-light-grey;

  ul {
    list-style: none;
    margin: 0;
    padding: 1.3rem 2rem;
    display: flex;
    align-items: center;

    @include screen-tablet-landscape-and-bigger {
      padding: 1.3rem 0.6rem;
    }

    li {
      margin-right: 2.3rem;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.8rem;
      text-align: left;
      display: flex;
      align-items: center;

      @include screen-tablet-landscape-and-bigger {
        margin-right: 3.8rem;
      }
    }

    img {
      margin-right: 0.3rem;
      max-width: 2.3rem;
    }
  }
}

#store-locator-legend {
  display: none;
  @include screen-tablet-landscape-and-bigger {
    display: block;
  }
}


#store-locator-legend-mobile {
  max-width: calc(100% + 3.2rem);
  margin: 0 -1.6rem 1.3rem;

  @include screen-tablet-landscape-and-bigger {
    display: none;
  }
}
