$link-transition-time: 200ms;
$link-transition-ease: $ease-in-out-quad;

// most basic link style
@mixin link--base {
  color: currentColor;
  text-overflow: ellipsis;
  transition: $link-transition-time $link-transition-ease;
  transition-property: border, color, background-color, opacity;

  &[href] {
    cursor: pointer;
  }

  &:not([class]) {
    @include hover-focus {
      text-decoration: none;
    }
  }
}

// reverse basic link style
@mixin link--base-reversed {
  text-decoration: none;

  @include hover-focus {
    text-decoration: underline;
  }
}

// underline button override for simple link with after underline hover
// add 'bold' to keep font-weight bold, default = regular weight
@mixin underline-button-clean($bold:false) {
  @if $bold != bold {
    font-weight: $font-weight-regular;
  }

  .button__label {
    background-image: none;
  }

  &::after {
    opacity: 0;
    transition-property: background-color, opacity;
  }
}

a {
  @include link--base;
}

.has--keyboard-focus {
  a[href]:focus {
    outline: none;
    position: relative;
    @include focus-state-line-crawl;
  }
}
