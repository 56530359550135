$add-to-cart-divider-height: 20px;
$svg-add-to-cart-loader: '<svg focusable="false" class="c-icon icon--loading" viewBox="0 0 19 18" width="30px" height="30px"><g><path fill="#ffffff" d="M5,5.8H1.7L4,3.4C4.8,2.6,5.6,2,6.5,1.6c1.9-0.8,4-0.8,5.9,0c0.9,0.4,1.8,1,2.5,1.7l0.7-0.7c-0.8-0.8-1.8-1.5-2.8-2 c-2.1-0.9-4.6-0.9-6.7,0c-1.1,0.5-2,1.1-2.8,2L1,5V1.6H0v5.1h5V5.8z"></path><path fill="#ffffff" d="M14,11.2v1h3.3L15,14.6c-0.7,0.7-1.6,1.3-2.5,1.7c-1.9,0.8-4,0.8-5.9,0c-0.9-0.4-1.8-1-2.5-1.7l-0.7,0.7 c0.8,0.8,1.8,1.5,2.8,2C7.2,17.8,8.3,18,9.5,18c1.2,0,2.3-0.2,3.3-0.7c1.1-0.5,2-1.1,2.8-2L18,13v3.4h1v-5.1H14z"></path></g></svg>';

@mixin button--add-to-cart {
  width: 100%;
  height: 60px;
  padding: 2px 20px 0;

  .button__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .button__label {
    flex: 1;
    flex-shrink: 0;
  }

  .button__price {
    flex: 1;
    margin-right: $global-spacing-sm;
  }

  .c-price {
    position: relative;
    padding-right: $global-spacing-sm;
    justify-content: center;

    &::after {
      content: '';
      width: 1px;
      height: $add-to-cart-divider-height;
      position: absolute;
      right: 0;
      top: calc(50% - 12px);
      background: var(--color-divider-line-dark);
    }
  }

  .price__items {
    justify-content: center;
  }

  .price__item--sales {
    order: 2;
    color: var(--color-button-primary-text);

    &:not(:last-child) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .price__item--list {
    @include text-xsmall;
    order: 1;

    &:not(:first-child) {
      margin-left: 0;
      margin-right: $global-spacing;
    }
  }

  .button__success {
    display: none;
    align-items: center;
  }

  // disabled state
  &[disabled] {
    .price__item--sales {
      color: var(--color-button-primary-font-disabled);
      font-weight: $font-weight-regular;
    }
    .c-price {
      &::after {
        opacity: .2;
      }
    }
  }

  // Success state
  &.is--success {
    background-color: var(--color-success);
    color: var(--color-text);
    font-weight: $font-weight-medium;
    pointer-events: none;

    .button__success {
      display: flex;
    }

    .button__wrapper {
      display: none;
    }
  }

  .c-loader {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .loader__spinner {
    @include background-svg($svg-add-to-cart-loader);
  }
}
