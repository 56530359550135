//.c-seo-description

.seo-description__content {
  @include text-small;
  margin-bottom: $global-spacing-sm;

  .seo-description--long & {
    margin-bottom: $global-spacing;
  }

  &:not(.toggle--is-active) {
    @include line-clamp;

    .seo-description--long & {
      @include line-clamp(3);
    }

    > *,
    li {
      display: inline;
    }
  }

  ul,
  ol {
    @include text-small;
  }
}
