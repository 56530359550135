.accordion__item-header {
  font-weight: $font-weight-bold;
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: $accordion-item-header-padding;
  transition: $accordion-transition-time $accordion-transition-ease $accordion-transition-time / 2;

  .accordion__item--specs & {
    padding: 14px $global-spacing-lg 10px 0;
    text-transform: uppercase;

    @include screen-tablet-landscape-and-bigger {
      padding: 18px $global-spacing-lg 14px 0;
      font-size: 2.4rem;
    }
  }

  .accordion__item--is-active & {
    transition: $accordion-transition-time $accordion-transition-ease;
  }

  &.accordion__item-header--mobile-only {
    @include screen-tablet-portrait-and-bigger {
      display: none;
    }
  }

  &.accordion__item-header--tablet-up {
    display: none;

    @include screen-tablet-portrait-and-bigger {
      display: block;
    }
  }

  @include keyboard-focus {
    @include focus-state-pulse;
  }
}
