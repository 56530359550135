.c-paged-navigation-header {
  display: flex;
  align-items: center;
  padding: 0 $global-spacing-md $global-spacing-lg rem(15px);

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-lg $global-spacing-lg $global-spacing-md $global-spacing-lg;
  }
}

.paged-navigation-header__button {
  position: static;
  width: 7px;
  height: 16px;
  margin-right: $gs8;

  &.modal__button-close {
    transform: translate3d(10px, 2px, 0);
  }
}
