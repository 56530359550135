.c-collapsible__text {
    margin-bottom: $gs8;
}

.c-collapsible__text-block {
    display: none;

    &.c-collapsible__text--active {
        display: inline;
    }
}

.c-collapsible__text-toggle {
    @include multi-line-text--underline($color-medium-grey);
    
    cursor: pointer;
    color: $color-medium-grey;
    margin-bottom: $gs48;
    display: inline-block;

    @include screen-tablet-landscape-and-bigger {
        margin-bottom: 0;
    }

    .c-button {
        padding: 0;

        .button__label {
            margin-right: rem(10);
        }
    }

    .button--icon {
        svg {
            height: 10px;
            width: 10px;
        }
    }
}
