// Variants
@mixin select--ellipsis {
  position: relative;

  &::before {
    content: attr(data-content);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    pointer-events: none;
    padding-top: $select-padding-top;
    padding-bottom: $select-padding-bottom;
    padding-left: $select-padding-left;
    padding-right: $global-spacing-md;
    z-index: 1;
  }

  .select__input {
    color: transparent;
  }
}

.select--ellipsis {
  @include select--ellipsis;
}

.select--ellipsis-mobile {
  @include screen-mobile-plus-and-smaller {
    @include select--ellipsis;

    // Don't overlap the custom & ellipsis on small screens.
    &.c-select--custom {
      .select__label span:last-child{
        visibility: hidden;
      }
    }
  }
}
