//default: adds 1px line at bottom of element in divider theme color
//optional: other color, position: top, pseudo: before
//full width on mobile
//container width from tablet landscape and up
@mixin divider-line($color: var(--color-divider-line), $position: bottom, $pseudo: after, $full-width-mobile: true) {
  position: relative;

  &::#{$pseudo} {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    #{$position}: 0;
    background-color: $color;
    transition-property: color, opacity;
    transition-duration: 300ms;
    z-index: -1;

    @if ($full-width-mobile == true) {
      @include screen-tablet-portrait-and-smaller {
        width: calc(100% + #{$global-spacing-xxlg});
        left: -$global-spacing-md;
      }
    }
  }
}
