$header-language-selector-list-spacing: 33px;
.c-header {
  @include z(header);

  width: 100%;
  top: 0;
  transition: transform 500ms linear;
  background-color: var(--color-background-html);

  @if variable-exists('show-body-background-image') {
    @include background-grain;
  }

  position: fixed;

  .header--is-hidden & {
    transform: translate3d(0, -250%, 0);
  }

  .header--is-hidden &.html:not(.header-hero) {
    transition: transform 300ms linear;
    transform: translate3d(0, -100%, 0);
  }

  &.header--error {
    padding-top: $global-spacing-lg;
  }

  .header--mega-menu-open & {
    background-color: var(--color-background-html);
    background-image: var(--color-background-image);
  }

  .c-button {
    color: currentColor !important;
  }
}

.header__top {
  @include z(headerTop);

  height: $header-height-top;
  position: relative;
  flex-direction: column;
  &::before {
    top: -1px;
  }

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row;
  }
}

.header-top__wrapper {
  display: none;

  @include screen-tablet-landscape-and-bigger {
    display: block;
    border-bottom: 0.8px solid $color-black;
  }
}

.header__top-left {
  width: 100%;
  margin-top: $gs24;

  @include screen-tablet-landscape-and-bigger {
    margin-right: auto;
    margin-top: 0;
  }
}

.header__top-items {
  display: flex;
  list-style: none;
  flex-direction: column;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row;
  }
}

.header__top-link {
  text-decoration: none;
  font-size: rem(13);
  line-height: rem(26);

  @include screen-tablet-landscape-and-bigger {
    font-size: rem(11);
  }
}

.header__usps {
  height: $header-height-top;
}

.header__top-right {
  flex-shrink: 0;

  @include screen-tablet-portrait-and-bigger {
    display: flex;
    column-gap: $global-spacing-lg;
  }
}

.header__top-right-language-selector {
  .language-selector__list {
    top: $header-language-selector-list-spacing;
    .header--is-hidden & {
      display: none;
    }
  }
}

.header__top-label {
  @include text-small;

  font-style: italic;
}

.header__bottom-wrapper {
  position: relative;
}

.header__bottom {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height-bottom;
}

.header__bottom-left {
  display: flex;
  align-items: center;
  flex: 1;
}

.header__logo {
  opacity: 1;
  transition: 400ms ease-in-out;
  transition-property: width, opacity;
  
  @include screen-tablet-landscape-and-bigger {
    margin-bottom: 14px;
  }

  .header--hide-small-logo & {
    opacity: 0;
    width: 0;
  }

  svg {
    width: 114px !important;
    height: 43px !important;
  }
}

.header__bottom-middle,
.header__bottom-right {
  display: flex;
  align-items: center;
  flex: 1;
}

.header__bottom-middle {
  justify-content: center;
}

.header__bottom-right {
  justify-content: flex-end;

  .search__button-close {
    color: $color-black;

    @include screen-tablet-landscape-and-bigger {
      margin-right: rem(-1);
    }
  }
}

.header__secure {
  display: inline-flex;
  align-items: center;

  @include text-small;

  svg {
    margin-right: 20px;
  }
}

.header__buttons {
  display: flex;
  align-items: center;
}

.header__menu-toggle {
  margin-right: -10px;

  @include z(mobileNavigationToggleButton);
}

.header--hide-small-logo {
  @include screen-tablet-portrait-and-smaller {
    .header__bottom-right {
      width: 100%;
    }

    .navigation__search {
      margin-left: -20px;
      margin-right: auto;
    }

    .search__toggle-button {
      .button__label {
        display: block;
      }
    }
  }
}

.header__switch-button {
  font-size: rem(13);
  font-weight: $font-weight-regular;
  line-height: rem(26);
  color: currentColor;
  text-decoration: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;

  @include screen-tablet-landscape-and-bigger {
    font-size: rem(11);
  }
}

.header__switch-button--professionals {
  .button__label {
    background-image: linear-gradient(transparent, transparent);
  }
}

.search--is-in-focus {
  .header__switch-button {
    border-color: $color-black;
  }
}

.search__toggle-button {
  @include screen-tablet-landscape-and-bigger {
    margin-right: rem(-8);
  }
}

.button--profile,
.site-header__minicart,
.navigation__search {
  .button__icon:hover {
    color: initial !important;
  }
}

.button__icon {
  .header--is-scrolled-up & {
    color: $color-black !important;
  }
}

.header--home-page {
  @supports (position: fixed) {
    position: fixed;
  }

  html:not(.header--is-hidden) & {
    background-color: $color-white;
    color: initial;
    border-color: $color-white;
  }

  html:not(.header--is-hidden):not(.header--is-scrolled-up):not(.search--is-in-focus) & {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    color: $color-white;

    .header__switch-button--shop {
      .button__label {
        background-image: linear-gradient(var(--color-background-html), var(--color-background-html));
      }
    }

    .header__switch-button--shop,
    .header__switch-button--professionals {
      &:hover {
        .button__label {
          background-image: linear-gradient(var(--color-background-html), var(--color-background-html)) !important;
        }
      }
    }
  }

  .header--mega-menu-open & {
    background-color: var(--color-background-html) !important;
    background-image: var(--color-background-image) !important;
    color: initial !important;

    .button--profile {
      color: $color-black;
    }
  }

  .header--is-hidden & {
    transition: transform 300ms linear, background-color 400ms ease-in, background-image 400ms ease-in, color 400ms ease-in;
    background-color: transparent;
    background-image: initial;
    color: initial;
  }

  .header__switch-button {
    .header--is-scrolled-up &,
    .header--mega-menu-open & {
      border-color: $color-black;
    }
  }

  .navigation__search {
    .button__icon:hover {
      color: $color-white !important;

      .search--is-in-focus &,
      .header--is-scrolled-up & {
        color: $color-black !important;
      }
    }
  }

  .button--profile,
  .site-header__minicart {
    .button__icon:hover {
      color: $color-white !important;

      .header--is-scrolled-up & {
        color: $color-black !important;
      }
    }
  }
}
