.c-downloads-wrapper {
  padding: $gs24 0 $gs72;
  background-color: $color-light-grey;

  .downloads-wrapper__title {
    font-size: rem(20);
    line-height: rem(24);
    font-weight: $font-weight-bold;
    margin-bottom: rem(38);
  }

  .experience-assets-richText {
    margin: rem(25) 0 0;

    .s-rich-text,
    p {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .download-link__footer {
    margin-top: rem(25);

    .s-rich-text {
      @include screen-tablet-landscape-and-bigger {
        max-width: 48%;
      }
    }

    .download-link__text-content {
      font-weight: $font-weight-bold;
    }

    .c-download-link {
      margin-bottom: rem(25);

      .c-button--underline {

        .button__icon {
          display: none;
        }

        .button__label {
          margin-right: 0;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}
