@import './variables/variables.loader';
@import './mixins/mixins.indicator';
@import './mixins/mixins.spinnert';

.c-loader {
  @include indicator;
  display: flex;
  z-index: 1;
}

.loader--fixed {
  position: fixed;
  @include z(loader);
}

@mixin make-loader($name, $color, $bgColor) {
  .loader--#{$name} {
    background-color: rgba($bgColor, 0.7);
  }
}

@mixin make-responsive-loader($name, $colorMobile, $colorDesktop, $bgColor) {
  .loader--#{$name} {
    background-color: rgba($bgColor, 0.7);
  }
}

@include make-loader('black', $color-black, $color-white);
@include make-loader('white', $color-white, $color-black);
@include make-responsive-loader('inverted', $color-black, $color-white, transparent);

.loader__spinner {
  @include spinnert(30px);
}

.loader--small {
  .loader__spinner {
    @include spinnert(16px);
  }
}
