.news-list__iteration-item {
    font-size: $font-size-set-text;
    margin: 0 rem(28);
    opacity: 0.3;
    text-decoration: none;

    &.news-list__iteration-item--current {
        opacity: 1;
        font-weight: $font-weight-bold;
    }
}
