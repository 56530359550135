.c-account-item {
  padding: $global-spacing * 3.75 0;

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing * 7.5 0;
  }
}

.account-item__title {
  @include h3;
  text-align: center;
  padding-bottom: $global-spacing-lg;
  border-bottom: 1px solid $color-grey;

  .c-account-item--address & {
    border: none;
  }

  .c-account-item--order & {
    @include h5;
    text-align: left;
    border: none;
    padding: 0;
  }
}

.account-item__header-date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 0 auto;

  .c-label {
    margin-bottom: $global-spacing-sm;
  }

  @include screen-mobile-plus-and-smaller {
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: $global-spacing-sm;

    .c-label {
      margin-bottom: 0;
    }
  }
}

.account-item__footer {
  margin-top: $global-spacing * 3;
}

.account-item__back-button {
  margin-left: -$global-spacing;

  @include screen-mobile-plus-and-smaller {
    margin-bottom: $global-spacing;
  }
}
