@mixin button--text {
  position: relative;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  color: var(  --color-text);
  font-weight: $font-weight-bold;

  .button__focus-helper {
    display: none;
  }

  &:not([disabled]):not(.disabled) {
    @include hover-focus {
      color: var(--color-text-light);
    }

    @include keyboard-focus {
      color: var(--color-text-light);
    }
  }
}
