.c-paged-navigation-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  left: auto;
  right: 0;
}

.paged-navigation-modal__content {
  z-index: 1;
  background-color: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 482px;
  margin-left: auto;
}

.paged-navigation-modal__gutter {
  padding: 0 $gs24;

  @include screen-tablet-landscape-and-bigger {
    padding: 0 $global-spacing-lg;
  }
}

.paged-navigation-modal__scroll-helper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
}

.paged-navigation-modal__button-group {
  padding: $global-spacing-md;
  border-top: 1px solid var(--color-divider-line);

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-xlg;
  }

  .c-button {
    @include screen-mobile-and-smaller {
      padding: 13px 15px 10px;
      @include text-small;
    }
  }

  .c-button + .c-button {
    margin-left: $global-spacing-sm;
  }
}
