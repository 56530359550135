.c-alert {
  color: var(--color-text);
  position: relative;
  z-index: 0;
  width: 100%;
  padding: $global-spacing-sm $global-spacing-md;
  display: flex;
  align-items: center;
  background-color: $color-white;
  @include text-small;
  border: 1px solid $color-grey;
}

.c-alert__warning-text {
  color: $color-warm-red;
  font-size: $font-size-set-text;
}

.alert--small {
  padding: $global-spacing * 1.5 $global-spacing-sm;
}

.alert--is-fixed {
  @include z(browserNotice);
  position: fixed;
  top: 0;
  left: 0;
  padding: $global-spacing-sm $global-spacing-xlg $global-spacing-sm $global-spacing-xs;
}

.alert--large {
  @include text;
  min-height: 74px;
}

.alert--near-icon .alert__icon {
  margin-right: 8px;
}

.alert--light {
  background-color: transparent;
  border-width: 0;
  padding: 0;
  min-height: auto;
  .alert__icon {
    margin-right: 12px;
    margin-left: 1px;
  }
  @include screen-tablet-landscape-and-bigger {
    .alert__message {
      line-height: rem(34);
    }
  }
}

.alert {
  &--warning,
  &--info,
  &--notice {
    font-weight: $font-weight-medium;
    border: 0;
    color: var(--color-info-alert-text);
    background-color: var(--color-alert-info);
  }

  &--success {
    font-weight: $font-weight-medium;
    border: 0;
    color: var(--color-success-alert-text);
    background-color: var(--color-alert-success);
  }

  &--error {
    font-weight: $font-weight-medium;
    border: 0;
    color: var(--color-error-alert-text);
    background-color: var(--color-alert-error);
  }
}

.alert__icon {
  flex-shrink: 0;
  margin-right: 20px;
}

.alert__message {
  padding-top: 2px;
  line-height: 1.45;

  p:first-child {
    margin-bottom: 0;
  }
}

.alert__message--bank-transfer {
  font-weight: $font-weight-regular;
  font-size: $font-size-set-text;
  line-height: $line-height-set-text-large;

  @include screen-tablet-landscape-and-bigger {
    max-width: 75%;
  }
}

.alert__button {
  flex-shrink: 0;
  display: flex;
  margin-left: auto;
  transition: opacity 300ms;

  @include hover-focus {
    opacity: 0.6;
  }

  @include keyboard-focus {
    outline: none;

    @include focus-state-pulse(true);
  }
}
