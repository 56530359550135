.c-tile {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $global-spacing * 3.5 $global-spacing * 2;

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing * 7.5 $global-spacing * 5;
  }
}
.c-tile--expanded {
  text-align: center;

  @include screen-tablet-landscape-and-bigger {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
  }
}
