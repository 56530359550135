@mixin p {
  margin-bottom: 1em;
  word-break: break-word;

  + p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

p {
  @include p;
}
