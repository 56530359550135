.c-added-to-cart-modal {
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 0;

  @supports (-webkit-touch-callout: none) {
    padding-bottom: $global-spacing * 2;
  }

  @include screen-tablet-portrait-and-bigger {
    padding-bottom: 0;
    min-width: 650px;
  }
}

.added-to-cart-modal__content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-height: 100%;
  padding: $global-spacing-sm;

  @include screen-tablet-portrait-and-bigger {
    padding: $global-spacing * 8;
  }
}

.added-to-cart-modal__buttons {
  border-top: 1px solid var(--color-divider-line);
  padding-top: rem(24);

  @include screen-tablet-portrait-and-bigger {
    padding-top: rem(40);
  }
}
