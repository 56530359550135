.c-button--dropdown {
  border-top: 1px solid $color-grey;
  font-weight: 500;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding: $global-spacing-sm $global-spacing-xs;

  .c-icon {
    transition: 200ms ease-in-out;
    transition-property: transform;
  }

  &[aria-expanded='true'] .c-icon {
    transform: rotate(180deg);
  }

  @include hover-focus(false) {
    background-color: $color-grey;
  }

  @include keyboard-focus {
    outline: none;
    box-shadow: inset 0 0 0 1px $color-black;
    background-color: $color-grey;
  }

  @include screen-tablet-portrait-only {
    padding: $global-spacing-sm $global-spacing-xs;
  }

  @include screen-tablet-landscape-and-bigger {
    width: auto;
    padding: $global-spacing;
    border: none;
  }
}
