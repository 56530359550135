.quote__text {
  font-size: $fs35;
  font-weight: $font-weight-semi-medium;
  font-family: $font-stack-secondary;
  line-height: 100%;
  display: block;

  &::before,
  &::after {
    display: none;
  }

  @include screen-tablet-landscape-and-bigger {
    @include width-override(16, 'max-width');

    font-size: $fs50;
  }
}

.quote__text--right {
  text-align: right;
  margin-left: auto;
}

.quote__text--center {
  text-align: center;
  margin: 0 auto;
}
