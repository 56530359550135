.icon-card__svg {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;

  @include screen-tablet-landscape-and-bigger {
    width: 130px;
    height: 130px;
  }
}
