.c-slider {
  position: relative;

  .tns-ovh {
    @include screen-tablet-portrait-and-smaller {
      padding-left: $global-spacing-sm;
    }
  }

  .tns-nav {
    display: none;
  }

  .c-image-container {
    padding: 0;
    margin-bottom: $global-spacing-xxs;

    @include screen-tablet-landscape-and-bigger {
      margin-bottom: $global-spacing-xs;
    }
  }
}

.slider--hidden {
  overflow: hidden;
  height: 0;
  opacity: 0;
}

.slider__header {
  margin-bottom: $global-spacing-xs;

  @include screen-tablet-landscape-and-bigger {
    display: flex;
    align-items: flex-end;
  }
}

.slider__title {
  @include h1;
  text-transform: uppercase;

  @include screen-tablet-portrait-and-smaller {
    margin-bottom: $global-spacing-xs;
  }
}

.slider__subtitle {
  @include h5;
  text-transform: uppercase;
  display: block;
  margin-bottom: $global-spacing-xs;

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: $global-spacing-sm;
  }
}

.slider__controls {
  position: relative;
  margin: $global-spacing-md/2 auto 0 10px;

  &--meta {
    margin-left: auto;
  }
}

.slider__button {
  transition: opacity 300ms linear;

  .button__icon {
    transform: translate3d(0, -2px, 0);
  }

  &[disabled] {
    opacity: .4;
  }
}

.slider__button--prev {
  margin-left: -10px;
}

.slider__status-container {
    position: relative;
    display: flex;
}

.slider__status-bar {
  margin: $global-spacing-md auto 0;
  width: 98%;
  height: 1px;
  background-color: var(--color-slider-status-line);
  position: relative;

  @include screen-tablet-landscape-and-bigger {
    margin: $global-spacing-md 0 0 auto;
    width: 75%;
  }

  .c-slider--page-designer & {
    margin-top: $gs16;

    @include screen-tablet-landscape-and-bigger {
      margin-top: $gs32;
    }
  }
}

.slider__status-indicator {
  width: 0;
  height: 1px;
  position: absolute;
  top: 0;
  background-color: var(--color-slider-indicator-line);
  transition: 300ms transform;
  transform: translateX(0);
}

.slider__meta {
    @include h5;
    padding-left: $global-spacing-xxs;

    @include screen-tablet-landscape-and-bigger {
        text-align: right;
    }
}
