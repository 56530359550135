.c-arco {
  @include background-grain();

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  z-index: 10;
  align-items: center;
  padding: 20px;

  .c-arco__img {
    display: block;
    position: absolute;
    height: 80%;
    width: 70%;
    object-fit: contain;

    @include screen-tablet-landscape-and-bigger {
      height: 100%;
      width: 90%;
      left: 90px;
    }
  }

  .c-arco__content {
    max-height: 70%;

    @include screen-tablet-landscape-and-bigger {
      margin-top: 50px;
    }
  }

  .c-arco__text {
    display: block;
    margin: 0 auto;
    filter: var(--arco-invert);
    width: 80%;
    max-height: 100%;
    object-fit: contain;
    margin-bottom: 50px;

    @include screen-tablet-landscape-and-bigger {
      width: 100%;
    }
  }

  .c-arco__button {
    display: block;
    margin: 0 auto;
    color: var(--color-button-primary-text) !important;
  }

  .c-arco__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
  }
}
