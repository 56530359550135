.c-cta-block {
  color: var(--color-text-inverted);
  overflow: hidden;

  .c-basic-text {
    margin-bottom: 0;
  }

  .c-button--ghost {
    color: currentColor;
    box-shadow: inset 0 0 0 1px currentColor;

    &:not([disabled]):not(.disabled) {
      @include hover-focus {
        color: var(--color-text-inverted-70);
        box-shadow: inset 0 0 0 1px var(--color-text-inverted-70);
      }
    }
  }

  .c-button--underline {
    color: currentColor;

    .button__label {
      background-image: linear-gradient(currentColor, currentColor);
    }

    &:not([disabled]):not(.disabled) {
      @include hover-focus {
        color: var(--color-text-inverted-70);

        .button__label {
          background-image: linear-gradient(var(--color-text-inverted-70), var(--color-text-inverted-70));
        }
      }
    }
  }

  &.s-black {
    color: var(--color-button-ghost);

    .c-button--primary {
      color: var(--color-button-ghost);
    }
  }
}

.cta-block__column {
  @include screen-tablet-portrait-and-bigger {
    width: 50%;
  }
}

.cta-block__column--image {
  .cta-block--form & {
    @include screen-tablet-portrait-and-bigger {
      width: percentage(5/12);
    }
  }
}

.cta-block__column--text {
  padding: $global-spacing-md;

  @include screen-tablet-portrait-and-bigger {
    padding: 0;
    padding-top: $global-spacing-xxlg;
    padding-bottom: $global-spacing-xxlg;

    .cta-block--form & {
      width: percentage(7/12);
    }
  }

  @include screen-tablet-landscape-and-bigger {
    padding-top: $global-spacing-xxxlg;
    padding-bottom: $global-spacing-xxxlg;
  }

  &.cta-block__column--has-heading {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.cta-block__heading {
  background-color: $color-light-grey;
  padding-top: 20px;
  padding-bottom: 20px;
  width: calc(100% + #{map_get($container-gutters, 'xs') * 2});
  margin-left: map_get($container-gutters, 'xs') * -1;
  padding-left: map_get($container-gutters, 'xs');
  padding-right: map_get($container-gutters, 'xs');
  margin-bottom: auto;

  @include screen-tablet-portrait-and-smaller {
    text-align: center;
  }
}

.cta-block__form-wrapper {
  padding-top: $global-spacing-xxlg;
  padding-bottom: $global-spacing-xxlg;

  @include screen-tablet-portrait-and-bigger {
    padding-top: $global-spacing-xxxxlg;
    padding-bottom: $global-spacing-xxxxlg;

    .cta-block__column--has-heading & {
      margin-bottom: auto;
    }
  }
}
