// sass-lint:disable no-vendor-prefixes, no-important

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  color: var(--color-text);
}

html {
  font-family: $font-stack-primary;
  font-size: $font-size-root;
  height: 100%;

  &.no--scroll {
    overflow: hidden !important;
  }
}

body {
  font-size: $font-size-body;
  line-height: $line-height-body;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: var(--color-background-html);
  @if variable-exists('show-body-background-image') {
    @include background-grain;
  }

  .header--mega-menu-open & {
    overflow: hidden;
  }
}

.page {
  display: flex;
  flex: 1;
  flex-direction: column;
  opacity: 0;
  transition: opacity 350ms;
  z-index: 2;

  &.is--loaded {
    opacity: 1;
  }

  .is--country-selector-page &,
  .is--country-modal-open &,
  .is--image-full-screen & {
    z-index: 10;
  }

  .has--navigation-bar & {
    padding-bottom: $navigation-bar-height;
  }
}
