@mixin button--minicart {
  .button__quantity {
    position: absolute;
    right: 6px;
    top: 8px;
    width: 16px;
    height: 12px;
    background-color: var(--color-text);
    border-radius: 8px;
    color: var(--color-text-inverted);
    font-size: .9rem;
    font-weight: $font-weight-bold;
    box-shadow: 0 0 0 2px var(--color-background);
  }

  .button__quantity--hidden {
    display: none;
  }

  .button__quantity-value {
    display: flex;
    line-height: 12px;
    padding-top: 1px;
    justify-content: center;
  }

  @include screen-tablet-portrait-and-smaller {
    .button__label {
      display: none;
    }
  }
}
