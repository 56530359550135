.c-product-zoom {
  display: none;

  &.modal--is-showing {
    display: flex;
  }

  flex-direction: column;
  background-color: $color-white;
  padding: 0;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row;
  }

  .modal__button-close {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-grey;
    width: 43px;
    height: 43px;
    padding: $global-spacing;

    @include hover-focus {
      border: 2px solid $color-black;
    }

    @include screen-tablet-landscape-and-bigger {
      width: 60px;
      height: 60px;
      padding: $global-spacing * 1.5;
    }
  }
}

.product-zoom__images {
  display: flex;
  flex: 1 1 auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-width: 100%;

  @include screen-tablet-landscape-and-bigger {
    height: 100%;
  }
}

.product-zoom__images-wrapper {
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;

  @include screen-tablet-landscape-and-bigger {
    overflow: hidden;
    flex-wrap: wrap;
  }

  @include screen-tablet-portrait-only {
    margin: 0;
  }
}

.product-zoom__image-wrapper {
  flex: 1 0 100%;

  @include screen-tablet-portrait-only {
    width: 100%;
    height: 100%;

    .c-image,
    img {
      height: 100%;
    }
  }
}

.product-zoom__thumbnails {
  padding: $global-spacing * 2;

  @include screen-tablet-landscape-and-bigger {
    padding: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 5%;
    height: 100%;
  }

  .tns-ovh {
    overflow: visible;

    @include screen-tablet-landscape-and-bigger {
      overflow: hidden;
    }
  }
}

.product-zoom__thumbnail-wrapper {
  width: #{ 100px + $global-spacing  * 1.5 };
  height: #{ 100px + $global-spacing * 1.5 };
  padding: 0 $global-spacing;
  border: 2px solid transparent;

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing / 2;
  }
}

.product-zoom__thumbnail--active {
  border: 1px solid $color-black;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -$global-spacing;
    height: 2px;
    width: 100%;
    background-color: $color-black;
  }

  @include screen-tablet-landscape-and-bigger {
    border: 2px solid $color-white;

    &::after {
      content: none;
    }
  }
}

.product-zoom__thumbnails-button {
  display: none;
  width: calc(100% - #{$global-spacing * 1.5});
  height: $global-spacing * 3;
  padding: $global-spacing / 2;
  margin: $global-spacing / 2 0;
  justify-content: center;
  background-color: $color-white;
  opacity: 1;
  transition: 300ms;
  transition-property: opacity, background-color, color;

  @include screen-tablet-landscape-and-bigger {
    display: flex;
  }

  @include hover-focus {
    background-color: $color-black;
    color: $color-white;
  }

  &[disabled] {
    opacity: 0;
  }

  .c-icon {
    @include screen-tablet-landscape-and-bigger {
      transform-origin: center center;
      transform: rotate(-90deg);
    }
  }
}
