.c-related-categories {
  margin: $global-spacing * 7.5 0;

  @include screen-tablet-portrait-and-bigger {
    margin: $global-spacing * 10 0;
  }

  @include screen-laptop-and-bigger {
    margin: $global-spacing * 16 0;
  }
}
