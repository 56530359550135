@mixin radio-disabled {
  cursor: default;
  color: var(--color-form-item-disabled-text);
  pointer-events: none;

  &::after,
  &::before {
    border-color: var(--color-form-item-disabled);
    cursor: default;
    opacity: 0;
  }

  .form__item-disabled-icon {
    display: block;
    left: 0;
    bottom: auto;
    top: $radio-position-top;
  }
}

.c-radio {
  &.disabled,
  &[disabled] {
    > .radio__label {
      @include radio-disabled;
    }
  }

  .form__item-info-message {
    padding-left: $radio-size + $global-spacing;
  }
}
