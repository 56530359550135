.search-results__header {
    display: flex;
    align-items: center;
    padding-top: $global-spacing * 5;
    padding-bottom: $global-spacing * 7;
  
    @include screen-tablet-portrait-and-bigger {
      padding-top: $global-spacing * 7;
      padding-bottom: $global-spacing * 7;
    }
  }
  
  .search-results__header--has-image {
    @include screen-tablet-portrait-and-bigger {
      min-height: rem(400);
    }
  }
  
  .search-results__header-category-buttons {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    max-width: 100%;
    margin-top: $global-spacing * 6;
  }
  
  .search-results__header-category-buttons-wrapper {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  
  .search-results__header-category-button {
    overflow: hidden;
  
    .button__label {
      white-space: nowrap;
    }
  }
  
  .search-results__refinements {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  
    .tab-menu__wrapper {
      @include screen-mobile-plus-and-smaller {
        width: 100%;
  
        &.tab-menu__wrapper--has-margin {
          margin-bottom: rem(20);
        }
      }
    }
  
    .c-tab-menu {
      @include screen-mobile-plus-and-smaller {
        width: inherit;
        @include divider-line(var(--color-divider-line), bottom, after, false);
      }
    }

    .search-results__sort {
      margin-top: rem(-3);
    }
  }
  
  .search-results__count {
    position: relative;
    flex-shrink: 0;
    padding-bottom: rem(13);
    display: flex;
  
    @include screen-mobile-plus-and-smaller {
  
      &.search-results__count--products {
        @include divider-line;
        margin-bottom: rem(20);
        padding-bottom: rem(13);
        justify-content: space-between;
      }
    }
  }
  
  .search-results__count-label {
    flex-shrink: 0;
  }
  
  .search-results__lightbulb-label {
    @include text-small(false);
    line-height: 1.8;
  
    @include screen-mobile-plus-and-smaller {
      text-align: right;
    }
  }
  
  .search-results__filter-button {
    display: flex;
    align-items: center;
    min-height: rem(28);
  
    @include screen-tablet-portrait-and-bigger {
      margin-left: auto;
    }
  
    .c-tag {
      margin-bottom: $global-spacing-xxs;
    }
  }
  
  .search-results__divider {
    display: block;
    position: relative;
  
    @include divider-line(var(--color-divider-line), bottom, after, false);
  }
  
  .search-results__suggestions {
    position: relative;
  
    @include screen-mobile-plus-and-smaller {
      margin-top: $global-spacing-lg;
      padding-top: $global-spacing-xlg;
      @include divider-line(var(--color-divider-line), top, before, false);
    }
  }
  