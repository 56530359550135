.mega-menu__link {
  font-size: rem(13);
  text-decoration: none;
  padding: rem(14) rem(18);
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
  height: 100%;
  text-transform: uppercase;

  &[aria-expanded="true"] {
    .button__label {
      text-shadow: 0 1px currentColor;
    }
  }

  @include hover-focus {
    text-shadow: 0 1px currentColor;
  }

  @include keyboard-focus {
    position: relative;
    text-shadow: 0 1px currentColor;
  }

  @include screen-tablet-landscape-and-bigger {
    height: $header-height-bottom-desktop;
  }

  .button__label {
    display: block;
    transition: border-color 300ms $ease-out-quad;
    border-bottom: 1px solid transparent;
  }
}
