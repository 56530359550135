// Flyout variation - Panel animates in from the right side
// .. animates in from the bottom on mobile
@mixin modal--flyout { // root .c-modal
  left: 0;

  &.modal--is-showing {
    .modal__content {
      opacity: 1;
      transition: $modal-transition-time $modal-transition-ease 100ms, $modal-transition-time $modal-transition-ease;
      transition-delay: 200ms;
      transform: translate3d(0, 0, 0);
    }
  }

  .modal__container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 0;
  }

  .modal__content {
    margin: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    opacity: 0;
    padding: 0;
    background-color: $color-white;
    transform: translate3d(0%, 100%, 0);
    transition-property: transform;

    @include screen-tablet-portrait-and-bigger {
      transform: translate3d(100%, 0%, 0);
      min-height: 0;
      width: 50%;
      min-width: $modal-flyout-min-width;
      max-width: $modal-flyout-max-width;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .modal__template-wrapper {
    height: 100%;
  }

  .modal__button-close {
    top: $global-spacing-sm;
    right: $global-spacing-sm;
  }

  .modal__flyout-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .modal__flyout-sticky {
    position: sticky;
    z-index: 1;
    background-color: $color-white;
    padding: $global-spacing-md;

    &--top {
      top: 0;

      @include screen-mobile-plus-and-smaller {
        padding-left: $global-spacing-xxlg;
        padding-right: $global-spacing-xxlg;
      }
    }

    &--bottom {
      bottom: 0;
      border-top: 1px solid var(--color-slider-status-line);
    }

    @include screen-tablet-portrait-and-bigger {
      padding: $global-spacing-xlg;

      &--bottom {
        border: 0;
      }
    }
  }

  .modal__flyout-scrollable-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    padding: 0 $global-spacing-md;

    @include screen-tablet-portrait-and-bigger {
      padding: 0 $global-spacing-xlg;
    }
  }

  .modal__flyout-buttons {
    margin-bottom: -$global-spacing-sm;
  }

  .modal__flyout-button {
    display: inline-flex;
    align-items: center;
    margin-bottom: $global-spacing-sm;

    &:not(:last-child) {
      margin-right: $global-spacing-lg;
    }
  }
}
