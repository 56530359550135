@mixin button--ghost {
  color: var(--color-button-ghost);
  font-weight: $font-weight-medium;
  padding: $button-spacing;
  letter-spacing: .2px;
  box-shadow: inset 0 0 0 1px var(--color-button-ghost);
  text-transform: uppercase;

  &:not([disabled]):not(.disabled) {
    @include hover-focus() {
      outline: none;
      color: var(--color-button-ghost-hover-text);
      box-shadow: inset 0 0 0 1px var(--color-button-ghost-hover);

      .button__icon svg {
        path,
        polygon {
          fill: var(--color-button-ghost-hover-text);
        }
      }
    }

    @include keyboard-focus {
      color: var(--color-button-ghost-focus-text);
      box-shadow: inset 0 0 0 1px var(--color-button-ghost-focus);
      background-color: var(--color-button-ghost-focus-background);

      .button__icon svg {
        path,
        polygon {
          fill: var(--color-button-ghost-focus);
        }
      }
    }
  }

  &[disabled] {
    font-weight: $font-weight-regular;
    color: var(--color-button-ghost-disabled-text);
    box-shadow: inset 0 0 0 1px var(--color-button-ghost-disabled);
  }
}
