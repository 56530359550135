@import 'button/mixin.button';
@import 'button/mixin.icon';
@import 'button/mixin.icon-only';
@import 'button/size/mixin.small';

@import 'button/variant/mixin.primary';
@import 'button/variant/mixin.ghost';
@import 'button/variant/mixin.button-collapse';
@import 'button/variant/mixin.label';
@import 'button/variant/mixin.tab';
@import 'button/variant/mixin.inline';
@import 'button/variant/mixin.underline';
@import 'button/variant/mixin.minicart';
@import 'button/variant/mixin.add-to-cart';
@import 'button/variant/mixin.text';
@import 'button/variant/mixin.trumpet';

.c-button {
  @include button;
}

/**
* Button variants
*/
.c-button--primary {
  @include button--primary;
}

.c-button--ghost {
  @include button--ghost;
}

.c-button--tab {
  @include button--tab;
}

.c-button--inline {
  @include button--inline;
}

.c-button--underline {
  @include button--underline;
}

.c-button--label {
  @include button--label;
}

.c-button--text {
  @include button--text;
}

.c-button--add-to-cart {
  @include button--add-to-cart;
}

.c-button--block-fill {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
 * Full width button
 */
.button--block {
  width: 100%;
}

/**
 * Full width button on small screens
 */
.button--block-md {
  width: 100%;

  @include screen-tablet-landscape-and-bigger {
    width: auto;
  }

  + .button--block-md {
    margin-top: $global-spacing-md;

    @include screen-tablet-landscape-and-bigger {
      margin-top: auto;
    }
  }
}

/**
* Button icon modifier
*/
.button--icon {
  @include button--icon;
}

/**
* Button icon modifier
*/
.button--icon-only {
  @include button--icon-only;
}

/**
 * Button to collapse items as a toggle
 */
.c-button--collapse {
  @include button--collapse;
}

/**
* Button sizes
*/

.button--small {
  @include button--small;
}

/**
* Header button modifiers
*/
.button--minicart {
  @include button--minicart;
}

.c-button--trumpet {
  @include button--trumpet;
}
