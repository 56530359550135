.c-select {
  &.select--small {
    padding-left: $global-spacing-xxs;
    border-bottom: 0;
    font-weight: $font-weight-bold;

    .select__label {
      font-weight: $font-weight-bold;
    }
  }

  .select__arrow {
    svg {
      color: var(--color-text);
    }
  }
}
