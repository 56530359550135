.c-discovery-slide {
  text-decoration: none;
  background-image: none;
  display: block;
  padding-bottom: 2px; //to make sure the bottom border of the link isn't cut off

  &[href] {
    @include hover-focus {
      .image__default {
        transform: scale(1.05);
      }
    }

    .image__default {
      transition: transform 400ms $ease-out-quad;
    }
  }

  @include hover-focus {
    .discovery-slide__image--hover {
      opacity: 1;
    }
  }
}

.discovery-slide__image-wrapper {
  position: relative;
  overflow: hidden;
}

.discovery-slide__image--hover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 500ms;
}
