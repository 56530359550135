.refinement-menu--landing {
  padding: $gs24;

  @include screen-tablet-portrait-and-smaller {
    padding: $gs8;
  }
}

.refinement-menu-title {
  font-size: $fs20;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-refinement-options--section {
  margin: $gs40 0 $gs80;
}

.refinement-menu__section--year {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.refinement-menu__actions-clear {
  color: $color-dark-grey;
  padding-top: rem(4);

  &::after{
    background-color: $color-dark-grey;
  }
}

.refinement-menu__years-select {
  border-bottom: none;
}

.refinement-menu__actions-close {
  margin: 0 $gs40;
}

.refinement-options__option {
  margin-bottom: rem(20);
  width: 100%;

  .radio__label,
  .checkbox__label {
    padding-left: rem(34);
  }

  .radio__text,
  .checkbox__text {
    color: var(--color-text);
  }
}
