// @shelf-version 1.0.0
$accordion-spacing: $global-spacing-md;
$accordion-border: 1px solid var(--color-divider-line);
$accordion-item-header-padding: $global-spacing-md $global-spacing-xlg $global-spacing-md 0;
$accordion-item-content-padding: 0 0 $global-spacing-md 0;
$accordion-transition-time: 450ms;
$accordion-transition-ease: $ease-in-out-quad;

.c-accordion {
  margin-bottom: $accordion-spacing;
  position: relative;
}

@each $size-modifier, $breakpoint in $grid-breakpoints {
  .accordion--disable-collapse--#{$size-modifier} {
    padding: 0;
  }
}

.accordion__item {
  border-top: $accordion-border;

  &:last-child {
    margin-bottom: 0;
    border-bottom: $accordion-border;
  }

  .experience-assets-faqItem:not(:last-child) & {
    border-bottom: 0;
  }

  &--specs {
    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.accordion__item-override-title {
  display: none;
  font-weight: $font-weight-medium;
  position: relative;
  width: 100%;
  text-align: left;
  padding: $accordion-item-header-padding;
}

.accordion__item-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: $accordion-transition-time / 2 $accordion-transition-ease;
  transition-property: opacity, transform;

  .accordion__item--is-active & {
    opacity: 1;
    transform: translateY(-50%) rotate(-180deg);
  }

  .accordion__item--specs.accordion__item--is-active & {
    transform: translateY(-50%) rotate(-135deg);
  }
}


.accordion__item-header--toggle {
  display: block;
}

.accordion__item-header--open {
  display: block;

  .accordion__item--is-active & {
    display: none;
    @include content-hidden;
  }
}

.accordion__item-header--close {
  display: none;
  @include content-hidden;

  .accordion__item--is-active & {
    display: block;
    @include content-visible;
  }
}

.accordion__item-content-holder {
  height: 0;
  overflow: hidden;
  @include content-hidden;
  transition: $accordion-transition-time $accordion-transition-ease, $accordion-transition-time $accordion-transition-ease $accordion-transition-time, $accordion-transition-time $accordion-transition-ease $accordion-transition-time;
  transition-property: height, visibility, border-radius;

  .accordion__item--is-active & {
    @include content-visible;
    overflow: visible;
    height: auto;
    transition: $accordion-transition-time $accordion-transition-ease, $accordion-transition-time $accordion-transition-ease, $accordion-transition-time $accordion-transition-ease;
  }

  .accordion__item--is-animating & {
    overflow: hidden;
  }
}

.accordion__item-content {
  padding: $accordion-item-content-padding;

  .accordion__item--is-active &,
  .accordion__item--is-animating & {
    @include content-visible;
  }

  .button--collapse {
    margin-bottom: $global-spacing * 2;
  }
}

.accordion__item--mobile-only {
  @include screen-tablet-landscape-and-bigger {
    border: 0;

    &:last-child {
      border: 0;
    }

    .accordion__item-header {
      padding: 0;
      pointer-events: none;
      cursor: default;
    }

    .accordion__item-icon {
      display: none;
    }

    .accordion__item-content-holder {
      height: auto !important;
      visibility: visible;
    }
  }
}
