.c-shipping-form {
  @include screen-tablet-landscape-and-bigger {
    .c-total-delivery-time {
      margin-top: 0;
    }
  }
  .shipping-methods-empty {
    margin-bottom: rem(12);
  }

  .c-total-delivery-time.no-margin {
    margin-top: rem(0);
  }
}

.shipping-form__shipping-methods {
  .shipping-methods__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .c-shipping-method {
    @include screen-tablet-landscape-and-bigger {
      width: calc(50% - 12px);
    }
  }
}

.pac-icon {
  display: none;
}

.pac-matched {
  font-family: 'Avenir', Arial, sans-serif;
  font-size: $font-size-body;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: $line-height-set-text;
  color: $color-black;
}

.pac-item:hover .pac-matched {
  color: $color-white;
}

.pac-item {
  font-family: 'Avenir', Arial, sans-serif;
  font-size: $font-size-body;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: $line-height-set-text;
  color: #666;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  background-color: $color-white;
}

.pac-item:hover {
  background-color: $color-black;
}

.pac-item-query {
  font-family: 'Avenir', Arial, sans-serif;
  font-size: $font-size-body;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: $line-height-set-text;
  color: $color-black;
  margin-right: 1.6rem;
}

.pac-item:hover .pac-item-query {
  color: $color-white;
}

.pac-container {
  background-color: $color-white;
}

.shipping-description {
  margin-top: 1.6rem;

  .shipping-description-text {
    font-family: Avenir LT Std;
    font-size: 14px;
    font-weight: 350;
    line-height: 20px;
    margin-left: 1.6rem;
  }
}
