.profile-form__title {
  font-weight: $font-weight-bold;
  line-height: 1;
  margin-bottom: $global-spacing-sm;

  @include screen-tablet-portrait-and-bigger {
    margin-top: $global-spacing-sm;
    margin-bottom: $global-spacing-sm;
  }
}

.profile-form__intro {
  color: var(--color-text);
  margin: $global-spacing-sm 0;
}

.profile-form__password-hint {
  color: var(--color-text);
  margin-top: $global-spacing-xs;
  margin-bottom: $global-spacing-lg;
}
