q {
  quotes: "“" "”" "‘" "’";

  html[lang="de"] & {
    quotes: "»" "«" "‹" "›";
  }

  html[lang="ch"] & {
    quotes: "«" "»";
  }

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}
