// Transitions
$button-icon-transition-time: 300ms;
$button-icon-transition-ease: $ease-in-out-quad;

@mixin button--icon {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .button__icon {
    line-height: 0;
    transform: translate3d(0, -2px, 0);
    transition: $button-icon-transition-time $button-icon-transition-ease;
    transition-property: transform;
    flex-shrink: 0;

    svg {
      path,
      polygon {
        transition: fill $button-icon-transition-time $button-icon-transition-ease;
      }
    }
  }

  .button__label {
    margin-right: $global-spacing * 1.5;
  }

  &.button--icon-only {
    padding: 10px;
    justify-content: center;
  }

  &.button--icon-left {
    flex-direction: row-reverse;

    .button__label {
      margin-right: 0;
      margin-left: $global-spacing * 1.5;
    }
  }

 .button__focus-helper {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
 }
}
