.c-basic-text {
  @include default-pd-asset-space;

  > *:last-child {
    margin-bottom: 0;
  }

  .c-button {
    width: 100%;

    @include screen-tablet-landscape-and-bigger {
      width: unset;
    }
  }
}

.basic-text__pretitle {
  display: block;
  margin-bottom: $gs4;
}

.basic-text__title {
  margin-bottom: rem(19);

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: $gs24;
  }
}

.basic-text__element {
  margin-bottom: $global-spacing-lg;

  &--button {
    margin-bottom: $global-spacing-md;

    .basic-text--right-align-buttons & {
      @include screen-tablet-landscape-and-bigger {
        text-align: right;
      }
    }

    .basic-text--center-align-buttons & {
      text-align: center;

      .c-button--underline {
        width: unset;
      }
    }
  }
}

.basic-text--centered {
  .basic-text__pretitle {
    display: block;
    text-align: center;
    
    @include screen-tablet-landscape-and-bigger {
      margin-bottom: rem(12);
    }
  }

  .basic-text__title {
    text-align: center;
    margin-bottom: $gs4;

    @include screen-tablet-landscape-and-bigger {
      margin-bottom: $gs24;
    }
  }

  .basic-text__intro {
    text-align: center;
  }

  .basic-text__body {
    margin-bottom: $gs4;
    text-align: center;

    @include screen-tablet-landscape-and-bigger {
      max-width: rem(772);
      margin-inline: auto;
    }
  }
}
