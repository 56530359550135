$modal-small-padding: $global-spacing * 7.5 $global-spacing * 7;
$modal-small-width: 448px;

@mixin modal--small {
  @include screen-tablet-landscape-and-bigger {
    .modal__container {
      height: auto;
      max-height: calc(100% - #{$global-spacing-sm});
      max-width: $modal-small-width;
    }

    .modal__content {
      padding: $modal-small-padding;
    }
  }
}
