.c-navigation {
  width: 100%;
  display: flex;
}

.navigation__item {
  display: block;
  padding: $global-spacing 0;
  text-decoration: none;
  background-image: none;

  @include screen-tablet-landscape-and-bigger {
    border-bottom: 0;
  }

  @include hover-focus {
    .image__default {
      transform: scale(1.08);
    }
  }

  @include screen-tablet-landscape-and-bigger {
    @include hover-focus {
      .navigation__item-image-wrapper {
        opacity: 1;
      }
    }
  }
}

.navigation__item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  @include hover-focus {
    text-shadow: 0 1px currentColor;
  }
}

.navigation__item-image-wrapper {
  @include screen-tablet-portrait-and-smaller {
    display: none;
  }

  @include screen-tablet-landscape-and-bigger {
    overflow: hidden;
    background-color: var(--color-background-inverted-10);
    position: absolute;
    right: rem(14);
    top: rem(35);
    height: 50%;
    width: calc(50% - 14px);
    opacity: 0;
    transition: opacity 250ms ease;
  }
}

.navigation__item-image {
  img {
    width: 100%;
    height: 100%;
  }

  .image__default {
    transition: 400ms transform $ease-out-quad, opacity 200ms ease-out;
  }
}

.navigation__aside-image {
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.navigation__socials {
  display: flex;
  margin-top: rem(20);
}

.navigation__socials-link {
  margin-right: $gs16;

  &:last-child {
    margin-right: 0;
  }
}

.navigation__socials-icon {
  width: rem(16);
  height: rem(16);
}
