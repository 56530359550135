.delete-address-form__title {
  font-weight: $font-weight-bold;
  line-height: 1;
  margin-bottom: $global-spacing-xs * 1.5;
}

.delete-address-form__intro {
  color: var(--color-text);
  margin-bottom: $global-spacing-md;
}
