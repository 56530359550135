@mixin indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 200ms ease-out;
  animation: loader-reveal 200ms linear;
  animation-fill-mode: forwards;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background-color: var(--color-background-html);
    @if variable-exists('show-body-background-image') {
      @include background-grain;
    }
  }
}
