//sass-lint:disable no-qualifying-elements
@mixin hover-focus($only-hover: true) {
  @if $only-hover {
    html:not(.is-touch) &,
    html.is-touch--with-mouse & {
      &:hover {
        @content;
      }
    }
  } @else {
    html:not(.is-touch) &,
    html.is-touch--with-mouse & {
      &:hover,
      &:focus {
        @content;
      }
    }
  }
}
