.c-cta-link-list {
  min-height: 70vh;

  .cta-block__column {
    padding-top: $global-spacing-xxxlg;
    padding-bottom: $global-spacing-xxxlg;
  }

  .experience-assets-ctaLink:not(:last-child) {
    margin-bottom: $global-spacing-sm;
  }

  .u-pre-4--md {
    position: unset;
  }
}

.cta-link-list__title {
  @include h2-secondary;
}
