$checkbox-size: 16px;
$checkbox-color-size: 16px;
$checkbox-color-radius: 50%;
$checkbox-border-radius: 0;
$checkbox-margin-right: $global-spacing-xs / 2;
$checkbox-transition-time: 200ms;
$checkbox-transition-ease: $ease-in-out-quad;
$checkbox-position-top: 11px;

@import 'mixin/mixin.checkbox-checked';
@import 'mixin/mixin.checkbox-disabled';

.c-checkbox {
  position: relative;
  text-align: left;
  text-decoration: none;

  .form__item-feedback-icon--error {
    display: none;
  }
}

.checkbox__input {
  @include sr-only;

  &:checked {
    + label {
      @include checkbox-checked;
    }
  }

  &.disabled,
  &[disabled] {
    + label {
      @include checkbox-disabled;

      .u-text--dark {
        color: var(--color-form-item-disabled-text);
      }
    }
  }
}

.checkbox__label {
  color: var(--color-form-item-placeholder);
  display: block;
  position: relative;
  cursor: pointer;
  padding: $global-spacing-xs 0 $global-spacing-xs 28px;
  margin-bottom: $global-spacing-xs;
  font-size: $font-size-body;

  @include screen-mobile-plus-and-smaller {
    .checkbox--flip-mobile & {
      left: auto;
      right: 0;
      padding: $global-spacing-xs ($checkbox-size + $global-spacing-sm) $global-spacing-xs 0;

      &::before,
      &::after {
        left: auto;
        right: 0;
      }
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: $checkbox-size;
    height: $checkbox-size;
    border-radius: $checkbox-border-radius;
    left: 0;
    top: $checkbox-position-top;
    transform-origin: center center;
    cursor: pointer;
  }


  &::before {
    background-image: var(--form-checkbox-icon);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px auto;
    background-color: transparent;
    color: var(--color-form-item-default);
    text-align: center;
    z-index: 1;
    transform: scale(0);
    transition: $checkbox-transition-time $checkbox-transition-ease;
    transition-property: transform, background-color;
  }

  &::after {
    transition: $checkbox-transition-time $checkbox-transition-ease;
    transition-property: border-color;
    border: $input-border-width solid var(--color-form-item-default);

    .form__item--error & {
      border-color: var(--color-error);
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @include hover-focus {
    &::after {
      border-color: var(--color-form-item-hover);
    }
  }
}

.checkbox__text {
  color: var(--color-form-item-placeholder);
}

.checkbox__icon {
  position: absolute;
  right: 0;
  top: 0;
}

.checkbox__count {
  margin-left: auto;
  text-align: right;
}

.checkbox__subtext {
  display: block;
  @include text-xsmall;
}

.checkbox__color-swatch {
  display: inline-flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: $global-spacing;
  margin-top: 4px;
  border: 1px solid var(--color-divider-line);
}

.checkbox__focus-helper {
  display: block;
  width: $checkbox-size;
  height: $checkbox-size;
  position: absolute;
  top: calc(#{$checkbox-size} / 2);
  left: 0;
  z-index: -1;
}

.checkbox--horizontal {
  .checkbox__wrapper {
    @include screen-tablet-portrait-and-bigger {
      margin-left: -20px;
      margin-right: -20px;
      display: flex;
      flex-wrap: wrap;
    }

    @include screen-tablet-landscape-and-bigger {
      margin-left: -40px;
      margin-right: -40px;
    }
  }

  .checkbox__label {
    @include screen-tablet-portrait-and-bigger {
      margin-left: 20px;
      margin-right: 20px;
    }

    @include screen-tablet-landscape-and-bigger {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}
