// sass-lint:disable no-vendor-prefixes, brace-style, no-important

//
//    A collection of some helper classes for toggling the visibility of elements
//
//    e.g.
//
//    <div class="u-hidden-xs">
//        This element will be hidden on screen size xs
//    </div>
//

@each $visibility-modifier, $visibility-media in $breakpoint-list {
  .u-visible--#{$visibility-modifier},
  .u-visible--#{$visibility-modifier}-block,
  .u-visible--#{$visibility-modifier}-table-cell,
  .u-visible--#{$visibility-modifier}-inline-block,
  .u-visible--#{$visibility-modifier}-inline {
    display: none !important;
  }

  @media #{$visibility-media} {
    .u-visible--#{$visibility-modifier},
    .u-visible--#{$visibility-modifier}-block {
      display: block !important;
    }
    .u-visible--#{$visibility-modifier}-table-cell {
      display: table-cell !important;
    }
    .u-visible--#{$visibility-modifier}-inline-block {
      display: inline-block !important;
    }
    .u-visible--#{$visibility-modifier}-inline {
      display: inline !important;
    }
    .u-hidden--#{$visibility-modifier} {
      display: none !important;
    }
  }
}

.u-sr-only {
  @include sr-only;
}

.u-sr-only[href] {
  @include keyboard-focus {
    position: absolute !important; // sass-lint:disable no-important
    width: auto;
    height: auto;
    clip: initial;
    z-index: 999;
    padding: $global-spacing / 2 $global-spacing-xs;
    background-color: $color-white;
    color: $color-black;
  }
}

.u-block {
  display: block !important;
}

.u-hidden {
  display: none !important;
}

.u-hidden-up {
  @include generate-mediaquery-rules {
    display: none !important;
  }
}

@each $visibility-modifier, $visibility-media in $breakpoint-list {
  .u-visible-up--#{$visibility-modifier} {
    display: none !important;
  }
}

.u-visible-up {
  @include generate-mediaquery-rules {
    display: block !important;
  }
}

.u-overflow--hidden {
  overflow: hidden;
}

.u-overflow--visible {
  overflow: visible;
}

.u-no-wrap {
  white-space: nowrap;
}

.u-disabled {
  pointer-events: none;
  opacity: .5;
}
