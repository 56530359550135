@mixin button--inline {
  display: inline-block;
  background-color: transparent;
  color: currentColor;
  letter-spacing: 0.02em;
  text-decoration: underline;
  padding: 0 !important;

  @include hover-focus() {
    text-decoration: none;
  }

  @include keyboard-focus() {
    outline: none;
  }

  .button__label {
    display: inline !important;
  }
}
