.c-product-tile-button {
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;

  @include screen-tablet-portrait-and-bigger {
    width: auto;
    min-width: calc(50% + #{$global-spacing-sm});
  }
}

@mixin product-tile-button-trigger-hover {
  background-color: $color-white;
  color: var(--color-product-button);

  .button__icon {
    color: var(--color-product-button);
  }
}

.product-tile-button__trigger {
  position: absolute;
  bottom: $global-spacing-sm;
  right: $global-spacing-sm;
  z-index: -1;

  &.button--icon.button--icon-only {
    // Overrule button colors
    background-color: $color-black;
    color: $color-white;
    border: 0;

    .button__icon {
      color: $color-white;
    }

    @include hover-focus() {
      @include product-tile-button-trigger-hover;
    }

    @include keyboard-focus {
      @include product-tile-button-trigger-hover;
    }
  }

  @include screen-tablet-landscape-and-bigger {
    bottom: $global-spacing-md;
    right: $global-spacing-md;
  }
}

.product-tile-button__close {
  position: absolute;
  top: $global-spacing-xs;
  right: $global-spacing-xs;

  &.button--icon.button--icon-only {
    color: var(--color-product-button); // Overrule button color
  }
}

.product-tile-button__product {
  display: none;
  margin-left: auto;
  position: relative;
  bottom: 0;
  right: 0;
  background-color: $color-white; // Colors not dependent on theme
  color: var(--color-product-button);

  .c-price {
    margin-top: $global-spacing-sm;

    .price__item {
      color: var(--color-product-button);
    }
  }

  &.is--visible {
    display: block;
  }

  @include screen-tablet-portrait-and-smaller {
    .t-default & {
      border: 1px solid var(--color-divider-line);
    }
  }

  @include screen-tablet-landscape-and-bigger {
    bottom: $global-spacing-md;
    right: $global-spacing-md;
    min-width: 365px;
    max-width: calc(100% - #{$global-spacing-md * 2});

    .c-price {
      margin-top: auto;
    }
  }
}

.product-tile-button__link {
  padding: $global-spacing-sm 0 $global-spacing-sm $global-spacing-sm;
  margin-right: $global-spacing-xxlg;

  .is--campaign-page & {
    pointer-events: none;
  }

  @include hover-focus {
    overflow: hidden;

    img {
      transform: scale(1.06);
    }
  }
}

.product-tile-button__image {
  width: 42px;
  min-width: 42px;
  height: 42px;
  margin-right: $global-spacing-sm;
  overflow: hidden;

  img {
    object-fit: cover;
    height: 100%;
    transition: transform 400ms $ease-out-quad;
  }

  @include screen-tablet-landscape-and-bigger {
    width: 85px;
    min-width: 85px;
    height: 85px;
  }
}
