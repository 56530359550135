@mixin button--collapse {
  position: relative;

  @include hover-focus(false) {
    border-bottom: 1px solid inherit;
  }

  @include keyboard-focus {
    .button__icon {
      &::before {
        width: 30px;
        height: 30px;
        padding-bottom: 0;
        left: 5px;
        transform: translateY(-50%);
      }
    }
  }

  .button__icon {
    .icon--minus {
      display: none;
    }
  }

  .button__label {
    &::after {
      content: attr(data-title);
    }
  }

  &.is--expanded {
    .button__label::after {
      content: attr(data-title-open);
    }

    .button__icon {
      .icon--minus {
        display: block;
      }
      .icon--plus {
        display: none;
      }
    }
  }
}
