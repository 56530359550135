.c-pagination {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--color-divider-line);
  padding-top: $global-spacing;
  margin-top: $global-spacing-lg;

  @include screen-tablet-landscape-and-bigger {
    margin-top: $global-spacing-xxlg;
  }
}

.pagination__content {
  margin-left: auto;
  margin-right: auto;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: $global-spacing-md;
  padding-right: $global-spacing-md;

  @include screen-mobile-plus-and-smaller {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.pagination__arrow {
  position: absolute;
  top: 0;
}

.pagination__arrow--prev {
  left: -10px;
}

.pagination__arrow--next {
  right: -10px;
}

.pagination__label {
  margin-left: $global-spacing;
  margin-right: $global-spacing;
  padding-top: $global-spacing;
}
