// sass-lint:disable no-vendor-prefixes, brace-style, no-important, no-trailing-whitespace

//
//    A collection of some helper classes for tweaking the layout of the elements.
//
//
//    .u-margin-{direction}--xs      - .u-margin-{direction}--xlg
//    .u-padding-{direction}--xs     - .u-padding-{direction}--xlg
//
//
$spacing-directions: ('top', 'left', 'bottom', 'right');

// Set vars for each
$spacing-direction-done: '';
$spacing-size-done: '';
$spacing-breakpoint-done: false;

// TODO: Deprecate old loop for classes generation after classes have been removed in DOM elements

// Loop trough all media queries
@each $spacing-breakpoint-modifier, $spacing-media in $breakpoint-list {
  // Loop trough all spacing sizes
  @each $spacing-size-modifier, $spacing-size in $spacing-sizes {
    // Loop trough all spacing directions
    @each $spacing-direction in $spacing-directions {
      // Check if size is responsive or not
      @if (length($spacing-size) == 1) {
        // Check if we should output another margin or skip
        @if (
          $spacing-direction-done !=
            $spacing-direction and
            $spacing-size-done !=
            $spacing-size and
            $spacing-breakpoint-done ==
            false
        ) {
          $spacing-direction-done: $spacing-direction;
          $spacing-size-done: $spacing-size-modifier;

          .u-margin-#{$spacing-direction}--#{$spacing-size-modifier} {
            margin-#{$spacing-direction}: $spacing-size !important;
          }

          .u-padding-#{$spacing-direction}--#{$spacing-size-modifier} {
            padding-#{$spacing-direction}: $spacing-size !important;
          }
        }
      } @else {
        @if (map-get($spacing-size, $spacing-breakpoint-modifier) != null) {
          @media #{$spacing-media} {
            $spacing-px: map-get($spacing-size, $spacing-breakpoint-modifier);

            .u-margin-#{$spacing-direction}--#{$spacing-size-modifier} {
              margin-#{$spacing-direction}: $spacing-px !important;
            }

            .u-padding-#{$spacing-direction}--#{$spacing-size-modifier} {
              padding-#{$spacing-direction}: $spacing-px !important;
            }
          }
        }
      }
    }
  }

  // Set to true to skip next breakpoints
  // to prevent duplicate classes when no responsive margin present.
  $spacing-breakpoint-done: true;
}

.u-margin-none {
  //sass-lint:disable no-important
  margin: 0 !important;
}

.u-margin-top-auto {
  //sass-lint:disable no-important
  margin-top: auto !important;
}

.u-padding--none {
  //sass-lint:disable no-important
  padding: 0 !important;
}

.u-padding-top--none {
  //sass-lint:disable no-important
  padding-top: 0 !important;
}

/**
 * Spacing variables based on numeral values
 * First loop creates a set of base classes, second loop creates a set with mediaqueries
 */

@each $spacing-size-modifier, $spacing-size in $spacing-sizes-numeral {
  // Loop trough all spacing directions
  @each $spacing-direction in $spacing-directions {
    .u-margin-#{$spacing-direction}-#{$spacing-size-modifier} {
      margin-#{$spacing-direction}: $spacing-size !important;
    }

    .u-padding-#{$spacing-direction}-#{$spacing-size-modifier} {
      padding-#{$spacing-direction}: $spacing-size !important;
    }

    .u-margin-x-#{$spacing-size-modifier} {
      margin-left: $spacing-size !important;
      margin-right: $spacing-size !important;
    }

    .u-margin-y-#{$spacing-size-modifier} {
      margin-top: $spacing-size !important;
      margin-bottom: $spacing-size !important;
    }

    .u-margin-#{$spacing-size-modifier} {
      margin: $spacing-size !important;
    }

    .u-padding-x-#{$spacing-size-modifier} {
      padding-left: $spacing-size !important;
      padding-right: $spacing-size !important;
    }

    .u-padding-y-#{$spacing-size-modifier} {
      padding-top: $spacing-size !important;
      padding-bottom: $spacing-size !important;
    }

    .u-padding-#{$spacing-size-modifier} {
      padding: $spacing-size !important;
    }
  }
}

// Loop trough all media queries
@each $spacing-breakpoint-modifier, $spacing-media in $breakpoint-list-up {
  // Loop trough all spacing sizes
  @each $spacing-size-modifier, $spacing-size in $spacing-sizes-numeral {
    // Loop trough all spacing directions
    @each $spacing-direction in $spacing-directions {
      .u-margin-#{$spacing-direction}-#{$spacing-size-modifier}--#{$spacing-breakpoint-modifier} {
        @media #{$spacing-media} {
          margin-#{$spacing-direction}: $spacing-size !important;
        }
      }

      .u-padding-#{$spacing-direction}-#{$spacing-size-modifier}--#{$spacing-breakpoint-modifier} {
        @media #{$spacing-media} {
          padding-#{$spacing-direction}: $spacing-size !important;
        }
      }

      .u-padding-y-#{$spacing-size-modifier}--#{$spacing-breakpoint-modifier} {
        @media #{$spacing-media} {
          padding-top: $spacing-size !important;
          padding-bottom: $spacing-size !important;
        }
      }

      .u-padding-x-#{$spacing-size-modifier}--#{$spacing-breakpoint-modifier} {
        @media #{$spacing-media} {
          padding-left: $spacing-size !important;
          padding-right: $spacing-size !important;
        }
      }

      .u-margin-y-#{$spacing-size-modifier}--#{$spacing-breakpoint-modifier} {
        @media #{$spacing-media} {
          margin-top: $spacing-size !important;
          margin-bottom: $spacing-size !important;
        }
      }

      .u-margin-x-#{$spacing-size-modifier}--#{$spacing-breakpoint-modifier} {
        @media #{$spacing-media} {
          margin-left: $spacing-size !important;
          margin-right: $spacing-size !important;
        }
      }
    }
  }
}

// Loop trough all media queries
@each $spacing-breakpoint-modifier, $spacing-media in $breakpoint-list-up {
  // Loop trough all spacing directions
  @each $spacing-direction in $spacing-directions {
    .u-margin-#{$spacing-direction}-auto--#{$spacing-breakpoint-modifier} {
      @media #{$spacing-media} {
        margin-#{$spacing-direction}: auto !important;
      }
    }
  }
}

.u-spacing--default-block {
  @include default-block-space;

  &:last-child {
    margin-bottom: 0;
  }
}

.u-spacing--default-block-small {
  @include default-block-space-small;

  &:last-child {
    margin-bottom: 0;
  }
}

.u-grid-remove-gutters {
  @include remove-gutters;
}

.o-col-auto {
  flex: 0 1 auto;
}
