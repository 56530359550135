.csb__list {
  background-color: var(--color-background-html);
  @if variable-exists('show-body-background-image') {
    @include background-grain;
  }

  display: block;
  border-top: 1px solid var(--color-divider-line);
  border-bottom: 1px solid var(--color-divider-line);
  //box-shadow: $shadow-depth-1;
  list-style: none;
  position: absolute;
  right: -1px;
  top: $global-spacing-xlg;
  width: 100%;
  min-width: 170px;
  z-index: 99;
  box-shadow: 0 7px 7px rgba($color-black, .07);

  &[hidden] {
    display: none;
  }

  .c-product-attribute & {
    top: 51px;
  }

  .cart-product__quantity-select & {
    min-width: 80px;
  }
}

.csb__close {
  display: none;
}

.csb__item {
  font-weight: $font-weight-regular;
}

.csb__button--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.csb__button--selected {
  pointer-events: none;
  cursor: default;
}

.csb__button-label {
  position: relative;
  padding-left: $global-spacing-md;
  align-items: center;
  cursor: pointer;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  .csb__button--selected &::before,
  .csb__button--selected &::after {
    content: '';
    display: block;
    position: absolute;
    left: $global-spacing;
    bottom: $global-spacing;
    transform: rotate(-45deg);
    background-color: var(--color-text);
    transform-origin: left bottom;
    transition: $button-transition-time $button-transition-ease;
    transition-property: background-color;
  }

  .csb__button--selected &::before {
    height: 7px;
    width: 2px;
  }

  .csb__button--selected &::after {
    height: 2px;
    width: 12px;
  }
}


.csb__button {
  cursor: pointer;
  padding: $global-spacing-xs $global-spacing;
  transition: $button-transition-time $button-transition-ease;
  transition-property: background-color, color;
  width: 100%;

  @include hover-focus {
    background-color: var(--color-text);
    color: var(--color-text-inverted);

    .csb__button-label::before,
    .csb__button-label::after {
      background-color: var(--color-text-inverted);
    }
  }
}
