.c-login-status {
  z-index: 5;

  .dropdown-menu__dropdown {
    left: initial;
    right: 0;
  }

  @include screen-tablet-portrait-and-smaller {
    position: static;

    .c-dropdown-header {
      display: none;
    }

    .dropdown-menu__dropdown {
      @include dropdown-absolute-state;
      top: 100%;
      width: 100%;
    }
  }
}

.login-status__container {
  width: 100%;
}

.login-status__toggle-wrapper {
  display: flex;
  align-items: center;

  @include screen-tablet-portrait-and-smaller {
    .button__label {
      @include sr-only;
    }
  }
}

.login-status__toggle-button {
  .login-status__close-icon {
    display: none;
  }

  @include screen-tablet-portrait-and-smaller {
    &[aria-expanded="true"] {
      .login-status__user-icon {
        display: none;
      }

      .login-status__close-icon {
        display: block;
      }
    }
  }
}

.login-status__content {
  display: flex;
  width: 100%;
  padding: $global-spacing * 3 $global-spacing * 2;
  flex-direction: column;

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing * 4 $global-spacing $global-spacing * 2;
  }
}

.login-status__button {
  width: 100%;
}

.login-status__button--logged-in {
  width: 100%;
  border-bottom: 1px solid var(--color-background);
  text-align: left;
  color: var(--color-text);
  justify-content: space-between;

  &:last-of-type {
    border-bottom: none;
  }

  @include hover-focus {
    text-decoration: underline;
  }

  @include keyboard-focus {
    outline: 1px solid currentColor;
  }

  .button__icon {
    @include screen-tablet-landscape-and-bigger {
      display: none;
    }
  }

  @include screen-tablet-portrait-and-smaller {
    font-weight: $font-weight-medium;
  }
}

.login-status__button--user {
  color: $color-dark-grey;

  .button__label {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .button__icon {
    color: var(--color-text);
  }

  @include screen-tablet-portrait-and-smaller {
    font-weight: $font-weight-medium;
  }
}

.login-status__button--user-name {
  color: var(--color-text);
  padding-bottom: $global-spacing / 2;
  text-transform: capitalize;
  font-weight: $font-weight-medium;

  @include screen-tablet-landscape-and-bigger {
    font-weight: $font-weight-bold;
  }
}

.login-status__divider {
  display: block;
  width: 100%;
  padding: $global-spacing * 2 0;
  text-align: center;
}

.login-status__usps {
  padding-top: $global-spacing * 2;
  text-align: center;
  line-height: 2.5;
}
