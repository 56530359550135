$color-swatch-size: 28px;
$color-swatch-size-label: 24px;
$color-swatch-border: 1px;
$color-swatch-border-color: #cac3bd;
$color-swatch-margin: 4px - $color-swatch-border;

.c-color-swatch {
  position: relative;
  margin-left: 1px; // Fixes overflow issues with the parent
  margin-right: $global-spacing-md;
  margin-bottom: $global-spacing-md;
  width: $color-swatch-size;
  height: $color-swatch-size;

  @include screen-tablet-landscape-and-bigger {
    margin-right: $global-spacing * 1.5;
    margin-bottom: $global-spacing * 1.5;
  }

  &::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $color-black;
    bottom: -$global-spacing / 1.5;
    opacity: 0;
    content: '';
    transition: 300ms opacity;
    transition-timing-function: $ease-in-out-back;
  }

  @include hover-focus {
    &::after {
      opacity: 1;
    }
  }

  &.color-swatch--selected {
    &::after {
      opacity: 1;
    }
  }

  &.color-swatch--unselectable {
    &::after {
      content: none;
    }
  }
}

.color-swatch__label {
  appearance: none;
  position: relative;
  border-radius: 50%;
  display: block;
  width: $color-swatch-size;
  height: $color-swatch-size;
  border: $color-swatch-margin solid $color-white;
  background-color: blue;

  @include keyboard-focus {
    @include focus-state-pulse($round: true);
  }

  &::after {
    content: '';
    position: absolute;
    width: $color-swatch-size;
    height: $color-swatch-size;
    top: -$color-swatch-margin;
    left: -$color-swatch-margin;
    border-radius: 50%;
    box-shadow: 0 0 0 $color-swatch-border $color-swatch-border-color;
    transform-origin: center center;
    transition: 300ms box-shadow;
    transition-timing-function: $ease-in-out-back;
    pointer-events: none;
  }

  &::before {
    @include center();
    transform: translate(-50%, -50%) rotate(45deg);
    display: block;
    width: 1px;
    height: 30px;
    background-color: $color-swatch-border-color;
    box-shadow: 0 0 0 2px $color-white;
    background-position: center;
    background-repeat: no-repeat;
  }

  .color-swatch--selected & {
    &::after {
      box-shadow: 0 0 0 $color-swatch-border $color-black;
    }

    &::before {
      background-color: $color-black;
    }
  }

  .color-swatch--unselectable & {
    @include hover-focus {
      &::after {
        box-shadow: 0 0 0 $color-swatch-border $color-swatch-border-color;
      }
    }

    &::before {
      content: '';
    }
  }

  @include hover-focus {
    cursor: pointer;

    &::after {
      box-shadow: 0 0 0 $color-swatch-border $color-black;
    }
  }
}

.color-swatch__input {
  @include sr-only;

  &:checked + .color-swatch__label {
    &::after {
      transform: scale(1);
    }
  }

  &:disabled + .color-swatch__label {
    @include hover-focus {
      cursor: default;
      &::after {
        transform: scale(0);
      }
    }

    @include keyboard-focus {
      @include focus-state-pulse($round: true);
    }

    &::before {
      content: '';
    }
  }
}
