.form__item-error {
  @include text-xsmall;
  display: none;
  position: relative;
  color: var(--color-error-text);
  z-index: 0;

  .form__item--error &,
  .form__item--warn & {
    margin-top: $global-spacing;
    display: inline-block;
  }
}
