// Aspect ratio without breakpoints
// Doesn't work, can't get '3-4' values to work as '3/4' in mixin
//$aspect-ratios: ('', '3-4', '4-3');
//@each $aspect-ratio in $aspect-ratios {
//  @if (
//      $aspect-ratio == ''
//  ) {
//    .u-aspect-ratio {
//      @include aspect-ratio(str-replace($aspect-ratio, '-', '/'));
//    }
//  } @else {
//    .u-aspect-ratio-#{$aspect-ratio} {
//      @include aspect-ratio(str-replace($aspect-ratio, '-', '/'));
//    }
//  }
//}

// Custom classes, can't get above to work

.u-aspect-ratio {
  @include aspect-ratio();
}

.u-aspect-ratio-3-4 {
  @include aspect-ratio(3/4);
}

.u-aspect-ratio-4-3 {
  @include aspect-ratio(4/3);
}

.u-aspect-ratio-5-4 {
  @include aspect-ratio(5/4);
}

.u-aspect-ratio-16-9 {
  @include aspect-ratio(16/9);
}

.u-aspect-ratio-7-4 {
  @include aspect-ratio(7/4);
}

.u-aspect-ratio-contain {
  img {
    object-fit: contain;
  }
}

// Loop trough all media queries for aspect ratio 1
@each $spacing-breakpoint-modifier, $spacing-media in $breakpoint-list-up {
  .u-aspect-ratio--#{$spacing-breakpoint-modifier} {
    @media #{$spacing-media} {
      @include aspect-ratio();
    }
  }
}

// Loop trough all media queries for aspect ratio 3/4
@each $spacing-breakpoint-modifier, $spacing-media in $breakpoint-list-up {
  .u-aspect-ratio-3-4--#{$spacing-breakpoint-modifier} {
    @media #{$spacing-media} {
      @include aspect-ratio(3/4);
    }
  }
}

// Loop trough all media queries for aspect ratio 4/3
@each $spacing-breakpoint-modifier, $spacing-media in $breakpoint-list-up {
  .u-aspect-ratio-4-3--#{$spacing-breakpoint-modifier} {
    @media #{$spacing-media} {
      @include aspect-ratio(4/3);
    }
  }
}

// Loop trough all media queries for aspect ratio 5/4
@each $spacing-breakpoint-modifier, $spacing-media in $breakpoint-list-up {
  .u-aspect-ratio-5-4--#{$spacing-breakpoint-modifier} {
    @media #{$spacing-media} {
      @include aspect-ratio(5/4);
    }
  }
}

// Loop trough all media queries for aspect ratio 16/9
@each $spacing-breakpoint-modifier, $spacing-media in $breakpoint-list-up {
  .u-aspect-ratio-16-9--#{$spacing-breakpoint-modifier} {
    @media #{$spacing-media} {
      @include aspect-ratio(16/9);
    }
  }
}

// Loop trough all media queries for aspect ratio contain
@each $spacing-breakpoint-modifier, $spacing-media in $breakpoint-list-up {
  .u-aspect-ratio-contain--#{$spacing-breakpoint-modifier} {
    @media #{$spacing-media} {
      img {
        object-fit: contain;
      }
    }
  }
}
