//.c-billing-resume {}

//.billing-resume__container {}

.billing-resume__checkbox {
  .form__item {
    margin: 0;
  }
}

.billing-resume__text {
  display: block;

  &:not(:last-of-type) {
    margin-bottom: $global-spacing-xxs;
  }
}
