$arrow-size: 40px;

.c-product-range-slider {
  position: relative;
  opacity: 0;
  transition: opacity 200ms ease-in-out;

  &.is--initialised {
    opacity: 1;
  }

  .tns-ovh {
    overflow: visible;
  }
}

.product-range-slider__header {
  position: relative;
}

.product-range-slider__title {
  @include fluid-type(4rem, 2rem, font-size);
  margin-bottom: $global-spacing * 2.5;

  @include screen-tablet-portrait-and-bigger {
    margin-bottom: $global-spacing-xlg;
  }
}

.product-range-slider__arrows {
  display: none;

  svg {
    vertical-align: initial;
  }

  @include screen-tablet-landscape-and-bigger {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include keyboard-focus {
      @include focus-state-line-crawl($size: 10px);
    }
  }
}

.product-range-slider__arrow {
  width: $arrow-size;
  height: $arrow-size;
  transition-timing-function: ease-out;
  transition-duration: 300ms;
  transition-property: opacity, background-color;
  background-color: $color-grey;
  margin: 0;
  line-height: 1;

  &[disabled],
  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  @include hover-focus {
    background-color: $color-grey;
  }

  @include keyboard-focus {
    outline: none;
    box-shadow: inset 0 0 0 1px $color-black;
    background-color: $color-grey;
  }
}

.product-range-slider__arrow--right.c-button {
  padding: 0;
  right: 0;
  margin-left: $global-spacing-md;

  @include hover-focus {
    .button__icon {
      transform: translate3d(30%, 0, 0);
    }
  }
}

.product-range-slider__arrow--left.c-button {
  padding: 0;
  left: 0;

  @include hover-focus {
    .button__icon {
      transform: translate3d(-30%, 0, 0);
    }
  }
}

.product-range-slider__scroll-indicator {
  display: none;
  height: 4px;
  background-color: $color-grey;
  position: relative;
  opacity: 0;
  width: 120%;
  transition: opacity 300ms ease-in-out;

  &.is--visible {
    opacity: 1;
  }

  @include screen-tablet-landscape-and-bigger {
    display: block;
    width: 100%;
  }
}

.product-range-slider__slides {
  margin: 0 auto;

  .c-product-tile {
    overflow: visible;
    padding-bottom: 0;

    @include screen-tablet-portrait-and-bigger {
      padding-bottom: 0;
    }

    @include screen-tablet-landscape-and-bigger {
      padding-bottom: 0;
    }
  }
}
