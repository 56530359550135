.c-cart-product {
  position: relative;
  text-decoration: none;
  border-bottom: 1px solid var(--color-divider-line);
}

.cart-product__image-link {
  display: block;
  margin-bottom: $global-spacing;
  flex: 1;
}

.cart-product__image--lightbulb {
  @include screen-tablet-landscape-and-bigger {
    width: 48px;
    height: auto;
    max-height: 64px;
    margin-left: auto;
  }
}

.cart-product__info {
  position: relative;
}

.cart-product__info-top {
  width: 100%;

  @include screen-tablet-landscape-and-bigger {
    padding-right: calc(200px + 16px);
    min-height: 60px;
  }

  @include screen-laptop-and-bigger {
    padding-right: calc(260px + 16px);
  }
}

.cart-product__product-name {
  @include underline-button-clean(bold);
}

.cart-product__lightbulb-name {
  @include underline-button-clean();
}

.cart-product__attributes {
  @include text-small;
  color: var(--color-text-light);
}

.cart-product__availability-message {
  width: 100%;
  @include text-small;
  font-weight: $font-weight-medium;

  @include screen-mobile-and-smaller {
    flex: 2;
  }

  @include screen-mobile-plus-and-bigger {
    width: 50%;
  }
}

.cart-product__badges {
  margin-left: $global-spacing-sm;
  margin-bottom: -$global-spacing;
  display: flex;
  min-width: 80px;
  flex-wrap: wrap;
  justify-content: flex-end;

  @include screen-mobile-and-smaller {
    flex: 1;
  }

  .c-tag {
    margin: 0 0 $global-spacing $global-spacing;
  }
}

.product-cart__quatitiy-price-wrapper {
  @include screen-tablet-landscape-and-bigger {
    position: absolute;
    right: 0;
    width: 200px;
  }

  @include screen-laptop-and-bigger {
    width: 220px;
  }
}

.cart-product__quantity-wrapper {
  min-width: calc(25% - 8px);

  @include screen-tablet-landscape-and-bigger {
    margin-top: -8px;
    min-width: 50px;
  }

  .form__item {
    margin: 0;
  }
}

.cart-product__quantity-select {
  .select__label {
    margin-bottom: 0;
  }
}

.cart-product__price {
  @include screen-tablet-landscape-and-bigger {
    margin-left: $global-spacing-md;
  }

  .price__items {
    flex-direction: column;
    justify-content: flex-end;
  }
}

.cart-product__remove-wrapper {
  display: inline-block;
  margin-top: auto;
}

.cart-product__remove-button {
  @include button-underline-color-update(var(--color-text-light), var(--color-text));
  @include underline-button-clean;
}

.cart-product__lightbulbs {
  position: relative;
}

.cart-product__lightbulb {
  position: relative;

  &:not(:last-child) {
    margin-bottom: $global-spacing-md;
  }
}
