@mixin button--icon-only {
  .button__icon {
    transform: none;
  }

  &:not(.c-button--primary):not(.c-button--ghost) {
    color: var(--color-button-ghost);

    &:not([disabled]):not(.disabled) {
      @include hover-focus {
        color: var(--color-button-ghost-hover-icon-only);
      }
    }

    &[disabled] {
      color: var(--color-button-ghost-disabled);
    }
  }
}
