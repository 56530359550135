/* stylelint-disable */
@mixin aspect-ratio($ratio: 1, $object-fit: cover, $object-position: center center) {
  @supports (aspect-ratio: 1) {
    img {
      display: inline-block;
      aspect-ratio: $ratio;
      object-fit: $object-fit;
      object-position: $object-position;
    }
  }

  @supports not (aspect-ratio: 1) {
    position: relative;
    height: auto;

    &::after {
      display: block;
      padding-bottom: calc(100% / #{$ratio});
      content: '';
      pointer-events: none;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: $object-fit;
      object-position: $object-position;
      transform: none;
    }
  }
}
/* stylelint-enable */
