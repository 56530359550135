.form__item--warn {
  .form__item-error {
    display: inline-block;
    color: $form-item-warn-border-color;
  }

  .input__input {
    border-color: $form-item-warn-border-color;

    @include hover-focus {
      border-color: $form-item-warn-border-color;
    }
  }
}
