.center-form {
  margin: 0 auto;

  .form__item {
    .csb__list {
      max-height: 20rem;
    }

    &--lap-active {
      input[type="date"] {
        color: var(--color-form-item-focus-text);
      }
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
  }

  input[type="date"] {
    &:focus {
      color: var(--color-form-item-focus-text);
    }
  }
}

.lead-image {
  width: $global-spacing * 45 !important;
  height: auto !important;
  margin-top: $global-spacing * 3.8;
}

.lead-form-title-block {
  margin-bottom: $global-spacing * 2.4;
}

.lead-form-mandatory {
  color: $color-dark-grey;
  font-size: $global-spacing * 1.2;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: $global-spacing * 1.8;
  margin-top: $global-spacing * 0.8;
}

@include screen-tablet-portrait-and-smaller {
  .lead-form-image {
    display: none;
  }
}

