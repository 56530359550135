.c-flyout-menu {
  max-height: calc(100vh - var(--header-height) - 40px);
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--color-text);
  position: relative;
  opacity: 1;
  transition: 200ms opacity;
  background-color: var(--color-background-html);

  .header--hide-flyout & {
    opacity: 0;
    transition-duration: 0ms;
  }

  .o-container {
    height: 100%;
  }

  .o-grid {
    position: relative;
    min-height: 100%;
  }
}

.flyout-menu__items {
  width: 100%;
}

.flyout-menu__item {
  width: 100%;
  padding-right: $global-spacing-lg;
}
