.c-language-selector {
  position: relative;
  display: flex;

  @include screen-tablet-landscape-and-bigger {
    padding: rem(18) rem(37) rem(18) rem(18);
  }

  @include hover-focus {
    .language-selector__list {
      @include screen-tablet-landscape-and-bigger {
        display: block;
      }
    }
  }
}

.language-selector__toggle {
  font-size: rem(13);
  line-height: rem(26);
  padding: 0;
  display: none;

  .button__icon {
    display: none;
  }

  .button__label {
    margin-right: 0;
  }

  @include screen-tablet-landscape-and-bigger {
    display: block;
  }
}

.language-selector__list {
  display: flex;
  position: relative;
  margin-top: rem(105);
  background-color: var(--color-background-html);
  @if variable-exists('show-body-background-image') {
    @include background-grain;
  }

  @include screen-tablet-landscape-and-bigger {
    position: absolute;
    display: none;
    right: 0;
    left: rem(-18);
    margin-top: 0;
    top: rem(50);
    padding: rem(25) rem(0) rem(28) rem(36);
    width: calc(100% + 18px);
  }

  .c-footer & {
    box-shadow: none;
    border-bottom: 1px solid $color-dark-grey;
  }

  @include hover-focus {
    @include screen-tablet-landscape-and-bigger {
      display: block;
    }
  }
}

.language-selector__list-item {
  font-size: rem(13);
  line-height: rem(18);
  width: 100%;
  font-weight: $font-weight-regular;
  text-align: left;
  position: relative;
  padding: rem(0) rem(21) rem(0) rem(0);

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    opacity: 0;
    transition: opacity 200ms;
  }

  @include screen-tablet-landscape-and-bigger {
    @include text;
    padding: rem(10) rem(0) rem(14);
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;

    .button__label {
      font-weight: $font-weight-bold;
    }
  }

  &:hover:not([disabled]) {
    font-weight: $font-weight-bold;
  }
}
