@mixin checkbox-checked {
  &::before {
    transform: scale(1);
  }

  &::after {
    border-color: var(--color-form-item-focus);
  }

  .checkbox__count {
    color: var(--color-text);
  }
}

.c-checkbox {
  &.checked {
    > .checkbox__label {
      @include checkbox-checked;
    }
  }
}
