@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    &::before,
    &::marker {
      display: none;
    }
  }
}
