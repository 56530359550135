@mixin modal--fill-content {
  .modal__container {
    height: 100%;

    @include screen-tablet-landscape-and-bigger {
      height: 90%
    }
  }

  .modal__content {
    width: 100%;
    height: 100%;
    padding: 0;
  }
}
