.c-header-hero {
  display: none;
}

.is-homepage {
  .c-header-hero {
    @include z(header);
    display: block;
    position: fixed;
    width: 100%;
    color: $color-white;
    background-color: $color-black;
    transition: transform 300ms linear;
    top: 0;
    left: 0;

    .header--is-hidden & {
      transform: translate3d(0, -100%, 0);
    }
  }
}

.header-hero__title {
  font-size: 34px;
  margin-left: $global-spacing-sm;
  margin-bottom: 0;
  line-height: 34px;
  font-weight: $font-weight-bold;
  padding: 15px 0 5px;

  @include screen-tablet-landscape-and-bigger {
    font-size: 50px !important;
    line-height: 50px;
  }
}

.header-hero__animation {
  position: relative;
  display: flex;
  justify-content: space-around;
  top: 0;
  left: 100%;
  width: 100%;
  white-space: nowrap;
  animation: move 12s linear infinite;
  animation-delay: 0s;

  @include screen-tablet-landscape-and-bigger {
    animation-duration: 24s;
  }

  @keyframes move {
    0%   {  left: 100%; }
    100% {  left: -100%; }
  }
}

.header-hero__clickarea {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
