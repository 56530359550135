$method-border-width: 1px;
$method-border-focus-width: 2px;
$method-border-radius: 0;
$method-border-color: var(--shipping-method-border);
$method-border-color-focus: var(--shipping-method-border-focus);
$method-border-hover-color: var(--shipping-method-border-hover);
$method-border-active-color: var(--shipping-method-border-active);
$method-transition-time: 200ms;
$method-transition-ease: $ease-in-out-quad;

.c-shipping-method {
  width: 100%;
  position: relative;
  margin-bottom: $global-spacing-sm;

  @include screen-tablet-portrait-and-smaller {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .c-radio,
  .radio__wrapper {
    height: 100%;
  }
}

.shipping-method__input:checked + .shipping-method__label {
  border-color: $method-border-active-color;
  cursor: default;
}

.shipping-method__label {
  height: 100%;
  padding: $global-spacing-sm $global-spacing-md $global-spacing-sm $global-spacing-xxxlg;
  background-color: $color-white;
  position: relative;
  border: $method-border-width solid $method-border-color;
  border-radius: $method-border-radius;
  transition: border-color 250ms ease;

  .shipping-method--single & {
    padding-left: $global-spacing-md;
  }

  .shipping-method__input:not(:checked) + & {
    @include hover-focus {
      border-color: $method-border-hover-color;
    }
  }

  &::before,
  &::after {
    left: 24px;
    top: calc(50% - 9px);

    .shipping-method--single & {
      display: none;
    }
  }
}

.shipping-method__contents-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  color: var(--color-text);
}

.shipping-method__change-store-button {
  flex-shrink: 0;
}

.shipping-method__store-address-line {
  display: block;
}

.shipping-method__title {
  display: block;
  line-height: 1.2;
  font-weight: $font-weight-bold;
  margin-bottom: $global-spacing-xxs;
}

.shipping-method__price {
  display: block;
  margin-bottom: -$global-spacing-xxs;
}

.shipping-method__logo {
  margin-left: $global-spacing-sm;
  flex-shrink: 0;

  img {
    max-width: 40px;
    max-height: 40px;
  }
}
