// all in-view animations for components on page designer pages

//global animations
.experience-component:not(.tns-item) .c-basic-entry,
.experience-component .c-basic-text,
.experience-component .c-hubspot-form,
.experience-component .c-quote,
.experience-component:not(.tns-item) .c-image-container,
.experience-component .video__wrapper,
.experience-component .contact-form__wrapper,
.experience-component .cookie-form__wrapper,
.experience-component .rich-text__wrapper,
.experience-component .c-link-list,
.experience-component .c-faq-list,
.experience-component .c-icon-card,
.image-grid__item,
.discovery-slider__container,
.image-slider__container,
.tile-slider__container,
.c-cta-block,
.c-story {
  transition: transform 600ms $ease-in-out-quad, opacity 300ms ease;

  &.is--out-view {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
  }
}

.experience-component .rich-text__wrapper {
  transition-delay: 400ms;
}

//image animations
.experience-component:not(.tns-item) .c-basic-entry,
.experience-component:not(.tns-item) .c-image-container,
.image-grid__item,
.c-story {
  .c-image {
    transition: transform 400ms $ease-in-out-quad 300ms;
  }

  &.is--out-view {
    .c-image {
      transform: scale(0.95);
    }
  }
}

//video animations
.experience-component .video__wrapper {
  .c-video {
    transition: transform 400ms $ease-in-out-quad 300ms;
  }

  &.is--out-view {
    .c-video {
      transform: scale(0.92);
    }
  }
}

//quote animations
.experience-component .c-quote {
  .quote__footer {
    transition: transform 400ms $ease-in-out-quad 300ms;
  }

  &.is--out-view {
    .quote__footer {
      transform: translate3d(0, 70px, 0);
    }
  }
}
