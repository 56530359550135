.c-breadcrumb {
  list-style: none;
  padding: 0;
}

.breadcrumb__button {
  max-width: 100%;

  .button__label {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
