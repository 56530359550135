.u-background {
  @include make-colors($color-backgrounds-map);
}

.u-background--primary {
  background-color: var(--color-primary);
}

.u-background--black {
  background-color: $color-black;
}

.u-background--scroll-gradient-container {
  @include scroll-container($color-white, $color-white, 'horizontal');
  width: 100%;
}

.u-background--image-bg {
  background-color: var(--color-image-background);
}

.u-background--scroll-gradient-left,
.u-background--scroll-gradient-right {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100px;
  z-index: 2;
  pointer-events: none;

  svg {
    position: absolute;
    top: calc(50% - 4px);
    transform: translateY(-50%);
  }
}

.u-background--scroll-gradient-left {
  left: 0;
  background-image: linear-gradient(to left, var(--color-background-html-0), var(--color-background-html));

  svg {
    left: 0;
  }
}

.u-background--scroll-gradient-right {
  right: 0;
  background-image: linear-gradient(to right, var(--color-background-html-0), var(--color-background-html));

  svg {
    right: 0;
  }
}

.u-background--light {
  content: '';
  display: block;
  position: fixed;
  width: 1000px;
  height: 1000px;
  opacity: 1;
  background: radial-gradient(circle closest-side, rgba(var(--color-light), .2), rgba(var(--color-light), 0));
  pointer-events: none;
  transform: translate3d(calc(100vw - 500px), 110px, 0);
  transition: transform 350ms $ease-out-quad, opacity 300ms linear;

  @include screen-tablet-portrait-and-bigger {
    width: 1500px;
    height: 1500px;
    transform: translate3d(calc(100vw - 7500px), 110px, 0);
  }

  @include screen-tablet-landscape-and-bigger {
    width: 2000px;
    height: 2000px;
    transform: translate3d(calc(100vw - 1000px), 110px, 0);
  }

  .t-warm-red & {
    background: radial-gradient(circle closest-side, rgba(var(--color-light), .25), rgba(var(--color-light), 0));
  }

  .t-moss-green &,
  .t-blue-sky & {
    background: radial-gradient(circle closest-side, rgba(var(--color-light), .3), rgba(var(--color-light), 0));
  }

  .header--hide-glow & {
    opacity: 0;
  }

  // if header is in view, light should always be at default position
  // override styles set by js
  html:not(.header--is-hidden) & {
    transform: translate3d(calc(100vw - 500px), 110px, 0) !important;

    @include screen-tablet-portrait-and-bigger {
      transform: translate3d(calc(100vw - 750px), 110px, 0) !important;
    }

    @include screen-tablet-landscape-and-bigger {
      transform: translate3d(calc(100vw - 1000px), 110px, 0) !important;
    }
  }
}
