$form-item-margin: $global-spacing-xlg;
$form-padding-left: $global-spacing;
$form-item-error-border-width: 1px;
$form-item-error-border-radius: 0;
$form-item-warn-border-color: #e8880c;
$form-item-error-border-color: var(--color-error);
$form-item-error-fill-color: rgba($form-item-error-border-color, 0.2);
$form-item-warn-fill-color: rgba($form-item-warn-border-color, 0.2);
$form-item-error-padding-top: $global-spacing/2;
$form-item-error-padding-bottom: $global-spacing/2;
$form-item-error-padding-left: $form-padding-left;
$form-item-error-padding-right: $global-spacing;

form {
  .c-button[type="submit"] {
    @include screen-mobile-plus-and-smaller {
      width: 100%;
    }
  }
}

.form__item,
.c-alert {
  margin-bottom: $form-item-margin;
  text-align: left;
}

.c-total-delivery-time {
  margin-top: rem(24);
}

.form__item {
  .csb__list {
    overflow-y: auto;
    max-height: rem(400);
  }
}

.form__item--no-margin {
  margin-bottom: 0;

  @include screen-tablet-portrait-and-bigger {
    margin-bottom: 0;
  }
}

.form__title {
  @include h5;
  font-weight: $font-weight-bold;
  margin: 0 auto $global-spacing-lg;
  text-align: center;
}

.select__input,
.input__textarea,
.input__input {
  &:not([disabled]) {
    position: relative;

    @include keyboard-focus {
      ~ .form__focus-helper {
        @include focus-state-line-crawl;
      }
    }
  }
}

.form__focus-helper {
  display: block;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  transform-origin: top left;
  z-index: 0;
}

.form__item-feedback-icon {
  position: absolute;
  right: 0;
  display: none;
  bottom: 9px;

  svg {
    max-width: 20px;
    max-height: 20px;
  }
}

.form__item--error {
  &:not(.c-select) {
    .form__item-feedback-icon--error {
      display: block;
      color: var(--color-error-text);
    }
  }
}

.form__item--success {
  .form__item-feedback-icon--success {
    display: block;
    color: var(--color-success);
  }

  //.select__arrow {
    //display: none;
  //}
}


.form__item-disabled-icon {
  position: absolute;
  right: 0;
  display: none;
  bottom: 10px;

  .input--textarea & {
    right: 10px;
  }

  svg {
    max-width: 20px;
    max-height: 20px;
  }
}

.form__item--disabled {
  color: var(--color-form-item-disabled);

  .form__item-disabled-icon {
    display: block;
  }

  .form__item-feedback-icon--success,
  .form__item-feedback-icon--error,
  .select__arrow,
  .input__toggle-password {
    display: none;
  }
}

.d-none {
  display: none !important;
}
