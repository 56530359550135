.c-dropdown-linklist {
  flex: 1;
  width: 100%;

  .c-dropdown {
    display: none;

    @include screen-tablet-landscape-and-bigger {
      display: flex;
    }
  }
}

.c-dropdown-linklist--colorcode {
  display: flex;
  flex-wrap: wrap;
  padding: 0 $global-spacing;

  .c-dropdown-header {
    @include screen-tablet-landscape-and-bigger {
      flex: 1 0 100%;
      margin-left: -$global-spacing;
      margin-right: -$global-spacing;
      margin-bottom: $global-spacing-sm;
    }
  }

  .dropdown-linklist__item {
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
  }

  .dropdown-menu__refinement-item {
    padding: 0;
    border: none;
  }
}
