.c-tab-menu {
  display: flex;
  position: relative;
  z-index: 2;
}

.tab-menu__link {
  display: block;
  text-decoration: none;
  color: var(--color-text-light);
  padding-bottom: 12px;
  border-bottom: 1px solid transparent;

  @include screen-mobile-plus-and-smaller {
    flex: 1;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  @include screen-tablet-portrait-and-bigger {
    &:not(:last-child) {
      margin-right: $global-spacing-lg;
    }
  }

  &.is--active {
    color: var(--color-text);
    border-bottom: 1px solid var(--color-text);
  }
}
