$payment-image-width: 46px;
$payment-image-height: 32px;
$payment-image-border-radius: 2px;

.c-payment-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-left: -$global-spacing-xxs;
  margin-right: -$global-spacing-xxs;
}

.payment-options__image-holder {
  margin-left: $global-spacing-xxs;
  margin-right: $global-spacing-xxs;
  margin-bottom: $global-spacing;
 }

.payment-options__image {
  width: $payment-image-width;
  height: $payment-image-height;
  border-radius: $payment-image-border-radius;
  overflow: hidden;

  img {
    display: block;
  }
}

.apple-pay-custom {
  /* All browsers except Safari */
  display: none;

  /* Safari 10.1+ */
  @media not all and (min-resolution: .001dpcm)
  { @supports (-webkit-appearance:none) {
      display: block;
      background-color: white !important;
      background-image: -webkit-named-image(apple-pay-logo-black) !important;
      margin: rem(16) 0 0 !important;
      border-radius: 0 !important;
      width: 100%;
      height: rem(52);
    }
  }
}
