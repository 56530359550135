.recommended-lightbulb-set {
  @include screen-mobile-plus-and-smaller {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @include screen-tablet-portrait-and-bigger {
    max-width: 600px;
  }

  .product-line-item__added {
    @include screen-mobile-plus-and-smaller {
      margin-left: 64px;
    }
  }
}

.recommended-lightbulb-set__content {
  flex: 1;
}

.recommended-lightbulb-set__text {
  @include screen-tablet-portrait-and-bigger {
    @include text;
  }

  .c-accordion & {
    max-width: 400px;
  }
  .c-modal & {
    text-align: center;
  }
}

.recommended-lightbulb-set__icon {
  flex-shrink: 0;

  svg {
    @include screen-mobile-plus-and-smaller {
      width: rem(40);
      height: rem(38);
    }
  }

  .recommended-lightbulb-set__icon--big {
    @include screen-mobile-plus-and-smaller {
      width: rem(80);
      height: rem(80);
    }
  }
}

.recomended-lightbulb-set__icon-image {
  max-width: rem(80);
  max-height: rem(80);
}

.recommended-lightbulb-set__body {
  margin-top: rem(5);

  .product-line-item__image {
    max-width: rem(30);
    max-height: rem(30);
  }
}

.recommended-lightbulb-set__button {
  padding: rem(18) rem(25) rem(15);
  margin: rem(16) 0 0 rem(64);
  flex-shrink: 0;
  height: fit-content;

  @include screen-tablet-portrait-and-bigger {
    margin: rem(16) 0 0 rem(32);
  }
}

.recommended-lightbulb-set__toggle-button {
  &.toggle--is-active {
    .button__icon {
      transform: rotate(180deg);
    }
  }
}

.recommended-lightbulb-set__toggle-content {
  padding-right: $global-spacing-xlg;
  margin-left: -46px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms $ease-in-out-quad;

  &.toggle--is-active {
    max-height: 400px;
  }

  .product-line-item--modal {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: $global-spacing-sm;
    align-items: center;

    .product-line-item__image-wrapper {
      flex: 0 0 30px;
      max-width: 30px;
      @include aspect-ratio();
    }

    .product-line-item__name {
      font-weight: $font-weight-regular;
      color: var(--color-text-light);
      @include text-xsmall;
    }

    .product-line-item__price,
    .product-line-item__actions {
      display: none;
    }
  }
}
