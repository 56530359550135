.size-chart__button {
  padding: 0;
  color: $color-black;
  font-weight: $font-weight-regular;
  text-decoration: underline;
  @include text-small;
  margin: 0;

  @include screen-tablet-portrait-and-bigger {
    padding: 0;
  }

  @include screen-desktop-and-bigger {
    padding: 0;
  }
}
