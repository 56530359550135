.search-results__sort-button {
  &.form__item {
    margin-bottom: 0;
  }

  .select__input {
    border: none;
  }

  .select__label {
    span {
      &:first-child {
        @include screen-mobile-plus-and-smaller {
          display: none;
        }
        color: #999;
      }
    }
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
  }

  &.c-select--custom {
    .csb__list {
      top: 46px;
    }

    @include hover-focus(false) {
      .select__label span {
        border-bottom: 1px solid var(--color-form-item-hover-text);
      }
    }
  }
}
