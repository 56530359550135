// On a white (white-grey) background these colors are allowed for text:
// black, black-grey, dark-grey

:root, .t-grey {
    // Core color settings
    --color-primary: #{$color-black};
    --color-background-html: #{$color-light-grey};
    --color-background-html-0: #{rgba($color-white, 0)};
    --color-background-html-50: #{rgba($color-white, .5)};
    --color-background: #{$color-white-grey};
    --color-background-column: #{$color-light-grey};
    --color-background-mega-menu: #{$color-light-grey};
    --color-background-inverted-10: #{rgba($color-black, .1)};
    --color-background-footer: #{$color-light-grey};
    --color-background-footer-bottom: #{$color-black};
    --color-image-background: #{$color-white};
    --color-image-background-70: #{rgba($color-white, .7)};
    --color-text: #{$color-black};
    --color-text-dark: #{$color-black};
    --color-text-light: #{$color-dark-grey};
    --color-text-lightest: #{$color-tinted-grey};
    --color-text-inverted: #{$color-white};
    --color-text-inverted-70: #{rgba($color-white, .7)};
    --color-text-footer: var(--color-text);
    --color-text-footer-top: #{$color-white};
    --color-text-footer-bottom: var(--color-text-inverted);
    --color-definition-table: #{$color-dark-grey};
    --color-definition-table-title: #{$color-black-grey};
    --color-divider-line: #{$color-black};
    --color-divider-line-light: #{$color-black};
    --color-divider-line-dark: #{$color-black-grey};
    --color-slider-indicator-line: #{$color-black};
    --color-slider-status-line: #{$color-soft-grey};
    --color-light: 244, 239, 231;
    --color-tag-text: var(--color-text);
  
    // Secondary background colors
    --color-secondary-background-column: #{$color-light-grey};
    --color-secondary-background-footer: #{$color-light-grey};
  
    // Cursors
    --cursor-zoom-in: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' opacity='.7' width='40' height='40'%3E%3Crect fill='%23000000' width='50' height='50'/%3E%3Cg%3E%3Cpath fill='%23ffffff' d='M0,0v50h50V0H0z M49,49H1V1h48V49z'/%3E%3Cpolygon fill='%23ffffff' points='24.5,33.4 25.5,33.4 25.5,25.5 33.4,25.5 33.4,24.5 25.5,24.5 25.5,16.6 24.5,16.6 24.5,24.5 16.6,24.5 16.6,25.5 24.5,25.5 '/%3E%3C/g%3E%3C/svg%3E%0A") 25 25, zoom-in;
    --cursor-zoom-out: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' opacity='.7' width='40' height='40'%3E%3Crect fill='%23000000' width='50' height='50'/%3E%3Cg%3E%3Cpath fill='%23ffffff' d='M0,0v50h50V0H0z M49,49H1V1h48V49z'/%3E%3Crect fill='%23ffffff' x='16.6' y='24.5' width='16.8' height='1'/%3E%3C/g%3E%3C/svg%3E%0A") 25 25, zoom-out;
  
    // Specific spacing
    --main-spacing-top: #{$header-height + $global-spacing};
    --main-spacing-top-mobile: #{$header-height + $global-spacing};
    --main-spacing-top-negative: #{($header-height + $global-spacing) * -1};
    --main-spacing-top-mobile-negative: #{($header-height + $global-spacing) * -1};
    --default-block-space-mobile: #{$gs40};
    --default-block-space-desktop: #{$gs48};
    --default-block-space-mobile-negative: #{$gs40 * -1};
    --default-block-space-desktop-negative: #{$gs48 * -1};
    --default-block-space-small: #{$global-spacing-lg};
    --default-pd-asset-space: #{$global-spacing-sm};
    --hero-image-height-mobile: 325px;
    --hero-image-height-desktop: 675px;
  
    // Menu settings
    --hamburger-size: 36px;
    --hamburger-thickness: 1px;
    --hamburger-spacing: 5px;
    --hamburger-border-radius: 0;
  
    // Core background settings see stylesheet.isml
  
    // Button colors
    --color-product-button: #{$color-black};
  
    // - Primary
    --color-button-primary-background: #{$color-black};
    --color-button-primary-text: #{$color-white};
    --color-button-primary-background-hover: #{$color-white};
    --color-button-primary-text-color-hover: #{$color-black};
    --color-button-primary-border-color-hover: #{$color-black};
    --color-button-primary-background-focus: #{$color-dark-grey};
    --color-button-primary-background-disabled: #{$color-light-grey};
    --color-button-primary-font-disabled: #{$color-dark-grey};
  
    // - Ghost
    --color-button-ghost: #{$color-black};
    --color-button-ghost-underline: #{$color-black};
    --color-button-ghost-hover: #{$color-tinted-grey};
    --color-button-ghost-hover-text: #{$color-dark-grey};
    --color-button-ghost-hover-icon-only: #{$color-black-grey};
    --color-button-ghost-focus: #{$color-tinted-grey};
    --color-button-ghost-focus-text: #{$color-black-grey};
    --color-button-ghost-focus-background: #{$color-light-grey};
    --color-button-ghost-disabled: #{$color-grey};
    --color-button-ghost-disabled-text: #{$color-dark-grey};
  
    // Form Colors
    --color-form-item-placeholder: #{$color-dark-grey};
    --color-form-item-default: #{$color-dark-grey};
    --color-form-item-hover: #{$color-black};
    --color-form-item-hover-text: #{$color-black};
    --color-form-item-focus: #{$color-black};
    --color-form-item-focus-text: #{$color-black};
    --color-form-item-disabled: #{$color-dark-grey};
    --color-form-item-disabled-text: #{$color-dark-grey};
    --form-checkbox-icon: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTAgNiI+Cjxwb2x5Z29uIGZpbGw9ImN1cnJlbnRDb2xvciIgcG9pbnRzPSI5LjMsMCAzLjYsNC44IDAuNywyLjQgMCwzIDMuNiw2IDEwLDAuNyAiLz4KPC9zdmc+Cg==');
    --shipping-method-border: #{$color-grey};
    --shipping-method-border-focus: #{$color-black};
    --shipping-method-border-hover: #{$color-dark-grey};
    --shipping-method-border-active: #{$color-black};
  
    // States colors
    --color-success: #{$color-white};
    --color-alert-success: var(--color-success);
    --color-success-text: #{$color-black};
    --color-success-alert-text: var(--color-text);
    --color-error: #{$color-white};
    --color-alert-error: var(--color-error);
    --color-error-text: #{$color-warm-red};
    --color-error-alert-text: var(--color-text);
    --color-info: #{$color-white};
    --color-alert-info: var(--color-info);
    --color-info-text: #{$color-black};
    --color-info-alert-text: var(--color-text);
  
    // Invert
    --arco-invert: invert(0);
  }
  