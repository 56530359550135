.c-order-details {
  margin-top: -$global-spacing-xlg;
}
.order-details__card {
  padding-top: $global-spacing-xxxxlg;

  &--summary {
    background-color: var(--color-background-column);
    padding-top: $global-spacing-xlg;
    position: relative;

    &::before,
    &::after {
      content: "";
      background-color: var(--color-background-column);
      position: absolute;
      top: 0;
      height: 100%;
      width: 50vw;
    }

    &::before {
      left: -50vw;
    }

    &::after {
      right: -50vw;
    }
  }

  @include screen-tablet-landscape-and-bigger {
    &--summary {
      padding-top: 164px;

      &::before,
      &::after {
        content: none;
      }

      &.o-col-10--md {
        padding-left: 8.33333%;
        padding-right: 8.33333%;
      }
    }
  }
}

.order-details__items {
  padding: $global-spacing-md 0;
  border-top: 1px solid var(--color-divider-line);
  border-bottom: 1px solid var(--color-divider-line);
}

.order-details__item-row {
  &:last-child {
    .product-line-item--wide {
      .product-line-item__body {
        border-bottom: 0;
      }
    }
  }
}

.order-details__item {
  &.c-product-line-item {
    padding: 0 0 $global-spacing-md 0;
    border-bottom: none;
  }

  .order-details__item-row:last-child & {
    padding-bottom: 0;
  }
}

.order-details__title {
  margin-bottom: $global-spacing-md;
}

.order-details__totals {
  padding: $global-spacing-xlg 0;
}

.order-details__summary-item-header {
  @include h4;
  font-weight:  $font-weight-bold;
  padding-bottom: $global-spacing-md;
  border-bottom: 1px solid var(--color-divider-line);
}

.order-details__summary-item {
  margin-bottom: $global-spacing-xxxlg;
}

.order-details__summary-item-body {
  padding-top: $global-spacing-md;
  display: flex;
  flex-direction: column;

  &--status {
    flex-wrap: wrap;
    align-items: center;
  }

  &.canceled {
    color: var(--color-error);
  }
}

.order-details__summary-item-link {
  text-decoration: underline;
}

.order-details__address {
  span {
    display: block;
  }
}

.order-details__orderstatus-icon {
  margin-right: $global-spacing * 1.5;
}

.order-details__tracking {
  margin-top: $global-spacing-md;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.order-details__tracking-label {
  flex-basis: 100%;
  font-size: $font-size-small;
  line-height: $global-spacing-md;
  color: var(--color-text-light);
}

.order-details__tracking-nr {
  font-weight: $font-weight-bold;
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid var(--color-button-ghost-underline);
}
