// @shelf-version 1.1.0
$modal-max-width: 700px !default;
$modal-gutter: $global-spacing * 2 !default;
$modal-gutter-small: $global-spacing * 2 !default;
$modal-transition-time: 400ms !default;
$modal-transition-ease: $ease-in-out-quad !default;
$modal-min-width-media-query: ($modal-max-width + $modal-gutter-small * 2) - 0.5;

$modal-flyout-min-width: 400px !default;
$modal-flyout-max-width: 500px !default;

@import 'modal/variant/mixin.small';
@import 'modal/variant/mixin.medium';
@import 'modal/variant/mixin.large';
@import 'modal/variant/mixin.flyout';
@import 'modal/variant/mixin.fill-content';
@import 'modal/variant/mixin.search';
@import 'modal/variant/mixin.newsletter';
@import 'modal/variant/mixin.fullscreen';

.is--modal-open {
  overflow: hidden;

  body {
    overflow: hidden;

    .is-touch & {
      position: fixed;
    }
  }
}

.c-modal {
  z-index: 970;
  position: fixed;
  top: 0;
  left: -100vw;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: $modal-transition-time $modal-transition-ease, 0ms linear $modal-transition-time;
  transition-property: opacity, visibility;
  visibility: hidden;

  &:not(.modal--flyout) {
    -webkit-overflow-scrolling: touch;
  }

  &.modal--is-showing {
    left: 0;
    opacity: 1;
    pointer-events: all;
    transition: $modal-transition-time $modal-transition-ease;
    transition-property: opacity;
    visibility: visible;

    .modal__content {
      opacity: 1;
      transition: $modal-transition-time $modal-transition-ease 100ms,
      $modal-transition-time $modal-transition-ease;
      transition-property: transform, opacity;
    }

    .modal__background {
      left: 0;
      opacity: 1;
      transition: $modal-transition-time ease-out 200ms, 0ms;
      transition-property: opacity, left;
    }
  }
}

.c-modal__image--sample {
  max-width: 100%;

  @include screen-tablet-landscape-and-bigger {
    max-width: 450px;
  }
}

.c-modal-sample {
  .modal__content {
    overflow-y: auto !important;
  }
}

.modal__container {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  padding: $global-spacing-md;
  display: flex;
  justify-content: center;
  align-items: center;

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-xlg;
  }
}

.modal__content {
  background-color: var(--color-background);
  width: 100%;
  max-width: $modal-max-width;
  opacity: 0;
  padding: $modal-gutter;
  position: relative;
  transition: $modal-transition-time $modal-transition-ease,
  $modal-transition-time $modal-transition-ease;
  transition-property: transform, opacity;
  z-index: 1;
  overflow-y: auto;
  max-height: calc(100vh - 160px);
}

.modal__button-close {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: $global-spacing * 1.5;
  right: $global-spacing * 1.5;
  z-index: 1;
  font-size: 2rem;

  @include screen-tablet-landscape-and-bigger {
    top: $global-spacing-md;
    right: $global-spacing-md;
  }

  @include hover-focus {
    color: var(--color-text-dark);
  }

  svg * {
    transition: fill 250ms $ease-out-quad;
  }
}

.modal__background {
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba($color-black, 0.4);
  transition: 200ms ease-out $modal-transition-time, 0ms linear 800ms;
  transition-property: opacity, left;
  cursor: pointer;
}

//Variants
.modal--fill-content {
  @include modal--fill-content;
}

.modal--small {
  @include modal--small;
}

.modal--medium {
  @include modal--medium;
}

.modal--large {
  @include modal--large;
}

.modal--flyout {
  @include modal--flyout;
}

.modal--search {
  @include modal--search;
}

.modal--newsletter {
  @include modal--newsletter;
}

.modal--fullscreen {
  @include modal--fullscreen;
}

.modal--fullscreen-sm {
  @include screen-mobile-plus-and-smaller {
    .modal__container {
      padding: 0;
    }
    .modal__content {
      max-height: 100%;
      min-height: 100%;
    }
  }
}

.adyen-threeDS2-modal__background {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba($color-black, 0.4);
}

#adyenModalDialog,
#adyenThreeDS2Container{
  position: relative;
  min-width: 390px;
  min-height: 400px;
  background-color: var(--color-background);
}
