.c-quote {
  @include default-pd-asset-space;
  display: block;
}

.quote__text-container {
  padding-bottom: $global-spacing-md;
}

.quote__author-wrapper {
  position: relative;
  margin-top: $gs4;

  @include screen-tablet-landscape-and-bigger {
    margin-top: rem(18px);
  }
}

.quote__author-name {
  @include h6;
  font-weight: $font-weight-semi-medium;
}
