.c-basic-entry {
  @include default-pd-asset-space;
  display: block;
  position: relative;
  text-decoration: none;
  background-image: none;

  @include hover-focus {
    .basic-entry__image .image__default {
      transform: scale(1.05);
    }
  }

  @include screen-tablet-portrait-and-smaller {
    .u-spacing--default-block-small *[class*='o-col']:last-child & {
      margin-bottom: -$global-spacing;
    }
  }
}

.basic-entry--brand-image {
  @include hover-focus {
    .basic-entry--second-image {
      opacity: 1;
      transition: opacity 300ms ease-in-out;
    }
  }
}

.basic-entry__title {
  font-size: $font-size-set-h6;
  line-height: rem(26);
  margin-top: $gs8;
  font-weight: $font-weight-semi-medium;
}

.basic-entry__subtitle {
  display: block;
  font-size: $font-size-set-text;
  line-height: rem(26);
  font-weight: $font-weight-bold;
}

.basic-entry__body {
  display: block;
}

.basic-entry__description {
  font-size: rem(13);
  line-height: $line-height-h6;
  margin-top: $gs4;
}
