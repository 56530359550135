$svg-designholding-loading: '<svg focusable="false" class="c-icon icon--loading" viewBox="0 0 19 18" width="30px" height="30px"><g><path fill="currentColor" d="M5,5.8H1.7L4,3.4C4.8,2.6,5.6,2,6.5,1.6c1.9-0.8,4-0.8,5.9,0c0.9,0.4,1.8,1,2.5,1.7l0.7-0.7c-0.8-0.8-1.8-1.5-2.8-2 c-2.1-0.9-4.6-0.9-6.7,0c-1.1,0.5-2,1.1-2.8,2L1,5V1.6H0v5.1h5V5.8z"></path><path fill="currentColor" d="M14,11.2v1h3.3L15,14.6c-0.7,0.7-1.6,1.3-2.5,1.7c-1.9,0.8-4,0.8-5.9,0c-0.9-0.4-1.8-1-2.5-1.7l-0.7,0.7 c0.8,0.8,1.8,1.5,2.8,2C7.2,17.8,8.3,18,9.5,18c1.2,0,2.3-0.2,3.3-0.7c1.1-0.5,2-1.1,2.8-2L18,13v3.4h1v-5.1H14z"></path></g></svg>';

@mixin spinnert($size: 30px) {
  backface-visibility: hidden;
  transform: translateZ(0);
  width: $size;
  min-width: $size;
  height: $size;
  min-height: $size;
  @include background-svg($svg-designholding-loading);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  animation: designholding-loader-spin 1000ms infinite ease-in-out;
  background-repeat: no-repeat;
  transform-origin: center;
}
