@mixin modal--newsletter {
  @include background-grain;
  top: 0;

  .modal__container {
    height: 75vh;
    padding: 0;
    bottom: 0;
    position: absolute;

    @include screen-tablet-landscape-and-bigger {
      height: auto;
      overflow: auto;
      max-height: 100vh;
    }
  }

  .modal__content {
    max-width: none;
    width: 100%;
    padding: $global-spacing-lg 0;
    height: 100%;
    max-height: none;
    background: $color-white;
    padding-left: $global-spacing-md;
    padding-right: $global-spacing-md;

    @include screen-tablet-landscape-and-bigger {
      padding-left: $global-spacing-xlg;
      padding-right: $global-spacing-xlg;
    }

    .c-newsletter-form {
      margin-bottom: $global-spacing * 7;
    }
  }
}
