//.c-cart-totals {}

.cart-totals__line {
  justify-content: space-between;
  color: var(--color-text);
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: $global-spacing-sm;
  }

  span {
    color: currentColor;
  }
}
.cart-totals__line--discount {
  font-weight: $font-weight-bold;
  color: var(--color-text--black);
}

.cart-totals__line--message,
.cart-totals__line--grand-total {
  border-top: 1px solid var(--color-divider-line);
  margin-top: $global-spacing-lg;
  padding-top: $global-spacing-lg;
}

.cart-totals__line-grand-total-label {
  .order-details__card & {
    @include h4;
    text-transform: none;
  }
}

.cart-totals__line-grand-total-vat {
  .order-details__card & {
    display: block;
    color: var(--color-text-light);
  }
}

.cart-totals__content {
  margin-bottom: 0;
  font-weight: normal;
  &.small__content {
    font-size: $font-size-medium;
  }
}

.cart-totals__price {
  font-weight: $font-weight-bold;
  text-align: right;
  flex: 1 0 auto;
  margin-left: $global-spacing * 2;

  .order-details__card .cart-totals__line--grand-total & {
    @include h4;
  }
}

.hide-vat {
  display: none;
}
