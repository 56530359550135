// @shelf-version 1.1.0
$video-loader-size: 50px;
$video-loader-thickness: 4px;
$video-loader-color: $color-black;
$video-button-transition-time: 500ms;
$video-button-transition-ease: $ease-in-quad;

@import '../loader/mixins/mixins.spinnert';

@mixin video-button-show-props {
  transition: $video-button-transition-time $video-button-transition-ease,
  $video-button-transition-time $video-button-transition-ease, $video-button-transition-time;
}

.c-video {
  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: $global-spacing-sm;

  .video__image,
  .video__player,
  iframe,
  video {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .experience-assets-video {
    margin-bottom: 0;
  }
}

.video__player {
  z-index: 1;
  opacity: 0;
  transform: scale(0.95);
  transition: 250ms $ease-in-quad;
  transition-property: opacity, transform;

  .video--is-initialised & {
    opacity: 1;
    transform: scale(1);
  }
}

.video__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::before {
    content: '';
    @include spinnert;
    pointer-events: none;
  }

  .video--is-initialised & {
    opacity: 0;
    pointer-events: none;

    &::before,
    &::after {
      animation: none;
    }
  }
}

.video__image {
  opacity: 1;
  z-index: 1;
  pointer-events: none;

  .video--is-playing & {
    opacity: 0;
  }
}

.video__description {
  @include text-small;
  color: $color-dark-grey;
  margin-bottom: 0;

  @include screen-tablet-landscape-and-bigger {
    max-width: 35%;
    margin-bottom: $global-spacing-xxxxlg;
  }
}
