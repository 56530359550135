@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@mixin content-visible {
  visibility: visible;
}

@mixin content-hidden {
  visibility: hidden;
}
