.c-tag {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  margin-bottom: $global-spacing;
  margin-right: $global-spacing;
  width: auto;
  color: var(--color-tag-text);

  &.tag--circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &.tag--clean {
    color: var(--color-tag-text);
  }
}

.tag__label {
  @include text-small($line-height: false);
  line-height: 1.4;
  font-weight: $font-weight-bold;
  background-color: var(--color-tag);
  padding: 7px 8px 5px;
  transition: opacity 250ms;

  .tag--circle & {
    line-height: 1;
    text-align: center;
    letter-spacing: -1px;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tag--clean & {
    background: transparent;
    font-weight: $font-weight-regular;
    padding-left: 0;
    padding-right: 0;
  }
}
