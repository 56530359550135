// =========================================================================
//
//  PRECISE CONTROL OVER RESPONSIVE TYPOGRAPHY FOR SASS
//  ---------------------------------------------------
//  Indrek Paas @indrekpaas
//  Edited by Adrian Klingen for rem support
//
// =========================================================================

@function if-important($important){
  @return #{if($important, '!important', '')};
}

@mixin fluid-type(
  $max-value,
  $min-value,
  $properties: font-size,
  $max-vw: $breakpoint-laptop,
  $min-vw: $breakpoint-mobile-plus,
  $important: false
) {
  @each $property in $properties {
    #{$property}: rem-to-px($min-value);
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(
        #{rem-to-px($min-value)} +
          #{tenfold(strip-unit($max-value - $min-value))} *
          (100vw - #{$min-vw}) /
          #{strip-unit($max-vw - $min-vw)}
      ) if-important($important);
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: rem-to-px($max-value) if-important($important);
    }
  }
}

@mixin fluid-line-height(
  $line-height-set: $line-height-set-text-small,
  $important: false
) {
  @each $spacing-breakpoint-modifier, $line-height in $line-height-set {
    @media screen and (min-width: map-get($grid-breakpoints, $spacing-breakpoint-modifier)) {
      line-height: $line-height if-important($important);
    }
  }
}

@function tenfold($size) {
  @return (strip-unit($size) * 10);
}

@function rem-to-px($size) {
  @return tenfold($size) + px;
}
