@mixin make-colors($colors, $property: background-color) {
  @each $name, $color in $colors {
    &--#{$name} {
      #{$property}: $color;
    }
  }
}

@mixin make-swatch-colors($colours, $property: color) {
  @each $name, $color in $colours {
    &-#{$name} {
      .checkbox__label{
        #{$property}: $color;
      }
    }
  }
}
