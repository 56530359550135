@mixin checkbox-disabled {
  cursor: default;
  color: var(--color-form-item-disabled-text);

  &::after,
  &::before {
    border-color: var(--color-form-item-disabled);
    cursor: default;
    opacity: 0;
  }

  .form__item-disabled-icon {
    display: block;
    left: 0;
    bottom: auto;
    top: 7px;
  }
}

.c-checkbox {
  &.disabled,
  &[disabled] {
    > .checkbox__label {
      @include checkbox-disabled;
    }
  }

  .form__item-info-message {
    padding-left: $checkbox-size + $global-spacing;
  }
}
