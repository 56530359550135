@mixin button--underline {
  position: relative;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  color: var(--color-button-ghost);
  font-weight: $font-weight-regular;
  line-height: 26px;

  .button__label {
    @include multi-line-text--underline(var(--color-button-ghost-underline));
  }

  &.button--small {
    padding: 0;
  }

  .button__focus-helper {
    display: none;
  }

  &:not([disabled]):not(.disabled) {
    @include hover-focus {
      color: var(--color-button-ghost-hover-text);

      .button__label {
        background-image: linear-gradient(var(--color-button-ghost-hover-text), var(--color-button-ghost-hover-text));
      }
    }

    @include keyboard-focus {
      color: var(--color-button-ghost-focus-text);
      background-color:  var(--color-button-ghost-focus-background);
    }
  }

  &[disabled] {
    font-weight: $font-weight-regular;
    cursor: default;
    pointer-events: none;
    color: var(--color-button-ghost-disabled-text);

    .button__label {
      background-image: linear-gradient(var(--color-button-ghost-disabled), var(--color-button-ghost-disabled));
    }

    .button__icon svg {
      path,
      polygon {
        fill: var(--color-button-ghost-disabled);
      }
    }
  }

  &.button--icon {
    .button__label {
      padding-bottom: 0;
      background-image: none;
    }

    svg {
      path,
      polygon {
        fill: currentColor;
      }
    }
  }
}

@mixin button-underline-color-update($color, $color-hover) {
  color: $color;

  .button__label {
    background-image: linear-gradient($color, $color);
  }

  &:not([disabled]):not(.disabled) {
    @include hover-focus {
      color: $color-hover;

      .button__label {
        background-image: linear-gradient($color-hover, $color-hover);
      }
    }
  }
}
