$spotlight-size-mobile: 100px;
$spotlight-size-desktop: 250px;

.c-campaign-page-block {
  --spot-pos-x: -500%;
  --spot-pos-y: -500%;
  // mask is 200% big to prevent ugly blend mode of spot
  // so mask position is different from spot position
  --mask-pos-x: -500%;
  --mask-pos-y: -500%;

  margin-top: var(--main-spacing-top-mobile-negative);
  padding-top: var(--main-spacing-top-mobile);
  overflow: hidden;
  padding-bottom: calc(#{$spotlight-size-mobile} / 2);

  @include screen-tablet-landscape-and-bigger {
    padding-bottom: calc(#{$spotlight-size-desktop} / 2);
  }
}

.campaign-page-block__image-block,
.campaign-page-block__image-wrapper {
  position: relative;
}

.campaign-page-block__image {
  pointer-events: none;
}

.campaign-page-block__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  transform: translate3d(-25%, -25%, 0);
  pointer-events: none;
  @include background-grain;
  opacity: 0.8;

  mask: radial-gradient(
          circle at var(--mask-pos-x) var(--mask-pos-y),
          transparent 30px,
          black 100px
  );
  -webkit-mask: radial-gradient(
          circle at var(--mask-pos-x) var(--mask-pos-y),
          transparent 30px,
          black 100px
  );
}

.campaign-page-block__spotlight {
  position: absolute;
  top: var(--spot-pos-y);
  left: var(--spot-pos-x);
  pointer-events: none;
  mix-blend-mode: multiply;

  &::after {
    content: '';
    display: block;
    pointer-events: none;
    width: $spotlight-size-mobile;
    height: $spotlight-size-mobile;
    transform: translate3d(-50%, -50%, 0);
    background: radial-gradient(circle closest-side, rgba(var(--color-light), .3) 0%, rgba(var(--color-light), .15) 60%, rgba(var(--color-light), 0) 100%);

    @include screen-tablet-landscape-and-bigger {
      width: $spotlight-size-desktop;
      height: $spotlight-size-desktop;
    }
  }
}

.campaign-page-block__content {
  position: relative;
  z-index: 2;
}

.campaign-page-block__text {
  &:not(.toggle--is-active) {
    @include line-clamp(5);

    > *,
    li {
      display: inline;
    }
  }
}

.campaign-page-block__image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  transform: translate3d(0, calc(100% + #{$global-spacing}), 0);

  .is-touch & {
    display: none;
  }
}

.campaign-page-block__image-caption--touch {
  display: none;

  .is-touch & {
    display: block;
  }
}



.campaign-page-block__image-caption-text {
  @include screen-mobile-plus-and-smaller {
    @include text-xsmall;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  @include screen-tablet-portrait-and-bigger {
    @include text-small;
  }
}