.spare-part-list {

  &__header-subtitle {
    margin-bottom: $global-spacing-sm;
    font-weight: bold;
  }

  &__header-link {
    display: block;
    margin-bottom: $global-spacing-xxlg;
    font-weight: bold;
  }

  &__header-variant-wrapper {
    width: 100%;

    .product-attribute__displayValue {
      padding: 14px $global-spacing-sm 13px $global-spacing-sm; // necessary to fix size and align items
    }

    .spare-part-list__header-variant-atributes-loop {
      display: flex;
      flex-direction: column;

      @include screen-tablet-landscape-and-bigger {
        flex-direction: row;
        flex-wrap: wrap;
      }

      .product-attribute__option {
        @include screen-tablet-landscape-and-bigger {
          width: calc(25% - #{$global-spacing-sm});
          margin-right: $global-spacing-sm;
        }
      }
    }
  }

  &__slider-container-wrapper {
    margin-bottom: $global-spacing-lg;
    margin-top: $global-spacing;

    @include screen-tablet-landscape-and-bigger {
      margin-top: 86px; //Custon top to match design requirements
    }

    .slider__thumbs-nav {
      padding-top: $global-spacing-sm;
    }

    .c-slider {
      width: calc(100% + #{$global-spacing-md});

      @include screen-tablet-landscape-and-bigger {
        width: auto;
        position: sticky;
        top: calc(#{$global-spacing-md} + 110px); // header desktop size
        transition: top 300ms linear; // same as header
      }
    }
  }

  &__image-thumb-wrapper {
    cursor: pointer;
    margin-right: $global-spacing-sm;
    opacity: 0.5;
    position: relative;

    &:last-of-type {
      margin-right: 0;
    }

    &.tns-nav-active {
      opacity: 1;

      &::after {
        content: '';
        height: 1px;
        width: 50%;
        background: $color-black;
        position: absolute;
        left: 25%;
        bottom: -10px;
      }
    }
  }

  &__image-file {
    width: 100%;
    height: auto;
  }

  &__product-title {
    margin-bottom: $global-spacing-md;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__product-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__product-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid $color-light-grey;
    position: relative;
    padding-bottom: $global-spacing-xxxxlg;
    margin-bottom: $global-spacing-lg;

    @include screen-tablet-landscape-and-bigger {
      padding-bottom: $global-spacing-xxlg;
      margin-bottom: $global-spacing-xxlg;
    }

    &:last-of-type {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__product-image {
    margin-right: $global-spacing-md;
    max-width: 85px;

    @include screen-tablet-landscape-and-bigger {
      max-width: 115px;
    }
  }

  &__product-image-file {
    width: 100%;
    height: auto;
  }

  &__product-wrapper {
    padding-top: $global-spacing-xlg;
  }

  &__product-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: calc(100% - 100px);
  }

  &__product-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: $global-spacing;
  }

  &__product-content-name {
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__product-content-code {
    color: $color-dark-grey;
    margin-top: $global-spacing;
  }

  &__product-content-delivery {
    margin-top: $global-spacing-sm;
  }

  &__product-price {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: space-between;
    height: auto;
    position: absolute;
    bottom: 32px;
    left: 0;
    width: 100%;
    flex-shrink: 0;

    @include screen-tablet-landscape-and-bigger {
      position: relative;
      flex-direction: column;
      width: auto;
      bottom: unset;
      left: unset;
    }
  }

  &__price-value {
    font-weight: bold;
  }

  &__price-link {
    padding: 0;
    color: $color-dark-grey;
    font-weight: $font-weight-regular;
  }
}

// for PDP only
.spare-parts__pdp-button {
  justify-content: space-between;

  @include screen-mobile-plus-and-smaller {
    justify-content: flex-end;
  }
}
.spare-parts__pdp-button-popular {
  @include screen-mobile-plus-and-smaller {
    display: none;
  }
}

//.spare-part-list__image-thumbs-wrapper {}

.spare-part-list__image-thumb {
  width: 80px;

  @include screen-tablet-landscape-and-bigger {
    width: 100px;
  }
}

// Sticky carousel when header is not active
.header--is-hidden {
  .spare-part-list {
    &__slider-container-wrapper {
      .c-slider {
        @include screen-tablet-landscape-and-bigger {
          top: $global-spacing-md;
        }
      }
    }
  }
}
