// overrides for black themed pages.
.c-site-popup {
  color: $color-black;

  .c-button--ghost {
    color: $color-black;
    box-shadow: inset 0 0 0 1px $color-black;

    &:not([disabled]):not(.disabled) {
      @include hover-focus {
        color: $color-black;
        box-shadow: inset 0 0 0 1px $color-black-grey;
      }
    }
  }
}

.site-popup__modal {
  .modal__content {
    height: auto;
    max-width: 500px;

    @include screen-tablet-landscape-and-bigger {
      max-width: 600px;
    }
  }

  .modal__button-close {
    color: $color-black;

    @include hover-focus {
      color: $color-black-grey;
    }
  }
}

.site-popup__cta {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: $global-spacing-xlg;
}

.cta-popup__clickarea {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

