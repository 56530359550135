// @shelf-version 1.0.0
$tabs-transition-time: 300ms;
$tabs-transition-ease: $ease-in-out-quad;

.c-tabs {
  margin-bottom: $global-spacing * 3;
}

// ------------------------------------------
// TABS NAV - NAVIGATION
// ------------------------------------------
.tabs__nav {
  @include scroll-container;
  @include scrollbars(0, $color-white, $color-white);
  padding: 0 $global-spacing /2;
  margin: 0;
  list-style: none;
  position: relative;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row;
  }

  &::before {
    content: '';
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background-color: $color-grey;
  }
}

.tabs__nav-item {
  display: inline-flex;
  width: calc(100% / 2);
  text-align: left;
  margin: 0 $global-spacing /2;

  @include screen-tablet-landscape-and-bigger {
    width: auto;
  }
}

.tabs__nav-button {
  font-weight: $font-weight-bold;
  width: 100%;
  height: auto;
  text-align: center;
  padding: $global-spacing-xs * 1.5 0;
  justify-content: center;
  color: $color-black;
  background: $color-white;
  transition: $tabs-transition-time $tabs-transition-ease;
  transition-property: box-shadow, opacity;
  box-shadow: inset 0 0 0 0 $color-black;
  border: 1px solid $color-white;

  @include keyboard-focus {
    position: relative;
    @include focus-state-background-pulse;
  }

  @include screen-tablet-landscape-and-bigger {
    min-width: auto;
    padding: $global-spacing-xs * 1.5 $global-spacing-xs * 1.5 $global-spacing-xs $global-spacing-xs *
      1.5;
  }

  &:not(.tabs__nav-button--is-active) {
    background-color: $color-black;
    color: $color-white;

    @include hover-focus(false) {
      opacity: 1;
      color: $color-black;
    }
  }

  &.tabs__nav-button--is-active {
    box-shadow: inset 0 -4px 0 0 $color-black;
  }
}

.tabs__nav--no-underline {
  &::before {
    display: none;
  }

  .tabs__nav-button--is-active {
    background-color: $color-white;
    color: $color-black;
    box-shadow: none;
  }
}

// ------------------------------------------
// TAB PANEL - SINGLE PANEL
// ------------------------------------------
.tabs__tabpanel {
  display: none;

  &.is--active {
    display: block;
  }

  &.is--active-desktop {
    display: block;
  }
}

.c-tabs--search {
  margin-bottom: 0;

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: $global-spacing * 3;
  }

  .tabs__nav {
    &::before {
      content: none;
    }
  }

  .tabs__nav-item {
    width: auto;

    &:first-child {
      .tabs__nav-button {
        padding-left: 0;
      }
    }
  }

  .tabs__nav-button {
    padding: $global-spacing * 1.5 $global-spacing * 1.5;
    text-align: left;
    justify-content: flex-start;
  }

  .tabs__nav-button--is-active {
    box-shadow: none;
  }
}
