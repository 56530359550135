$modal-medium-padding-mobile: $global-spacing-xlg;
$modal-medium-padding-desktop: $global-spacing * 7.5 $global-spacing * 14.25;
$modal-medium-width: 984px;

@mixin modal--medium {
  .modal__content {
    padding: $modal-medium-padding-mobile;
  }

  @include screen-tablet-landscape-and-bigger {
    .modal__container {
      height: auto;
      max-height: calc(100% - #{$global-spacing-sm});
      max-width: $modal-medium-width;
    }

    .modal__content {
      padding: $modal-medium-padding-desktop;
    }
  }
}
