@mixin modal--search {
  height: calc(var(--vh, 1vh) * 100 - var(--header-height));
  top: var(--header-height);

  //@include screen-tablet-landscape-and-bigger {
  //  height: calc(var(--vh, 1vh) * 100 - #{$header-height-desktop});
  //  top: $header-height-desktop;
  //}

  .modal__background {
    display: none;
  }

  .modal__container {
    padding: 0;
    background-color: var(--color-background-html);
    @if variable-exists('show-body-background-image') {
      @include background-grain;
    }
  }

  .modal__content {
    max-width: none;
    width: 100%;
    padding: $global-spacing-lg 0;
    height: 100%;
    max-height: none;
    background: transparent;
    padding-left: $global-spacing-md;
    padding-right: $global-spacing-md;

    @include screen-tablet-landscape-and-bigger {
      padding-left: 0;
      padding-right: 0;
      width: calc(100% - #{$global-spacing-xlg} - #{$global-spacing-xlg});
      max-width: calc(#{$breakpoint-laptop} - #{$global-spacing-xlg} - #{$global-spacing-xlg});
    }
  }
}
