@mixin modal--fullscreen {
  .modal__container {
    padding: 0;
  }

  .modal__content {
    color: var(--color-text);
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    padding: 0;
    background-color: var(--color-background-html);
    @if variable-exists('show-body-background-image') {
      @include background-grain;
    }
  }

  .modal__button-close {
    top: 14px;
    right: $global-spacing-md;

    @include screen-tablet-landscape-and-bigger {
      right: $global-spacing-xlg;
    }

    @include screen-laptop-and-bigger {
      right: 50%;
      transform: translateX(#{($grid-max-width / 2) - $global-spacing-xxlg});
    }
  }
}
