.c-logo {
  display: block;
  svg {
    width: auto;
    height: auto;
  }
}

.logo--full-width {
  svg {
    height: auto;
    width: 100%;
    max-width: 100%;
  }
}
