$newsletter-button-sizes: 216px;

.c-footer {
  margin-top: auto;
  z-index: 0;
  background-color: var(--color-background-footer);
  border-top: 1.2px solid $color-black;

  &:not(.footer--minimal) {
    padding-top: $global-spacing-xxlg;
  }

  &.footer--minimal {
    margin-top: $global-spacing-xxxxlg;

    @include screen-tablet-landscape-and-bigger {
      margin-top: 0;
    }
  }

  .c-accordion {
    margin-bottom: 0;
  }

  .accordion__item {
    border: none;
  }

  .accordion__item--mobile-only {
    margin-bottom: $gs40;
  }

  .accordion__item-content {
    padding-top: 12px;
    padding-bottom: $gs24;

    @include screen-tablet-landscape-and-bigger {
      padding: 0;
    }
  }
}

.c-footer.footer--secondary-background {
  background-color: var(--color-secondary-background-footer);
}

.footer--minimal {
  .footer__bottom {
    .o-grid {
      border-top: none !important;
    }
  }
}

.footer__top {
  position: relative;
  padding-bottom: 107px;

  @include screen-tablet-landscape-and-bigger {
    padding-bottom: $gs120;
  }

  .s-rich-text a[href]:not(.c-button) {
    @include hover-focus {
      color: var(--color-text);
    }
  }
}

.footer__byline {
  @include text-small;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  margin-bottom: $global-spacing-xxs;

  @include screen-tablet-portrait-and-smaller {
    display: none;
  }
}

.footer__links {
  &:first-child {
    border-top: 0;
  }

  .accordion__item-header {
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    padding: 0;

    @include screen-tablet-landscape-and-bigger {
      margin-bottom: $gs8;
    }
  }
}

.footer__links-list {
  @include list-reset;
}

.accordion__item-header {
  letter-spacing: 0.1em;
  font-weight: $font-weight-medium;
}

.accordion__item-header,
.footer__links-list-item {
  @include text-small;
}

.footer__links-list-item {
  padding-left: 0;

  &:not(:last-child) {
    margin-bottom: $gs4;
  }
}

.footer__links-list-item a {
  @include hover-focus {
    color: currentColor !important;
  }
}

.footer__links-list-item a,
button.footer__text-link {
  @include link--base-reversed;
}

.is-homepage > .footer__newsletter {
  margin-top: 0;
}

.footer__newsletter {
  background-color: $color-dark-grey;
  color: $color-white;

  @include screen-tablet-portrait-and-smaller {
    margin-top: $global-spacing-md;
  }

  @include screen-tablet-portrait-and-bigger {
    margin-top: $global-spacing-xxxxlg;
  }

  .o-grid {
    position: relative;
    align-items: center;
    flex-direction: column;

    @include screen-tablet-landscape-and-bigger {
      flex-direction: row;
    }
  }

  .newsletter-subscription-form__form {
    display: flex;
    flex-direction: column;

    @include screen-tablet-landscape-and-bigger {
      width: 70%;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .form__item {
      margin-right: $global-spacing-sm;

      @include screen-tablet-landscape-and-bigger {
        width: 35%;
      }

      &.c-radio {
        width: 100%;

        .radio__wrapper {
          display: flex;
          flex-direction: column;

          @include screen-tablet-portrait-and-bigger {
              flex-direction: row;
          }
        }
      }

      &.c-checkbox {
        margin-bottom: 0;
        width: 100%;
      }
    }
  }

  .newsletter-form__content {
    .c-newsletter-form {
      margin: 0 0 $global-spacing-md  0 !important;
    }

    .c-alert {
      position: relative;
      width: initial;
      margin: 0 !important;

      @include screen-tablet-landscape-and-bigger {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: $global-spacing-xxxxlg !important;
      }
    }
  }

  .newsletter-form__button {
    @include text-xsmall;
    position: relative;
    text-transform: uppercase;
    margin: $global-spacing-xlg auto 0;
    width: $newsletter-button-sizes !important;

    @include screen-tablet-landscape-and-bigger {
      position: absolute;
      right: 0;
      top: 0;
      margin-top: $global-spacing-xxxxlg;
    }
  }
}

.footer__newsletter-description {
  color: $color-soft-grey;
}

.footer__button-newsletter {
  @include text-xsmall;
  margin: 0 auto $global-spacing-xlg;
  width: $newsletter-button-sizes;

  @include screen-tablet-landscape-and-bigger {
    margin: 0 0 0 auto;
  }
}

.footer__button {
  @include h6;
  text-transform: uppercase;
  height: $font-size-set-h6;
}

.footer__bottom {
  a,
  button {
    transition: opacity 250ms ease;

    @include hover-focus {
      opacity: .7;
    }
  }
}

.footer__language-selector {
  @include screen-tablet-landscape-and-bigger {
    order: 3;
  }
}

.footer__language-selector-select-item {
  display: inline-block;

  @include screen-tablet-landscape-and-bigger {
    &.form__item {
      margin-bottom: 0;
    }
  }

  .select__input-wrapper {
    display: inline-block;
  }

  .select__input {
    @include text-small;
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 0;
    font-weight: $font-weight-medium;
  }

  .icon--chevron-down {
    width: 12px;
    height: 6px;
  }
}

.footer__socials-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include screen-tablet-landscape-and-bigger {
    display: block;
    width: unset;
  }
}

.footer__socials {
  margin-top: $gs48;
  justify-content: center;

  @include screen-tablet-landscape-and-bigger {
    margin-top: 0;
    order: 1;
  }

  &.o-col-6--md,
  &.o-col-24 {
    padding: 0 !important;
  }
}

.footer__socials-link {
  text-decoration: none;
  margin-right: $gs16;

  svg {
    height: 20px;
    width: 19px;
  }
}

.footer__socials-link:last-child {
  margin-right: 0;
}

.footer__support {
  @include screen-tablet-portrait-only {
    display: flex;
    align-items: center;
  }

  @include screen-laptop-and-bigger {
    display: flex;
    align-items: center;
  }
}

.footer__support-title {
  margin-bottom: $global-spacing-md;

  @include screen-tablet-landscape-and-smaller {
    display: flex;
    height: 28px;
    align-items: center;
  }

  @include screen-tablet-portrait-only {
    margin-bottom: 0;
    margin-right: $global-spacing-md;
  }

  @include screen-tablet-landscape-and-bigger {
    display: block;
  }

  @include screen-laptop-and-bigger {
    margin-bottom: 0;
    margin-right: $global-spacing-md;
    display: inline-block;
  }
}

.footer__support-items {
  @include screen-tablet-portrait-and-bigger {
    display: flex;
  }
}

.footer__support-link {
  font-weight: $font-weight-medium;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: $global-spacing-md;
  }

  @include hover-focus {
    color: $color-white-transparent-50;
  }
}

.footer__support-link-icon {
  margin-right: $global-spacing-xs;
}

.footer__support-link-label {
  display: block;
  padding-top: 5px;
}

.footer__partner {
  font-size: $font-size-set-h7;
  font-weight: $font-weight-semi-medium;
  padding-bottom: $global-spacing-md;

  @include screen-tablet-landscape-and-bigger {
    order: 2;
    padding: $global-spacing-sm 0;
    margin-top: 0;
    justify-content: initial;
  }
}

.footer__partner-content {
  padding-bottom: 10px;

  @include screen-tablet-landscape-and-bigger {
    display: flex;
    height: 100%;
    align-items: center;
    padding-bottom: 28px;
  }
}

.footer__newsletter-text {
  margin: $global-spacing-xxlg 0 $global-spacing-lg;
  text-align: center;

  @include screen-tablet-landscape-and-bigger {
    margin: $global-spacing * 9 0;
    text-align: initial;
  }

  &.is--open {
    margin-bottom: $global-spacing-xlg !important;
  }
}
