//
//    Can be used to create a new ( vertical ) scroll container
//

//  Uses a mixin so it can also be used inside of media queries
@mixin scroll-container(
  $scrollbar-bg-color: $color-grey,
  $scrollbar-thumb-bg-color: $color-black,
  $direction: 'vertical'
) {
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;

  @if ($direction == 'vertical') {
    overflow-x: hidden;
    overflow-y: auto;
  } @else {
    overflow-x: auto;
    overflow-y: hidden;
  }

  @include scrollbars(5px, $scrollbar-bg-color, $scrollbar-thumb-bg-color);
}

%scroll-container {
  @include scroll-container();
}
