.hero__media {
  .c-hero:not(.hero--fullwidth) & {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(#{$breakpoint-laptop - (map_get($container-gutters, 'md') * 2)});

    @include screen-tablet-portrait-only {
      width: calc(100% - #{map_get($container-gutters, 'sm') * 2});
    }

    @include screen-tablet-landscape-and-bigger {
      width: calc(100% - #{map_get($container-gutters, 'md') * 2});
    }
  }
}
