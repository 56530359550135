//.c-image-grid {}

.image-grid__item {
  width: 100%;
  margin-bottom: $global-spacing-lg;

  .c-product-tile {
    padding-bottom: 0;
  }

  &[href] {
    overflow: hidden;
    text-decoration: none;
    background-image: none;

    @include hover-focus {
      .image__default {
        transform: scale(1.03);
      }
    }

    .image__default {
      transition: transform 400ms $ease-out-quad;
    }
  }

  @include screen-tablet-portrait-and-bigger {
    margin: $global-spacing-lg 0;

    &--half {
      width: 50%;
    }

    &--middle {
      margin-top: auto;
      margin-bottom: auto;
    }

    &--push-down {
      margin-top: 200px;
    }
  }

  .product-tile__color-swatches {
    display: none;
  }
}

.image-grid__product {
  @include screen-tablet-portrait-and-bigger {
    &.image-grid__item--last {
      width: 65.8%;
      margin-left: auto;
    }
  }
}
