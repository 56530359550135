.c-cta-link {
  text-decoration: none;

  @include hover-focus {
    .cta-link__label {
      opacity: 1;
    }

    .cta-link__image {
      opacity: 1;
    }
  }
}

.cta-link__label {
  @include h4;

  @include screen-tablet-landscape-and-bigger {
    opacity: .2;
    transition: opacity 200ms;
  }
}

.cta-link__arrow {
  display: none;
}

.cta-link__image {
  display: none;
  pointer-events: none;

  @include screen-tablet-landscape-and-bigger {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    opacity: 0;
    transition: opacity 250ms ease;
  }
}
