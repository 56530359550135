.c-image-container {
  &.image--hero {
    .image__default {
      max-height: 70vh;
      height: var(--hero-image-height-mobile);

      @include screen-tablet-landscape-and-bigger {
        height: var(--hero-image-height-desktop);
      }
    }
  }
}
