// @shelf-version 2.0.0
$cookie-transition-time: 400ms;

body.cookie-bar--is-visible {
  overflow: hidden;

  .is-touch & {
    position: fixed;
  }
}

.c-cookie-bar {
  outline: none;
  position: fixed;
  @include z(cookies);
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-black, .5);
  visibility: hidden;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  transition: opacity $cookie-transition-time ease, visibility 0s ease $cookie-transition-time;

  .cookie-bar--is-visible & {
    opacity: 1;
    visibility: visible;
    transition: opacity $cookie-transition-time ease, visibility 0s ease;
  }
}

.cookie-bar__content {
  width: 100%;
  padding: rem(28) 0 rem(52);
  max-height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  background-color: var(--color-background-html);
  box-shadow: 0 0 rem(20) 0 rgba($color-black, .05);
  transform: translate3d(0, 100%, 0);
  transition: transform $cookie-transition-time $ease-in-out-quad;

  .cookie-bar--is-visible & {
    transform: translate3d(0, 0, 0);
  }

  @include screen-tablet-landscape-and-bigger {
    padding: rem(42) 0 rem(60);
  }
}

.cookie-bar__text {
  @include h6;

  font-weight: $font-weight-regular;
}

.cookie-bar__button-holder {
  display: flex;
  margin-top: $global-spacing-lg;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: flex-end;
    margin-top: 0;
  }

  .cookie-bar__button {
    @include h6;

    display: block;
    font-weight: $font-weight-regular;
    padding: rem(6) 0;
    width: 100%;

    @include screen-tablet-landscape-and-bigger {
      padding: $gs16;
      margin-top: rem(14);
    }
  }

  .cookie-bar__button--settings {
    font-size: rem(13);
  }
}
