.error-page__container {
  display: flex;
  flex-direction: column;
}

.error-page__content {
  margin-bottom: rem(120);

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: rem(210);
  }
}

.error-page__heading,
.error-page__newsletter-title {
  @include h1;
  font-weight: $font-weight-semi-medium;
}

.error-page__heading {
  line-height: 100%;
  margin-bottom: rem(40);

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: rem(14);
  }
}

.error-page__text {
  @include text;
  margin-bottom: rem(40);
}

.error-page__newsletter {
  margin-bottom: $gs64;

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: rem(108);
  }
}

.error-page__newsletter-pretitle {
  font-size: $font-size-set-h6;
  line-height: rem(26);
  margin-bottom: 0;
}

.error-page__newsletter-title {
  line-height: rem(34);
  margin-bottom: rem(28) !important;

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: 0;
  }
}

.error-page__newsletter-button {
  width: 100%;
  font-weight: $font-weight-regular;

  @include screen-tablet-landscape-and-bigger {
    width: unset;
  }
}
