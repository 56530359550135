$lap-transition-time: 300ms;
$lap-transition-ease: ease;
$lap-padding-width: 0;

.form__item--lap {
  .input__label,
  .textarea__label,
  .select__label {
    pointer-events: none;
    position: absolute;
    transform: translate3d($lap-padding-width, -50%, 0);
    transform-origin: 0 0;
    transition: $lap-transition-time $lap-transition-ease;
    transition-property: transform, color, max-width;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - (#{$lap-padding-width} * 2));
    text-overflow: ellipsis;
    color: var(--color-form-item-placeholder);
    top: 50%;
    z-index: 2;
    font-weight: $font-weight-regular;
  }

  &.input--file {
    .input__label {
      left: 20px;
      max-width: calc(100% - calc((#{$lap-padding-width} * 2) + 20px));
    }
  }
}

.form__item--lap-active {
  .input__label,
  .textarea__label,
  .select__label,
  &.input--file .input__label {
    transform: translate3d(0, -30px, 0) scale(0.7);
    max-width: 120%;
    color: var(--color-form-item-placeholder);
  }

  @include hover-focus(false) {
    .input__label,
    .textarea__label,
    .select__label {
      color: var(--color-form-item-placeholder);
    }
  }
}
