.c-image-container {
  @include default-pd-asset-space;

  a {
    text-decoration: none;
    background-image: none;
  }

  .experience-slides & {
    margin-bottom: 0;
  }
}

// @shelf-version 1.0.0
.c-image {
  position: relative;

  &.image--object-fit-contain noscript {
    display: flex;
    margin: auto;
    width: auto;
    height: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
  }

  [js-hook-objectfit-img] {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;

    .has--object-fit-polyfill & {
      opacity: 0;
    }
  }
}

.image--object-fit-contain {
  [js-hook-objectfit-img] {
    object-fit: contain;
    width: auto;
    height: auto;

    @supports (object-fit: contain) {
      width: 100%;
      height: 100%;
    }
  }
}

[js-hook-objectfit-container] {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.image--object-fit-contain {
    background-size: contain;
    object-fit: contain;
  }
}

.image__default {
  opacity: 0;
  transition: opacity 200ms ease-out;

  .image--is-loaded & {
    opacity: 1;
  }
}

.image__no-js {
  display: none;
  opacity: 1;
  transition: opacity 200ms ease-out;

  .no-js & {
    display: block;
  }
}

.image__ghost {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: opacity 200ms ease-out 50ms;

  .image--is-loaded & {
    opacity: 0;
  }
}

.image__caption {
  @include text;
  text-align: left;
  margin-top: $global-spacing-sm;
}

.image__product-container {
  position: relative;
}

.content-spot {
  .c-image-container {
    margin-bottom: $global-spacing-md !important;

    img {
      aspect-ratio: 3/4;
    }
  }

  .image-with-text {
    .c-image-container {
      margin-bottom: $global-spacing-sm !important;
    }
  }
}
