.store-locator-landing__sidebar {
  display: flex;
  align-items: center;
  height: 100%;
}

.store-locator-landing__sidebar-content {
  padding-top: $global-spacing * 8;
  padding-bottom: $global-spacing * 8;
}

.store-locator-landing__image-wrapper {
  overflow: hidden;
  height: 100%;

  img {
    height: 100%;
  }
}

.store-locator-landing__form {
  margin-top: $global-spacing * 2;
}
