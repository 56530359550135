$hamburger-width: var(--hamburger-size);
$hamburger-height: var(--hamburger-size);
$hamburger-thickness: var(--hamburger-thickness);
$hamburger-spacing: var(--hamburger-spacing);
$hamburger-offset: calc(#{$hamburger-spacing} + #{$hamburger-thickness});
$hamburger-border-radius: var(--hamburger-border-radius);

.c-hamburger {
  position: relative;
  width: $hamburger-width;
  height: $hamburger-height;
  cursor: pointer;
  margin: $hamburger-offset;


  // extend clickable area by pseudo element
  &::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 60px;
    left: -10px;
    top: -22px;
    padding: 15px;
  }
}

.hamburger__bar {
  display: block;
  position: absolute;
  height: $hamburger-thickness;
  width: calc(100% - 20px);
  background-color: currentColor;
  border-radius: $hamburger-border-radius;
  opacity: 1;
  left: 50%;
  top: 50%;
  transform-origin: left center;
  transition: 250ms ease-in-out;
  transition-property: transform, opacity, background-color;

  &:nth-child(1) {
    transform: rotate(0) translate3d(-50%, calc(-50% - (#{$hamburger-offset})), 0);
  }
  &:nth-child(2) {
    top: 50%;
    transform: rotate(0) translate3d(-50%, -50%, 0);
  }
  &:nth-child(3) {
    transform: rotate(0) translate3d(-50%, calc(-50% + (#{$hamburger-offset})), 0);
  }
}

// -----------------------------------------------
// Body Class: Header Mobile Nav Open
// -----------------------------------------------
.header--mobile-nav-open {
  .hamburger__bar {
    &:nth-child(1) {
      transform: rotate(45deg) translate3d(-50%, -50%, 0);
      top: 50%;
      left: 50%;
    }
    &:nth-child(2) {
      width: 0;
      opacity: 0;
    }
    &:nth-child(3) {
      top: 50%;
      left: 50%;
      transform: rotate(-45deg) translate3d(-50%, -50%, 0);
    }
  }
}
