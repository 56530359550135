//.c-notification-message {}

.notification-message__content {
  max-width: 900px;
  @include text-small;
}

.notification-message__link {
  margin-left: 6px;
}
