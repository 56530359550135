.c-product-detail {
  @include screen-tablet-landscape-and-bigger {
    overflow: visible;
  }
}

.product-detail__side-bar-wrapper {
  z-index: 1;
}

.product-detail__image-grid {
  @include screen-tablet-portrait-and-smaller {
    margin-top: $global-spacing-lg;
  }
}

.product-detail__grid-image-wrapper {
  .c-image-zoom:not(.image-zoom--fullscreen) {
    .image-zoom__image-wrapper {
      background-color: var(--color-image-background);

      .image-zoom__image img {
        mix-blend-mode: multiply;
      }
    }
  }
}

.product-detail__lightbulb-usps {
  position: relative;
  @include divider-line(var(--color-divider-line), top);
}

.product-detail__add-to-cart {
  margin-top: $global-spacing-sm;
}

.c-product-detail-sticky {
  margin-top: var(--main-spacing-top-negative);
  margin-bottom: var(--main-spacing-top);

  .header--is-hidden &,
  .header--is-scrolled-up & {
    transition: transform 300ms linear;
  }
}

.product-detail__accordion {
  margin-bottom: 0;
}

//make all slides same height
//to align price in card always on bottom
.product-detail__recommendations {
  .tns-slider {
    display: flex;
  }

  .tns-item {
    //sass-lint:disable no-important
    display: flex !important;
    flex: 1;
  }

  .product {
    width: 100%;
  }
}

.product-detail__lightbulb {
  display: flex;
  justify-content: space-between;
  width: 100% ;
}

.product-detail__lightbulbs-image-wrapper {
  width: 48px;
  height: auto;
}

.product-detail__lightbulbs-link {
  color: var(--color-definition-table);
}

.product-detail__product-badges-wrapper {
  display: flex;
  align-items: self-start;
  justify-content: flex-start;
  width: auto;
  flex-wrap: wrap;
}

.product-detail__product-delivery-wrapper {
  width: 100%;
}

.product-detail__promo-wrapper {
  padding-left: $global-spacing-sm;
  display: block;
  text-decoration: none;
  border-left: 1px solid var(--color-divider-line);
}

.product-detail__designer-name {
  font-size: $font-size-body;
}

.product-detail__assistant-name {
  font-size: $font-size-set-text-small;
}

.centered-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.c-product-detail,
.c-product-detail-footer {
  .experience-hero,
  .experience-main,
  .experience-footer {
    margin-top: $global-spacing-md;
    margin-bottom: $global-spacing-md;
  }

  .experience-main {
    margin-bottom: $global-spacing-sm;
  }
}

.product-detail__meet-the-designer {
  overflow: hidden;
  background-color: $color-blue-sky;
  color: $color-white;
  padding: $global-spacing * 5 0;

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing * 7 0;
  }

  p {
    color: inherit;
    font-size: $font-size-body * 0.875;
    line-height: $font-size-body * 1.25;
  }

  p,
  .c-button {
    margin-top: $global-spacing * 3;
  }

  .c-button {
    font-size: $font-size-body * 0.875;
    font-weight: 600;
    text-decoration: none;
    border: 1px solid $color-white;
    padding: $global-spacing * 1.625 $global-spacing * 2 $global-spacing * 1.25;

    &:hover {
      background-color: $color-white;
      color: $color-black;
    }
  }

  .left-column {
    display: flex;
    flex-direction: column;

    @include screen-tablet-portrait-and-smaller {
      display: none;
    }

    .left-image {
      max-width: 100%;
      height: auto;
    }

    .content-wrapper {
      margin-top: auto;

      p { margin: 0; }
    }
  }

  .right-column {
    .designer-subtitle {
      font-size: $font-size-body;
      line-height: $font-size-body * 1.5;
      font-weight: 600;

      @include screen-tablet-landscape-and-bigger {
        font-size: $font-size-body * 1.25;
      }
    }

    .designer-name {
      font-size: $font-size-body * 4;
      line-height: 1;
      letter-spacing: -0.2rem;
      margin-top: $global-spacing * 2;

      @include screen-tablet-portrait-and-smaller {
        font-size: $font-size-body * 2;
        line-height: $font-size-body * 2.5;
      }
    }

    p,
    .c-button {
      margin-bottom: 0;

      @include screen-tablet-landscape-and-bigger {
        display: none;
      }
    }

    .right-image {
      max-width: 100%;
      height: auto;
      margin-top: $global-spacing * 12.5;

      @include screen-tablet-landscape-and-bigger {
        max-width: calc(100% + #{$global-spacing * 5.2});
        width: calc(100% + #{$global-spacing * 5.2});
        margin-top: $global-spacing * 7.5;
        margin-right: $global-spacing * -5.2;
        margin-bottom: $global-spacing * -7;
      }
    }
  }

  &.black {
    color: $color-black;

    .c-button {
      border-color: $color-black;
    }
  }
}

.product-detail {

  &__special-edition-banner {
    position: relative;
    padding: $global-spacing * 4 0;

    &:not(.product-detail__special-edition-banner--not-full) {
      aspect-ratio: 358 / 560;

      @include screen-tablet-portrait-and-smaller {
        background-color: transparent !important;
      }

      @include screen-tablet-landscape-and-bigger {
        padding: $global-spacing * 3 0;
        aspect-ratio: 904 / 400;
      }
    }    

    > .o-container,
    > .o-container > .hero__text-wrapper {
      height: 100%;
    }

    > .o-container > .hero__text-wrapper {
      @include screen-tablet-landscape-and-bigger {
        align-items: center;
      }
    }

    &.black {
      color: $color-black;
    }

    &.white {
      color: $color-white;
    }


    &-title {
      font-size: $font-size-body * 1.5;
      line-height: $font-size-body * 2;
      text-transform: unset;
    }

    p {
      font-size: $font-size-body * 0.875;
      line-height: $font-size-body * 1.25;
      margin: $global-spacing * 3 0 auto;
      padding-bottom: $global-spacing * 3;

      @include screen-tablet-landscape-and-bigger {
        margin: $global-spacing * 3 0;
        padding: 0;
      }
    }

    a {
      font-size: $font-size-body;
      font-weight: 700;

      &.black {
        color: $color-black;

        span {
          background-image: linear-gradient($color-black, $color-black);
        }
      }

      &.white {
        color: $color-white;

        span {
          background-image: linear-gradient($color-white, $color-white) !important;
        }

        &:hover {
          color: $color-white !important;

          span {
            background-image: linear-gradient($color-white, $color-white) !important;
          }
        }
      }
    }

    .hero__image {
      &--mobile {
        display: block;

        @include screen-tablet-landscape-and-bigger {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include screen-tablet-landscape-and-bigger {
          display: block;
        }
      }
    }

    .hero__media-wrapper {
      z-index: 1;

      &.left {
        @include screen-tablet-landscape-and-bigger {
          z-index: 1;
          width: 49.916%;
        }
      }

      &.right {
        @include screen-tablet-landscape-and-bigger {
          z-index: 1;
          left: 50%;
          width: 50%;
        }
      }
    }

    .hero__text-wrapper {
      position: relative;
      z-index: 2;

      &__content {
        display: flex;
        flex-direction: column;
      }
    }

    &--not-full {
      display: flex;
      flex-direction: column;
      padding: 0;

      @include screen-tablet-landscape-and-bigger {
        min-height: 39.1rem;
        aspect-ratio: initial;
        flex-direction: row;
        align-items: center;
        padding: $global-spacing * 3 0;
      }

      .o-container {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;

        @include screen-tablet-landscape-and-bigger {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      .hero__media {
        aspect-ratio: 358 / 268;

        @include screen-tablet-landscape-and-bigger {
          aspect-ratio: initial;
        }
      }

      .hero__media-wrapper {
        order: -1;
        position: relative;
        top: initial;
        left: initial;
        height: auto;
        aspect-ratio: 358 / 268;

        @include screen-tablet-landscape-and-bigger {
          position: absolute;
          order: initial;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          height: auto;
          aspect-ratio: 443 / 385;
          margin-bottom: 0;
        }
      }
    }
  }
}
