.c-product-content-tile {
  padding-bottom: 150%;
  position: relative;
  margin-bottom: 30px;

  @include screen-tablet-portrait-and-bigger {
    margin-bottom: 40px;
  }

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: 60px;
  }

  @include hover-focus {
    .video__button {
      opacity: 1;
      box-shadow: 0 0 0 0 rgba($color-black, 0);

      .video__button-icon {
        color: darken($color-white, 20%);
      }
    }
  }

  @include keyboard-focus {
    .video__button {
      opacity: 1;

      .video__button-icon {
        color: darken($color-white, 20%);
      }
    }
  }
}

.c-product-content-tile--double {
  padding-bottom: 75%;

  @include screen-tablet-portrait-and-bigger {
    .product-content-tile__body {
      max-width: 60%;
    }
  }

  @include screen-laptop-and-bigger {
    padding-bottom: 75%;
  }
}

.product-content-tile__content {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: $global-spacing * 2.5;
  text-decoration: none;

  @include screen-tablet-portrait-and-bigger {
    padding: $global-spacing * 3.75;
  }
}

.product-content-tile__title {
  .product-content-tile__pretitle + & {
    margin-top: $global-spacing * 2;
  }
}

.product-content-tile__body {
  z-index: 1;
  margin: 0;

  .product-content-tile__title + &,
  .product-content-tile__pretitle + & {
    margin-top: $global-spacing * 2;
  }
}

.product-content-tile__button {
  margin: 0;

  .product-content-tile__title + &,
  .product-content-tile__body + &,
  .product-content-tile__pretitle + & {
    margin-top: $global-spacing * 3;
  }
}

.product-content-tile__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .video__button {
    pointer-events: all;
  }
}

.product-content-tile__background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.product-content-tile__background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.product-content-tile__content--top-left {
  align-items: flex-start;
  justify-content: flex-start;
}

.product-content-tile__content--top-center {
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.product-content-tile__content--top-right {
  justify-content: flex-start;
  text-align: right;
}

.product-content-tile__content--center-left {
  justify-content: center;
}

.product-content-tile__content--center-center {
  justify-content: center;
  text-align: center;
}

.product-content-tile__content--center-right {
  justify-content: center;
  align-items: flex-end;
  text-align: right;
}

.product-content-tile__content--bottom-left {
  justify-content: flex-end;
}

.product-content-tile__content--bottom-center {
  justify-content: flex-end;
  text-align: center;
}

.product-content-tile__content--bottom-right {
  justify-content: flex-end;
  text-align: right;
}

.product-content-tile__content--has-border {
  border: 6px solid currentColor;

  @include screen-tablet-landscape-and-bigger {
    border-width: 10px;
  }
}
