.tag--tooltip {
  position: relative;

  @include hover-focus {
    .tag__tooltip-text {
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
      transition: transform 250ms $ease-out-quad, opacity 250ms;;
    }
  }

  .tag__label {
    cursor: default;
  }

  &.toggle--is-active {
    html.is-touch & {
      .tag__tooltip-text {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
        transition: transform 250ms $ease-out-quad, opacity 250ms;
      }

      .tag__tooltip-icon {
        &.icon--info {
          display: none;
        }
        &.icon--close {
          display: block;
        }
      }
    }
  }
}

.tag__tooltip {
  @include text-small($line-height: false);
  display: inline-block;
  position: relative;
  padding: 7px 0 5px;
  transform: translate3d(0, -1px, 0);

  @include hover-focus {
    .tag__tooltip-icon {
      opacity: .7;
    }
  }
}

.tag__tooltip-icon {
  transition: opacity 250ms;
  line-height: 0;

  &.icon--close {
    display: none;
  }
}

.tag__tooltip-text {
  position: absolute;
  width: 150px;
  top: calc(100% + 13px);
  right: -9px;
  padding: 8px 10px 4px;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transform: translate3d(0, 10px, 0);
  transition: transform 250ms $ease-out-quad, opacity 250ms, height 0s ease 250ms;

  @include screen-tablet-landscape-and-bigger {
    width: 200px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -16px;
    left: 0;
    width: 100%;
    height: 16px;
    background-color: transparent;
  }
}

.tag__tooltip-arrow {
  display: block;
  position: absolute;
  right: 10px;
  top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid currentColor;
}
