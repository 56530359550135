//
//    Set the spacing for each viewport
//
//    @see: utilities/utilities.spacing
//
//    @example: .u-margin-top--xs
//
//
$global-spacing: 10px;
// Will be rendered as classes (u-*)
$spacing-sizes-numeral: (
  0: 0,
  4: $global-spacing * 0.4,
  8: $global-spacing * 0.8,
  16: $global-spacing * 1.6,
  24: $global-spacing * 2.4,
  32: $global-spacing * 3.2,
  40: $global-spacing * 4,
  48: $global-spacing * 4.8,
  56: $global-spacing * 5.6,
  64: $global-spacing * 6.4,
  72: $global-spacing * 7.2,
  80: $global-spacing * 8,
  88: $global-spacing * 8.8,
  120: $global-spacing * 12,
  160: $global-spacing * 16,
  200: $global-spacing * 20,
);
// For direct css usage
$gs0: map-get($spacing-sizes-numeral, 0);
$gs4: map-get($spacing-sizes-numeral, 4);
$gs8: map-get($spacing-sizes-numeral, 8);
$gs16: map-get($spacing-sizes-numeral, 16);
$gs24: map-get($spacing-sizes-numeral, 24);
$gs32: map-get($spacing-sizes-numeral, 32);
$gs40: map-get($spacing-sizes-numeral, 40);
$gs48: map-get($spacing-sizes-numeral, 48);
$gs56: map-get($spacing-sizes-numeral, 56);
$gs64: map-get($spacing-sizes-numeral, 64);
$gs72: map-get($spacing-sizes-numeral, 72);
$gs80: map-get($spacing-sizes-numeral, 80);
$gs88: map-get($spacing-sizes-numeral, 88);
$gs120: map-get($spacing-sizes-numeral, 120);
$gs160: map-get($spacing-sizes-numeral, 160);
$gs200: map-get($spacing-sizes-numeral, 200);
// TODO: Deprecate old spacing sizes
// Do not use below this line
$global-spacing-xxs: $global-spacing / 2;
$global-spacing-xs: $global-spacing;
$global-spacing-sm: $global-spacing * 2;
$global-spacing-md: $global-spacing * 3;
$global-spacing-lg: $global-spacing * 4;
$global-spacing-xlg: $global-spacing * 5;
$global-spacing-xxlg: $global-spacing * 6;
$global-spacing-xxxlg: $global-spacing * 8;
$global-spacing-xxxxlg: $global-spacing * 10;

$spacing-sizes: (
  // Spacing modifier
  // .u-margin-top--{ modifier }
  'xlg':
    (
      // Breakpoint triggers
      'xxxlg': $global-spacing * 8,
      'xxlg': $global-spacing * 8,
      'xlg': $global-spacing * 8,
      'lg': $global-spacing * 4,
      'md': $global-spacing * 3,
      'sm': $global-spacing * 3,
      'xs': $global-spacing * 3
    ),
  'lg': (
    'xxxlg': $global-spacing * 6,
    'xxlg': $global-spacing * 6,
    'xlg': $global-spacing * 6,
    'lg': $global-spacing * 4,
    'md': $global-spacing * 2,
    'sm': $global-spacing * 2,
    'xs': $global-spacing * 2,
  ),
  'md': (
    'xxxlg': $global-spacing * 4,
    'xxlg': $global-spacing * 4,
    'xlg': $global-spacing * 4,
    'lg': $global-spacing * 4,
    'md': $global-spacing * 2,
    'sm': $global-spacing * 2,
    'xs': $global-spacing * 2,
  ),
  'sm': $global-spacing * 2,
  'xs': $global-spacing,
  'xxs': $global-spacing / 2
);

$breakpoint-list-up: (
    'xxxlg': $screen-desktop-plus-and-bigger,
    'xxlg': $screen-desktop-and-bigger,
    'xlg': $screen-laptop-and-bigger,
    'lg': $screen-tablet-landscape-and-bigger,
    'md': $screen-tablet-portrait-and-bigger,
    'sm': $screen-mobile-plus-and-bigger,
    'xs': $screen-mobile-and-bigger,
);
