// overrides for swatch version of radio on PDP
.radio--swatch {
  .radio__item {
    display: flex;
    width: 100%;
  }

  .radio__image {
    width: 54px;
    height: 54px;
  }

  .radio__label {
    height: 54px;
    width: 100%;
    padding: 0;
    margin-top: $global-spacing;
    margin-bottom: $global-spacing;
    @include text-small;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
      background: var(--color-background-column);
      z-index: -1;
      transition: opacity 250ms;
      opacity: 0;
    }

    &::after {
      border: 0;
      border-radius: 0;
      width: 2px;
      height: inherit;
      background-color: var(--color-text);
      left: -61px;
      top: 0;
      opacity: 0;
    }

    .radio__label-content {
      width: 100%;
      padding: $global-spacing $global-spacing * 1.5;
    }

    .radio__label-text {
      padding-right: $global-spacing-sm;
    }

    .radio__label-price {
      flex-shrink: 0;
    }

    img {
      height: inherit;
      flex-shrink: 0;
    }
  }

  .radio__input {
    &:checked {
      + label {
        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
