.c-navigation-bar {
  background-color: var(--color-background-html);
  border-top: 1px solid $color-soft-grey;
  z-index: 9;
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  .o-container {
    @include screen-tablet-portrait-and-smaller {
      padding-right: 0;
    }
  }

  .u-background--scroll-gradient-right,
  .u-background--scroll-gradient-left {
    display: none;
  }
}

.navigation-bar__list {
  position: relative;
  margin-bottom: rem(33);

  .u-background--scroll-gradient-container {
    @include screen-tablet-landscape-and-bigger {
      display: initial;
    }
  }

  .u-background--scroll-gradient-container::-webkit-scrollbar {
    display: none;
  }

  .experience-navigationBarLinks {
    display: flex;

    @include screen-tablet-landscape-and-bigger {
      justify-content: space-between;
    }
  }

  .experience-assets-navigationBarLink {
    display: flex;
    align-items: center;
  }
}

.navigation-bar__item {
  @include h3;
  font-weight: $font-weight-semi-medium;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  display: block;
  padding: 0 $global-spacing-md / 2;
  margin: rem(14) rem(36) rem(30);

  &:first-child {
    @include screen-tablet-landscape-and-bigger {
      margin-left: 0;
      padding-left: 0;
    }
  }

  &:last-child {
    @include screen-tablet-landscape-and-bigger {
      margin-right: 0;
      padding-right: 0;
    }
  }

  @include screen-tablet-landscape-and-bigger {
    margin: rem(14) 0 rem(30);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 50%;
    background-color: $color-white;
    border: 1.5px solid $color-black;
    border-radius: 100%;
    height: 14px;
    width: 14px;
    transform: translateX(-7px);
  }
}

.navigation-bar__item--is-active {
  font-weight: $font-weight-bold;

  @include screen-tablet-landscape-and-bigger {
    padding-bottom: $global-spacing / 8;
  }

  &::after {
    background-color: $color-black;
  } 
}

.navigation-bar__timeline {
  position: absolute;
  height: 2px;
  left: $gs16;
  width: 100%;
  top: $gs56;
  z-index: -1;
  border-bottom: 2px solid $color-black;

  @include screen-tablet-landscape-and-bigger {
    left: 0;
  }
}
