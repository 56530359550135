.c-store {
  position: relative;
  padding: $global-spacing * 3 0;

  .store__maps-link {
    text-decoration: none;
  }
}

.store__accordion-item {
  border-bottom: rem(1) solid $color-black;
  border-top: none;

  .accordion__item-icon {
    top: $global-spacing * 2;
    right: 0;
    width: rem(12);
  }

  .c-icon {
    width: 100%;
    height: auto;
  }

  .c-icon.icon--arrow-right-arclinea {
    margin-left: rem(5);
    width: rem(20);
    height: rem(16);
  }

  .accordion__item-content,
  .accordion__item-header {
    font-weight: inherit;
    padding: 0;

    &::after,
    &::before {
      content: none;
    }
  }

  .accordion__item-header {
    user-select: initial !important;
  }
}

.store__accordion-item--hidden {
  display: none;
}

.store__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}

.store__info-name {
  @include h2;

  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}

.store__info-store-type {
  font-size: rem(13);
  line-height: rem(26);
  text-transform: uppercase;
}

.store__distance {
  @include text-small;

  padding-top: $global-spacing-md;
  margin-bottom: 0;
  font-weight: $font-weight-regular;
}

.store__infos {
  @include text;

  font-style: normal;
  color: $color-black;
  padding-top: rem(14);
  display: flex;
  flex-direction: column;
}

.store__hours {
  display: flex;
  flex-wrap: wrap;
  margin-top: $global-spacing;
}

.store__hours-wrap {
  @include text-small;

  flex: 1 1 100%;
  display: flex;
  color: $color-tinted-grey;
}

.store__hours-time {
  padding-left: $global-spacing;
}

.store__infos-line {
  display: inline-block;
  width: fit-content;
  color: $color-black;
  text-decoration: none;
}

a.store__infos-line {
  @include hover-focus {
    text-decoration: underline;
  }
}

.store__links {
  @include text-small;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $global-spacing * 2;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: column;
    align-items: initial;
  }

  @include screen-laptop-and-bigger {
    flex-direction: row;
    align-items: center;
  }
}

.store__links-icon {
  @include screen-tablet-landscape-and-bigger {
    margin-top: $global-spacing * 2;
  }

  @include screen-laptop-and-bigger {
    margin-top: 0;
  }
}

.store__links-button {
  &[href=''] {
    display: none;
  }

  + .store__links-button {
    @include screen-tablet-landscape-and-bigger {
      margin-left: $global-spacing * 3;
    }
  }
}

.store__maps-link {
  display: flex;
  align-items: center;

  img {
    margin-right: $global-spacing;
  }

  @include hover-focus {
    color: $color-dark-grey;
  }
}

.c-button.c-button--underline {
  &.store__show-more,
  &.store__show-less {
    @include button-underline-color-update(transparent, transparent);
    display: none;
    width: fit-content;
    padding: $global-spacing-sm 0 rem(1);
    cursor: pointer;
    text-align: left;
    color: $color-medium-grey;
    border-bottom: rem(1) solid $color-medium-grey;
  
    svg {
      width: rem(10);
      height: rem(10);
      color: $color-medium-grey;
    }

    @include hover-focus {
      color: $color-medium-grey !important;
    }
  }

  &.store__show-more--visible,
  &.store__show-less--visible {
    display: inline-block;
  }

  &.store__show-more--map-is-active,
  &.store__show-less--map-is-active {
    display: none !important;
  }
}

// Store inventory variant
.store--store-inventory {
  border-top: 0;
  margin-bottom: $global-spacing-xs;
  padding: $global-spacing-md;

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-md;
  }

  .store__info {
    width: 100%;
  }

  .store__name-container {
    display: flex;
    justify-content: space-between;
  }

  .store__phone-button {
    flex-shrink: 0;
  }

  .accordion__item-icon {
    display: none;
  }
}
