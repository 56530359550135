.body-background-transition {
  transition-property: background-color;
  transition-duration: 1s;
  transition-timing-function: ease;
}
// Font color update for section background animation
.background-updated {
  a,
  span,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    transition-property: color;
    transition-duration: 1s;
    transition-timing-function: ease;
    color: var(--background-update-color);
  }
  path {
    transition-property: fill, stroke;
    transition-duration: 1s;
    transition-timing-function: ease;
    stroke:  var(--background-update-color);
  }
}
