.c-price {
  display: flex;
  align-items: center;
}

.price__items {
  display: flex;
  align-items: flex-end;
  line-height: 1;
  flex-wrap: wrap;
  @include text-small;
  color: var(--color-text-light);

  .product-line-item__attr--amount & {
    justify-content: flex-end;
    flex-direction: column;
  }
}

.price__item {
  display: block;
  @include text;
  color: var(--color-text);
  text-align: right;
  white-space: nowrap;
  transform: translateY(1px); // fix to align labels + prices to bottom correctly

  &:not(:first-child) {
    margin-left: 5px;
  }

  .product-line-item--modal &,
  .c-product-summary-item &,
  .product-line-item__attr--amount & {
    font-weight: $font-weight-regular;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  .product-line-item--modal & {
    margin-bottom: 0;
  }
}

.price__item--list {
  @include text-small;
  color: var(--color-text-light);
  display: block;
  text-decoration: line-through;
  text-align: right;
  margin-left: $global-spacing;
  margin-bottom: 2px;
}

.price__item-bundle {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .c-card & {
    padding-right: $global-spacing-sm;
  }

  @include keyboard-focus {
    outline: none;

    @include focus-state-pulse(true);
  }
}

.price__item-bundle-icon {
  margin-left: $global-spacing-xs;
  transform-origin: center center;
  transition: transform 300ms ease-in-out;

  .toggle--is-active & {
    transform: rotate(-180deg);
  }
}

.c-price--promotion {
  color: var(--color-error);

  &.c-price--black {
    color: var(--color-text);
  }
}

.price-vat-sign {
  display: none;
}
