.c-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  font-size: $font-size-small;
  font-weight: $font-weight-medium;
  padding: 0.3em;
  min-width: 20px;
  height: 20px;
  text-align: center;
  line-height: 1;
  color: var(--color-text);
  background: var(--color-background);
}

.badge--inverted {
  background: $color-white;
  color: var(--color-text);
}
