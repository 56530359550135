$helper-border-height: 4px;
$helper-transition-ease: $ease-in-out-quad;
$helper-transition-time: 200ms;

.checkbox__focus-helper,
.radio__focus-helper {
  display: block;
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: -1;
}

.checkbox__focus-helper {
  width: $checkbox-size;
  height: $checkbox-size;
  @include focus-state-pulse;
}

.custom-checkbox__focus-helper {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  @include focus-state-pulse;
}

.radio__focus-helper {
  width: $radio-size;
  height: $radio-size;
  @include focus-state-pulse($round: true);
}

.has--keyboard-focus {
  .checkbox__input {
    &:focus {
      + label {
        .checkbox__focus-helper {
          opacity: 1;
        }
      }
    }
  }

  .custom-checkbox__input {
    &:focus {
      + label {
        .custom-checkbox__focus-helper {
          opacity: 1;
        }
      }
    }
  }

  .radio__input {
    &:focus {
      + label {
        .radio__focus-helper {
          opacity: 1;
        }
      }
    }
  }
}
