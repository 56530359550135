//.c-campaign-header {}

.campaign-header__label {
  font-size: 1.2rem;
  font-weight: $font-weight-bold;
  text-transform: uppercase;

  @include screen-mobile-plus-and-smaller {
    margin-bottom: $global-spacing-md;
  }
  @include screen-tablet-portrait-and-bigger {
    padding-top: 12px;
  }
}

.campaign-header__countdown {
  @include screen-tablet-portrait-and-bigger {
    display: flex;
  }
}

.campaign-header__countdown-label {
  @include screen-mobile-plus-and-smaller {
    font-size: 1.4rem;
    margin-bottom: $global-spacing-xxs;
  }

  @include screen-tablet-portrait-and-bigger {
    padding-top: 6px;
    margin-right: $global-spacing-sm;
  }

  .countdown--done & {
    display: none;
  }
}

.campaign-header__countdown-label-done {
  font-size: 1.4rem;
  display: none;

  .countdown--done & {
    display: block;
  }
}

.campaign-header__countdown-timer {
  display: flex;

  .countdown--done & {
    display: none;
  }
}

.campaign-header__countdown-item {
  text-align: center;
  margin-right: $global-spacing-sm;

  @include screen-tablet-portrait-and-bigger {
    margin-right: 0;
    margin-left: $global-spacing-sm;
  }
}

.campaign-header__countdown-item-label {
  font-weight: $font-weight-bold;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.campaign-header__countdown-item-value {
  line-height: 1.8;
  font-weight: $font-weight-bold;

  @include screen-tablet-portrait-and-bigger {
    font-size: 2.4rem;
    line-height: 1.4;
  }
}