.u-text--left {
  @include generate-mediaquery-rules {
    text-align: left;
  }
}

.u-text--centered {
  @include generate-mediaquery-rules {
    text-align: center;
  }
}

.u-text--right {
  @include generate-mediaquery-rules {
    text-align: right;
  }
}

.u-text--justify {
  @include generate-mediaquery-rules {
    text-align: justify;
  }
}

.u-text--no-decoration {
  text-decoration: none;
}

// Weight
.u-text--bold {
  font-weight: $font-weight-bold;
}

.u-text--medium {
  font-weight: $font-weight-medium;
}

.u-text--regular {
  font-weight: $font-weight-regular;
}

.u-text {
  @include text;
}

.u-text--small {
  @include text-small;
}

.u-text--xsmall {
  @include text-xsmall;
}

.u-text--large {
  @include text-large;
}

.u-text--italic {
  font-style: italic;
}

.u-text { /* stylelint-disable-line */
  @include make-colors($color-text-map, 'color');
}

.u-text--transform-none {
  text-transform: none;
}

.u-text--transform-first-letter-uppercase {
  text-transform: lowercase;
  display: block;

  &::first-letter {
    text-transform: uppercase;
  }
}
.u-text--uppercase {
  text-transform: uppercase;
}

.u-text--light {
  color: var(--color-text-light);
}

.u-text--extra-light {
  color: var(--color-text-lightest);
}

.u-text--dark {
  color: var(--color-text-dark);
}
