.c-definition {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-grey;
  padding: $global-spacing-md 0;

  &.c-definition--information {
    align-items: flex-start;
  }

  @include screen-mobile-plus-and-smaller {
    flex-wrap: wrap;
  }
}

.definition__body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  dt:last-child {
    padding-bottom: 0;
  }
}

.definition__term {
  display: inline-block;
  font-weight: $font-weight-regular;
  width: 40%;
  max-width: 170px;
  padding: 0 $global-spacing $global-spacing-md 0;

  &:last-of-type {
    padding-bottom: 0;
  }
}

.definition__description {
  display: inline-block;
  width: 60%;
  padding-bottom: $global-spacing-md;
  text-overflow: ellipsis;
  overflow: hidden;

  &:last-of-type {
    padding-bottom: 0;
  }
}

.definition__link {
  white-space: nowrap;

  @include screen-mobile-plus-and-smaller {
    flex: 1 0 100%;
    margin-top: $global-spacing * 2;
  }
}
