$label-button-spacing: $global-spacing;
$label-button-spacing-tablet-portrait-and-up: $global-spacing;
$label-button-spacing-desktop-and-bigger: $global-spacing;

@mixin button--label {
  background-color: $color-light-grey;
  color: var(--color-text);
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  padding: $label-button-spacing;
  font-size: $font-size-small;
  margin: 0;

  @include screen-tablet-portrait-and-bigger {
    padding: $label-button-spacing-tablet-portrait-and-up;
  }

  @include screen-desktop-and-bigger {
    padding: $label-button-spacing-desktop-and-bigger;
  }

  @include keyboard-focus {
    outline: 1px solid $color-black;
  }

  &[disabled] {
    cursor: default;
    user-select: text;
  }
}
