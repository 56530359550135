.c-address-suggestions {
  position: relative;
}
.address-suggestions__list {
  border-right: 1px solid $color-dark-grey;
  border-left: 1px solid $color-dark-grey;
  border-bottom: 1px solid $color-dark-grey;
  border-radius: 2px;
  margin: $global-spacing * -3 0 $global-spacing * 2 $global-spacing * 0.5;

  @include screen-tablet-portrait-and-bigger {
    top: -30px;
    left: 0;
    position: absolute;
    z-index: 99;
    margin: 0;
    width: 100%;
    background: white;
  }
}

.shipping-address-suggestions__text {
  padding: $global-spacing * 1.5 $global-spacing * 2;
}

@mixin selected-state {
  background-color: $color-black;
  color: $color-white;
}

.shipping-address-suggestions__item {
  padding: $global-spacing * 1.5 0;
  cursor: pointer;

  &.selected {
    @include selected-state;
  }

  @include hover-focus(false) {
    @include selected-state;
  }

  @include keyboard-focus {
    @include selected-state;
  }
}
