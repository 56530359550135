$select-border-width: 1px;
$select-padding-top: $input-padding-top;
$select-padding-bottom: $input-padding-bottom;
$select-padding-left: $input-padding-left;
$select-padding-right: $global-spacing-md;
$select-line-height: $input-line-height;
$select-transition-time: 200ms;
$select-transition-ease: $ease-in-out-quad;

.c-select {
  &:not(.form__item--error):not(.form__item--disabled) {
    @include hover-focus(false) {
      .select__input {
        border-color: var(--color-form-item-hover);

        + .select__arrow svg {
          path,
          polygon {
            fill: var(--color-form-item-hover);
          }
        }
      }
      .select__label {
        color: var(--color-form-item-hover-text);
      }
    }
  }
}

.select__wrapper,
.select__input-wrapper {
  position: relative;
  height: 100%;
  color: var(--color-form-item-placeholder);
}

.select__icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: $select-padding-left;
  transform: translateY(-50%);
  display: none;
  pointer-events: none;

  .select--has-icon & {
    display: block;
  }
}

.select__label {
  font-size: $font-size-input;
  display: block;
  color: var(--color-form-item-placeholder);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  span {
    padding: 3px 0;
  }
}

.select--big-label {
  .select__label {
    transform: translate3d(0, -50px, 0) scale(0.9);
  }
}

@mixin select-input() {
  cursor: pointer;
  appearance: none;
  width: 100%;
  padding-top: $select-padding-top;
  padding-bottom: $select-padding-bottom;
  padding-left: $select-padding-left;
  padding-right: $select-padding-right;
  border: 0;
  border-bottom: $input-border-width solid var(--color-form-item-default);
  color: var(--color-form-item-disabled-text);
  line-height: $input-line-height;
  background-color: transparent;
  transition: border-color $input-transition-time $input-transition-ease;
  font-size: $font-size-input;
  text-overflow: ellipsis;
  border-radius: 0;

  .select--has-icon & {
    padding-left: $select-padding-left * 2.5;
  }

  &[disabled],
  &[readonly] {
    cursor: default;
    color: var(--color-form-item-disabled-text);
    border-color: var(--color-form-item-disabled);
  }

  &:invalid,
  option[disabled] {
    color: var(--color-form-item-disabled-text);
  }

  &::-ms-expand {
    display: none;
  }

  &--selected {
    color: var(--color-form-item-focus);
  }

  .form__item--error & {
    border-color: var(--color-error);
  }

  option {
    color: var(--color-text);
  }
}

.select__input {
  @include select-input;
}

.select__arrow {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.textarea__wrapper {
  position: relative;
}


/**
 * Not required text
 */
.select__not-required-text {
  display: inline-block;
  font-style: italic;
  margin-left: $global-spacing;
  font-weight: $font-weight-regular;
  color: var(--color-form-item-disabled-text);
}

/**
* Custom selectbox
* see _components.custom-select-box.scss
 */
.c-select--custom {
  position: relative;
  min-width: 50px; // not sure why, but without this the overflow ellipsis on label won't work.

  @include hover-focus {
    .select__label {
      border-color: var(--color-form-item-hover);
    }
  }


  .select__input-wrapper {
    position: absolute;
    right: 0;
    top: 0;
  }

  .select__input {
    display: none;
  }

  .select__label {
    @include select-input;
    color: var(--color-text);
    margin-right: $global-spacing-md;
    margin-bottom: $global-spacing-xs;
    transition: color 200ms $ease-out-quad, border-color 200ms $ease-out-quad;

    &--disabled {
      color: var(--color-form-item-disabled-text);
    }
  }

  &.form__item--error {
    .select__label {
      border-color: var(--color-error);
    }
  }

  &.form__item--success {
    .select__label {
      color: var(--color-form-item-focus-text);
    }
  }

  &.form__item--disabled {
    .select__label {
      color: var(--color-form-item-disabled);
    }
  }

  &.form__item--lap-active .select__label {
    position: relative;
    overflow: visible;
    transform: none;
    max-width: 100%;
    color: var(--color-form-item-focus);
    pointer-events: all;

    > span:first-child {
      position: absolute;
      display: block;
      transform-origin: top left;
      transform: translate3d(0, -30px, 0) scale(0.7);
      color: var(--color-form-item-placeholder);
    }
  }
}
