.image-modal__wrapper--small {
  margin-left: -$global-spacing-sm;
  margin-right: -$global-spacing-sm;
}

.c-image-modal {
  position: relative;
}

.image-modal--small {
  &:not(.image-modal--active) {
    @include screen-mobile-plus-and-smaller {
      width: 150px;
    }
    @include screen-tablet-portrait-only {
      width: 180px;
    }
    @include screen-tablet-landscape-and-bigger {
      width: 200px;
    }
  }
}

.image-modal--active {
  margin: 0;
  padding-top: 32px;
  position: fixed;
  top: 0;
  left: 0;
  max-width: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color-black, 0.4);
  @include z(modal);
}

.image-modal__content {
  position: relative;
  z-index: 2;

  .image-modal--small:not(.image-modal--active) & {
    padding: $global-spacing-md;
    border: 1px solid var(--color-divider-line);
    width: 100%;

    @include screen-tablet-portrait-and-bigger {
      max-width: 200px;
    }
  }

  .image-modal--active & {
    background-color: var(--color-image-background);
    padding: $global-spacing-sm;
    width: auto;
    max-width: calc(100vw - #{$global-spacing-xlg});

    @include screen-tablet-portrait-and-bigger {
      padding: $global-spacing-md;
      width: calc(33.33% + #{$global-spacing-xxlg}); // 4 columns + padding
      max-width: none;
    }
  }

  img {
    .image-modal--active & {
      width: 100vw;
      max-height: 80vh;

      @include screen-tablet-portrait-and-bigger {
        max-width: 70vw;
        height: auto;
        max-height: 65vh;
      }

      @include screen-tablet-landscape-and-bigger {
        height: 65vh;
        width: 100%;
      }
    }
  }
}

.image-modal__button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: var(--color-text);
  z-index: 5;
}

.image-modal__fullscreen-button {
  bottom: $global-spacing;
  right: $global-spacing;
  background-color: var(--color-image-background-70);
  border: 1px solid currentColor;
  transition: background-color 300ms $ease-out-quad;

  @include hover-focus {
    background-color: var(--color-background-inverted-10);
  }

  .image-modal--active & {
    display: none;
  }
}

.image-modal__close-button {
  top: $global-spacing;
  right: $global-spacing;
  display: none;

  @include screen-tablet-portrait-and-bigger {
    top: $global-spacing-sm;
    right: $global-spacing-sm;
  }

  .image-modal--active & {
    display: block;
  }
}

.image-modal__background {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  .image-modal--active & {
    display: block;
  }
}
