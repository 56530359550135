.c-product-line-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  border-bottom: 1px solid var(--color-divider-line);
  margin-bottom: $global-spacing-lg;
  padding-bottom: $global-spacing-lg;
  color: var(--color-text);

  &--order {
    flex-wrap: wrap;
    border: none;
    padding-bottom: 0;
    margin-bottom: $global-spacing-xxlg;
  }

  @include screen-tablet-landscape-and-bigger {
    &--order {
      margin-bottom: $global-spacing-xxxlg;
    }
  }

  .order-details__card & {
    margin-bottom: 0;
  }
}

.product-line-item--modal {
  padding-bottom: rem(40);
  margin-bottom: rem(40);

  @include screen-tablet-landscape-and-bigger {
    padding-bottom: rem(22);
  }
}

.c-product-line-item-sample-pack {
  border: none;
}

.c-product-line-item-sample {
  display: flex;
}

.product-line-item__samples {
  display: none;
  border-bottom: 1px solid var(--color-divider-line);

  &.toggle--is-active {
    display: block;
  }
}

.product-line-item__samples--confirm {
  border-bottom: none;
}

.product-line-item--wide {
  align-items: initial;
}

.product-line-item--large {
  align-items: initial;

  @include screen-mobile-plus-and-smaller {
    .product-line-item__image {
      height: auto;
    }
  }
}

.product-line-item__sample-image-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 180px;
  background-color: $color-off-white;
}

.product-line-item__sample-image-max {
  height: 173px;
  width: 100%;

  @include screen-tablet-landscape-and-bigger {
    width: 214px;
    height: 118px;
  }
}

.product-line-item__image-samples {
  width: 100%;
}

.product-line-item__image-samples--confirm {
  max-width: 50%;
  max-height: 100%;
  mix-blend-mode: multiply;
}

.product-line-item__button {
  text-align: left;
}

.product-line-item__image-wrapper {
  position: relative;

  .product-line-item--is-cover &,
  .product-line-item--modal &,
  .product-line-item--compact & {
    flex: 0 0 80px;
    max-width: 80px;
    margin-right: $global-spacing-sm;
  }

  .product-line-item--wide & {
    @include screen-mobile-plus-and-smaller {
      flex: 0 0 80px;
      max-width: 80px;
      margin-right: $global-spacing-sm;
    }

    @include screen-tablet-portrait-and-bigger {
      width: percentage(2/12);
    }
  }

  .product-line-item__image-sample {
    width: 50px;
    height: 50px;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .product-line-item--large:not(.product-line-item--is-cover) & {
    @include screen-mobile-plus-and-smaller {
      flex: 0 0 80px;
      max-width: 80px;
      margin-right: $global-spacing-sm;
    }

    @include screen-tablet-portrait-and-bigger {
      width: percentage(3/12);
    }
  }

  .c-product-line-item--order & {
    width: 84px;

    @include screen-tablet-portrait-and-bigger {
      width: 100px;
    }

    .product-line-item__image {
      max-width: 100%;
    }
  }

  .card__order-items & {
    width: 80px;
    margin-right: $global-spacing-sm;
    background-color: var(--color-background-html);

    &:last-child {
      margin-right: 0;
    }
  }

  .order-details__item & {
    .product-line-item__image {
      height: auto;
    }
  }
}

.product-line-item__image-wrapper--sample {
  flex: 0 0 50px !important;
  max-width: 50px !important;
}

.product-line-item__body {
  width: 100%;
  @include text-small;
  display: flex;
  flex-direction: column;

  @include screen-tablet-portrait-and-bigger {
    flex-direction: row;

    .product-line-item--large &,
    .product-line-item--wide & {
      flex-wrap: wrap;
    }
  }

  .product-line-item--large & {
    @include screen-tablet-portrait-and-bigger {
      width: percentage(9/12);
      margin-left: $global-spacing-md;
    }
  }

  .product-line-item--wide & {
    @include screen-tablet-portrait-and-bigger {
      width: percentage(10/12);
      margin-left: percentage(1/12);
    }
  }

  .c-product-line-item--order & {
    flex-direction: row;
    border-bottom: 1px solid var(--color-divider-line);
  }

  //ugly override for modal within order line item
  .product-line-item--modal & {
    border-bottom: 0;
  }

  .order-details__card & {
    padding-bottom: $global-spacing-md;
    position: relative;
    border-bottom: 1px solid var(--color-divider-line);

    @include screen-tablet-portrait-and-bigger {
      padding-bottom: $global-spacing-sm;
    }
  }

  .order-details__item-row:last-child & {
    &::after {
      content: none;
    }
  }
}

.product-line-item__body--sample {
  flex-direction: column;
}

.product-line-item__content {
  display: flex;
  flex-direction: column;
  width: 100%;

  .product-line-item--large & {
    @include screen-tablet-portrait-and-bigger {
      width: percentage(7/10);
      padding-right: percentage(1/10);
    }
  }

    .product-line-item--wide & {
    @include screen-tablet-portrait-and-bigger {
      width: percentage(7/10);
      padding-right: percentage(1/10);
    }
  }

  .c-product-line-item--order & {
    flex-direction: row;
    flex-wrap: wrap;
    padding: $global-spacing-md 0;

    @include screen-tablet-portrait-and-bigger {
      flex-wrap: nowrap;
    }
  }

  //ugly override for modal within order line item
  .product-line-item--modal & {
    padding-top: 0;
    padding-bottom: 0;

    @include screen-tablet-portrait-and-bigger {
      flex-wrap: wrap;
    }
  }
}

.product-line-item__content--bigger {
  flex: 3;
}

.product-line-item__prices--sample-bigger {
  flex: 1;
}

.product-line-item__name {
  @include text;
  text-transform: none;
  min-width: 100%;
}

.product-line-item__sample-confirmation-name,
.product-line-item__sample-price-value,
.product-line-item__sample-collection-name,
.product-line-item__quantity {
  font-weight: $font-weight-medium !important;
  font-size: $global-spacing-sm;
  line-height: $global-spacing-sm;
}

.product-line-item__attributes {
  color: var(--color-text-light);
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms $ease-in-out-quad;

  .product-line-item--large &,
  .product-line-item--wide &,
  &.toggle--is-active {
    max-height: 200px;
  }


  dl {
    display: table;
  }
}

.product-line-item__attributes-toggle {
  margin-bottom: $global-spacing;
  font-weight: $font-weight-regular;
  color: var(--color-text-light);

  .product-line-item--large &,
  .product-line-item--wide & {
    display: none;
  }

  svg {
    transition: transform 200ms $ease-out-quad;
  }

  &.toggle--is-active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.product-line-item__attributes-row {
  display: table-row;

  &:not(:last-child) {
    dt,
    dd {
      padding-bottom: $global-spacing-xxs;
    }
  }

  dt,
  dd {
    display: table-cell;
  }

  dt {
    padding-right: $global-spacing;
  }

  //dd {}
}

.product-line-item__prices {
  @include text;
  width: 100%;

  @include screen-mobile-plus-and-smaller {
    order: 3;
  }

  .product-line-item--large &,
  .product-line-item--wide & {
    @include screen-tablet-portrait-and-bigger {
      width: percentage(3/10);
    }
  }
}

.product-line-item__price {
  .product-line-item--large &,
  .product-line-item--wide & {
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: space-between;

    @include screen-mobile-plus-and-smaller {
      align-items: flex-end;

      .price__item--sales {
        order: 2;
      }
    }
  }

  .product-line-item__quantity {
    .order-details__card & {
      font-weight: normal;
      padding-top: 4px;
    }
  }

  .price__items {
    flex-direction: column;
    justify-content: flex-end;
  }

  .product-line-item--modal & {
    .price__items {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .product-line-item--compact & {
    .price__item--sales {
      order: 2;
    }
  }
}

.product-line-item__price--sample {
  width: 100%;
}

.product-line-item__sample-quantity-price {
  display: flex;
  border-bottom: 1px solid $color-grey;
}

.product-line-item__quantity--sample-smaller,
.product-line-item__sample-price--sample-smaller {
  flex: 1;
}

.product-line-item__prices--sample-smaller {
  order: 0;
}

.product-line-item__sample-name {
  font-weight: $font-weight-medium;
}

.product-line-item__sample-name--confirm {
  font-weight: $font-weight-medium;
  color: $color-red;
}

.product-line-item__badges {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @include screen-mobile-plus-and-smaller {
    margin-top: $global-spacing;
    margin-bottom: $global-spacing;
    order: 2;
  }

  @include screen-tablet-portrait-and-bigger {
    justify-content: flex-end;
    margin-top: auto;
  }

  .c-tag {
    @include screen-tablet-portrait-and-bigger {
      margin: 0 0 $global-spacing $global-spacing;
    }
  }
}

.product-line-item__footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .product-line-item--compact & {
    align-items: flex-end;
  }

  .c-product-line-item--order & {
    flex-basis: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: $global-spacing-md;

    @include screen-tablet-landscape-and-bigger {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: $global-spacing-xlg;
    }
  }
}

.product-line-item__footer--sample {
  width: 100%;
}

//.product-line-item__amount {}

.product-line-item__actions {
  flex-shrink: 0;

  @include screen-tablet-portrait-and-bigger {
    margin-left: $global-spacing-md;
  }
}

.product-line-item__view {
  min-width: 130px;
  margin-top: $global-spacing-sm;

  @include screen-mobile-and-smaller {
    min-width: 100%;
  }

  @include screen-tablet-portrait-and-bigger {
    margin-top: 0;
  }
}

.product-line-item__view-order {
  width: 100%;

  @include screen-tablet-landscape-and-bigger {
    width: auto;
  }
}

.product-line-item__added {
  color: var(--color-text-light);
  margin-top: $global-spacing-sm;

  svg {
    transform: translateY(-2px);
  }

  @include screen-tablet-portrait-and-bigger {
    margin-top: 0;
  }
}

.product-line-item__header {
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  padding: $global-spacing-md 0;
  border-bottom: 1px solid var(--color-divider-line);


  .c-product-line-item--order & {
    flex-wrap: wrap;
    padding-top: 0;

      @include screen-tablet-portrait-and-bigger {
        flex-wrap: nowrap;
        padding-top: $global-spacing-sm;
      }
  }
}

.product-line-item__orderNumber-label {
  color: var(--color-text-light);
}

.product-line-item__orderNumber-btn {
  font-weight: normal;
  text-decoration: none;
}

.product-line-item__attr {
  display: flex;
  flex-shrink: 0;
  flex-basis: 16.667%;
  align-items: center;
  padding: 0 $global-spacing-md;

  .c-product-line-item--order & {
    align-items: flex-start;

    @include screen-tablet-portrait-and-bigger {
      align-items: center;
    }
  }
}

.product-line-item__attr--images {
  padding: $global-spacing-md 0;
  margin-right: $global-spacing-sm;
  border: none;
  align-items: flex-start;

  .c-product-line-item--order & {
    flex-basis: 84px;

    @include screen-tablet-portrait-and-bigger {
      flex-basis: 100px;
      margin-right: $global-spacing-xlg;
    }
  }
}

.product-line-item__attr--name {
  flex-basis: 100%;
  flex-shrink: 1;
  font-size: 1.6rem;
  font-weight: $font-weight-bold;
  padding-left: 0;
  flex-wrap: wrap;

  @include screen-tablet-portrait-and-bigger {
    padding-right: $global-spacing-xlg;
  }
}

.product-line-item__attr--name-label {
  display: block;
  width: 100%;
}

.product-line-item__attr--quantity,
.product-line-item__attr--total-label {
  flex-basis: 8.3334%;
}

.product-line-item__attr--amount,
.product-line-item__attr--total-amount,
.product-line-item__attr--total-label {
  justify-content: flex-end;
  padding-right: 0;
}

.product-line-item__attr--quantity {
  padding-left: 0;
}

.product-line-item__attr--amount {
  flex-basis: 60%;
  flex-shrink: 1;
  flex-grow: 1;

  @include screen-tablet-portrait-and-bigger {
    flex-grow: inherit;
    flex-shrink: inherit;
    flex-basis: 40%;
  }
}



.product-line-item__attr--cta {
  flex-basis: 100%;
  order: 1;
  border: none;
  padding: $global-spacing-md 0;

  @include screen-tablet-landscape-and-bigger {
    flex-shrink: 1;
    order: initial;
    padding-top: 0;
  }
}

.product-line-item__attr--total-label {
  border: none;
  justify-self: flex-end;
}

.product-line-item__attr--total-amount {
  border: none;
  justify-self: flex-end;
  font-weight: $font-weight-bold;
  flex-basis: auto;

  @include screen-tablet-portrait-and-bigger {
    flex-basis: 27%;
  }

  @include screen-tablet-landscape-and-bigger {
    //flex-basis: 16.667%;
  }
}

.product-line-item__date {
  flex-basis: 100%;
  margin-bottom: $global-spacing-sm;

  @include screen-tablet-portrait-and-bigger {
    flex-basis: auto;
    margin-bottom: 0;
  }
}

//.product-line-item__lightbulb {}

.product-line-item__lightbulb-image-wrapper {
  position: relative;
  width: 48px;
  margin-right: $global-spacing-sm;
  flex-shrink: 0;

  @include screen-mobile-plus-and-smaller {
    display: none;
  }
}

.product-line-item__lightbulb-info {
  @include text-small;
}
