.c-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 127px;
  height: var(--hero-image-height-mobile);
  clip-path: inset(0);
  margin-top: var(--main-spacing-top-negative);

  @include screen-tablet-landscape-and-bigger {
    height: var(--hero-image-height-desktop);
    padding-bottom: $gs56;
  }

  .o-container--bottom {
    position: relative;
    margin-top: auto;
    bottom: $global-spacing-sm;
  }

  .o-grid {
    position: relative;
    z-index: 2;
  }
  
  .t-black & {
    color: var(--color-text-inverted);

    &.s-white {
      color: var(--color-text);
    }
  }
}

.hero--fullheight {
  height: 100vh;

  .hero__text-content {
    bottom: 127px;
    width: 90%;

    @include screen-tablet-landscape-and-bigger {
      bottom: $gs56;
    }
  }

  .hero__media-wrapper, 
  .hero__overlay,
  .hero__icon,
  .hero__text-content {
    position: fixed;
  }

  @include screen-tablet-landscape-and-bigger {
    height: 100vh;
  }
}

.hero--has-link {
  @include hover-focus {
    .hero__link-arrow {
      transform: none;
      opacity: 1;
    }
  }
}

.hero__title {
  @include screen-tablet-landscape-and-bigger {
    width: 50%;
  }
}

.hero__icon {
  position: absolute;
  bottom: 69px;
  left: 50%;
  transform: translateX(-14px);

  @include screen-tablet-landscape-and-bigger {
    bottom: 29px;
  }
}

.hero__overlay {
  position: absolute;
  z-index: 2;
  bottom: 0;
  height: 307px;
  width: 100%;
  background: linear-gradient(180deg, $color-black-transparent-75 0%, transparent 100%);
  transform: rotate(-180deg);
}

.hero__title--wider {
  width: 90%;

  @include screen-tablet-landscape-and-bigger {
    width: 35%;
  }
}

.hero__subtitle {
  @include text-xsmall;
  font-weight: $font-weight-medium;
}

.hero__link-arrow {
  margin-left: $global-spacing;
  opacity: 0;
  transform: translate3d(-$global-spacing, 0, 0);
  transition: transform 250ms $ease-out-quad, opacity 200ms;

  html.is-touch & {
    opacity: 1;
    transform: none;
  }
}

.hero__button {
  font-size: $font-size-set-text;
  font-weight: $font-weight-regular;
  text-decoration: none;
  line-height: 26px;
  color: $color-white;

  .button__label {
    @include multi-line-text--underline($color-white);
  }
}

.hero__button--hover {
  @include hover-focus {
    color: $color-white !important;

    .button__label {
      background-image: linear-gradient($color-white) !important;
    }
  }
}

.hero__video {
  margin-bottom: 0;
  padding-bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .video__player {
    /* Creates the same effect as applying object fit: cover,
       which does not work on an iframe */
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    transform: translate(-50%, -50%);
    background-color: $color-black;

    @media (min-aspect-ratio: 16/9) {
      height: 56.25vw;
    }

    @media (max-aspect-ratio: 16/9) {
      width: 177.78vh;
    }
  }
}

.hero__media-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero__media {
  height: 100%;
  position: relative;
  margin: auto;
  clip-path: inset(0);

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(111.47% 178.39% at 53.23% 67.45%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.44) 99.99%, rgba(0, 0, 0, 0.44) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  }
}

.hero__media--min-width {
  min-width: calc(100% - #{map_get($container-gutters, 'sm') * 2});

  @include screen-min-width($breakpoint-tablet-landscape) {
    min-width: calc(100% - #{map_get($container-gutters, 'md') * 2});
  }

  @include screen-min-width($breakpoint-laptop) {
    min-width: calc(#{$breakpoint-laptop - (map_get($container-gutters, 'md') * 2)});
  }
}

body.has--navigation-bar {
  .hero--fullheight {
    height: calc(100vh - #{$navigation-bar-height});

    .hero__text-content {
      bottom: $navigation-bar-height + 127px;
  
      @include screen-tablet-landscape-and-bigger {
        bottom: $navigation-bar-height + $gs56;
      }
    }

    .hero__icon {
      bottom: $navigation-bar-height + 69px;
    
      @include screen-tablet-landscape-and-bigger {
        bottom: $navigation-bar-height + 29px;
      }
    }
  }
}
