.c-definition-table {
  color: var(--color-definition-table);

  @include screen-tablet-landscape-and-bigger {
    display: flex;
    justify-content: space-between;
  }
}

.definition-table__col {
  @include screen-tablet-landscape-and-bigger {
    width: calc(50% - #{$global-spacing-xxlg});
  }
}

.definition-table__col--separated {
  @include screen-tablet-portrait-and-smaller {
    margin-bottom: $global-spacing-md;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.definition-table__line {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-divider-line);
  padding: $global-spacing-xs 0;

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-sm 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  .definition-table__dt{
    width: 50%;
    justify-content: start;

  }

  .definition-table__dd{
    text-align: end;
  }


  @include screen-tablet-portrait-and-smaller {
    .definition-table__col--separated &:last-child,
    .definition-table__col:last-child &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.definition-table__line--text {
  display: block;
  color: var(--color-definition-table-title);
}

.definition-table__line--title {
  color: var(--color-definition-table-title);
  border-bottom: 0;
  padding: $global-spacing-sm 0;

  @include screen-tablet-landscape-and-bigger {
    padding-bottom: $global-spacing-xxs;
  }

  svg {
    margin-top: -$global-spacing-xxs;
    margin-left: $global-spacing-xxs;
  }
}

.product-card__wrapper--bulbs {
  .definition-table__line {
    border-color: $color-light-grey;
  }
}

.definition-table__image-wrapper {
  width: 45%;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.definition-table__image--certificate {
  margin: 0 0 rem(10) rem(10);
  width: rem(24);
  height: rem(24) !important;

  & + & {
    margin-top: 0;
  }
}

.definition-table__link,
.definition-table__button-link {
  color: var(--color-text);
}

.definition-table__button-link {
  padding: 0;
  font-weight: $font-weight-bold;
}

.definition-table__header {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  border-bottom: 0;
  padding: $global-spacing 0 $global-spacing-md 0;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row;
    padding: $global-spacing-sm 0;
  }
}

.definition-table__header-link {
  margin-bottom: $global-spacing-md;

  @include screen-tablet-landscape-and-bigger {
    margin-left: auto;
    margin-bottom: 0;
  }
}

.definition-table__products {
  width: 100%;
}
