//.c-checkout-steps {}

.checkout-steps__list {
  list-style: none;
  padding: 0;
  display: flex;
}

.checkout-steps__step {
  flex: 1;
  border-bottom: 2px solid $color-grey;
  transition: border-color 300ms $ease-out-quad;

  &:last-child {
    margin-left: -$global-spacing-lg;
  }

  &:not(.is--completed) {
    color: var(--color-text-light);
    cursor: default;
    pointer-events: none;
  }

  &.is--active {
    color: var(--color-text);
    border-color: var(--color-text);
  }

  &.is--completed {
    @include hover-focus {
      border-bottom-color: var(--color-divider-line-dark);
    }
  }
}

.checkout-steps__link {
  padding-top: $global-spacing-sm;
  padding-bottom: $global-spacing-sm;
  display: block;
  text-decoration: none;
  @include text-small;

  .checkout-steps__step:not(:first-child) & {
    padding-left: $global-spacing;

    @include screen-tablet-landscape-and-bigger {
      padding-left: $global-spacing-md;
    }
  }
}

.checkout-steps__label-container {
  display: flex;
}

//.checkout-steps__icon-container {}
