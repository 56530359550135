.c-cart {
  position: relative;
  margin-top: var(--main-spacing-top-negative);
  height: auto;

  > .o-container {
    height: 100%;

    > .o-grid {
      height: 100%;
    }
  }
}

.cart__content {
  .c-alert + .c-alert {
    margin-top: $global-spacing-md;
  }
}

.cart__product {
  position: relative;

  &:last-child {
    .c-cart-product {
      border-bottom: 0;
    }
  }
}
