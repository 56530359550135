// -----------------------------------------------
// HEADER
// -----------------------------------------------

$header-height-top: 27px;
$header-height-bottom: 54px;
$header-height-bottom-desktop: 54px;

$header-height: $header-height-top + $header-height-bottom;

$header-transition-duration: 300ms;
$header-transition-ease: linear;
