.c-explore-link {
  display: flex;
  width: 100%;
  padding-bottom: $gs24;
  text-decoration: none;
  align-items: center;

  @include hover-focus {
    .explore-link__image {
      opacity: 1;
    }

    .explore-link__arrow {
      @include screen-tablet-landscape-and-bigger {
        opacity: 1;
      }
    }

    .explore-link__label {
      font-weight: $font-weight-bold;
    }
  }

  @include screen-laptop-and-bigger {
      width: 90%;
  }
}

.explore-link__label {
  @include screen-tablet-landscape-and-bigger {
      @include h4;

      font-weight: $font-weight-semi-medium;
  }
}

.explore-link__image {
  display: none;
  pointer-events: none;

  @include screen-tablet-landscape-and-bigger {
      display: block;
      position: absolute;
      top: 0;
      right: rem(9);
      width: 49%;
      height: rem(495);
      opacity: 0;
      transition: opacity 250ms ease;
  }
}

.explore-link__arrow {
  opacity: 0;
  margin-left: $gs16;

  @include screen-tablet-landscape-and-bigger {
      opacity: 0;
      transition: opacity 200ms;
  }
}
