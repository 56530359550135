@mixin modal--large {
  .modal__content {
    width: 100%;
    padding: $global-spacing-xlg $global-spacing-lg;

    @include screen-tablet-landscape-and-bigger {
      width: auto;
      padding: $global-spacing * 8 $global-spacing * 14;
      min-width: 680px;
    }
  }
}
