// Default color palette
$color-blue: #19495C;
$color-pink: #ff5764;
$color-turquoise: #86bbc1;
$color-light-pink: #d1b8b2;
$color-light-grey: #EAEAEA;
$color-dark-grey: #6A6A6A;
$color-darker-grey: #666;
$color-blue-sky: #5d7796;
$color-medium-grey: #B1B1B1;
$color-medium-grey-secondary: #A7A6A6;
$color-soft-grey: #D9D9D9;
$color-grey: #C0C0C0;
$color-brown: #9f917f;
$color-green: #849A78;
$color-green-light: #E1FFF4;
$color-yellow: #F9B949;
$color-black: #333;
$color-red: #CC2C29;
$color-red-light: #FFE8E8;
$color-red-20: rgba($color-red, .2);
$color-red-hover: #AE2623;
$color-tinted-grey: #999;
$color-warm-red: #a64f36; // not used on arclinea
$color-black-grey: #3d3d3d; // not used on arclinea
$color-white-grey: #fafafa;
$color-dark-black: #000;
$color-web-grey: #757F7F;

$color-black-transparent-15: rgba(0,0,0,0.15);
$color-black-transparent-25: rgba(0,0,0,0.25);
$color-black-transparent-40: rgba(0,0,0,0.4);
$color-black-transparent-50: rgba(0,0,0,0.5);
$color-black-transparent-60: rgba(0, 0, 0, 0.6);
$color-black-transparent-75: rgba(0,0,0,0.75);

$color-white-transparent-15: rgba(255,255,255,0.15);
$color-white-transparent-30: rgba(255,255,255,0.3);
$color-white-transparent-50: rgba(255,255,255,0.5);
$color-white-transparent-75: rgba(255,255,255,0.75);
$color-white-transparent-20: rgba(255,255,255,0.2);

$color-white: #fff;
$color-off-white: #f9f9f9;
$color-sand: #ECE9E5;
$color-sand-20: #F3F3F3;
$color-red-error: #873826;

// If var color-background-section exists
// PD assets with background turned on will have a background color

// If var $color-background-column-section exists
// PD layouts with background turned on will have a background color the width of the container

// If var $show-body-background-image exists
// background-grain mixin is added to body and other 'background' elements

$show-full-background-section-color: true;

// Colors that need override on dark theme pages
// Defined in theme files
//// Button colors
//// Core color settings
//// Form color settings
//// States

// Social color palette
$color-social-twitter: #00aced;
$color-social-facebook: #3b5998;
$color-social-googleplus: #dd4b39;
$color-social-pinterest: #cb2027;
$color-social-linkedin: #007bb6;
$color-social-youtube: #b00;
$color-social-vimeo: #aad450;
$color-social-instagram: #517fa4;
$color-social-flickr: #ff0084;
$color-social-dribbble: #ea4c89;
$color-social-whatsapp: #4dc247;

// List of social channels with their colors you can use to loop through
$color-socials-map: (
    'twitter': $color-social-twitter,
    'facebook': $color-social-facebook,
    'linkedin': $color-social-linkedin,
    'youtube': $color-social-youtube,
);

// Color maps to loop through
$colors-map: (
    'blue': $color-blue,
    'pink': $color-pink,
    'red': $color-red,
    'red-light': $color-red-light,
    'red-hover': $color-red-hover,
    'green': $color-green,
    'green-light': $color-green-light,
    'yellow': $color-yellow,
    'black': $color-black,
    'dark-grey': $color-dark-grey,
    'medium-grey': $color-medium-grey,
    'soft-grey': $color-soft-grey,
    'grey': $color-grey,
    'light-grey': $color-light-grey,
    'white': $color-white,
);

$color-swatches-map: (
    'beige': #e4d2c5,
    'blue': #283870,
    'blond': #faf0be,
    'bordeaux': #5f021f,
    'bronze': #cd7f32,
    'brown': #964b00,
    'creme': #fffdd0,
    'yellow': #f7ce46,
    'gold': #c9ac48,
    'grey': #808080,
    'green': #367b3d,
    'copper': #8e402a,
    'mauve': #b784a7,
    'miscellaneous': transparent,
    'nude': #efe8dc,
    'orange': #e99d56,
    'purple': #853b92,
    'peach': #ffdab9,
    'red': #a22a22,
    'pink': #ffc0cb,
    'taupe': #b38b6d,
    'transparant': #fff,
    'turquoise': #40e0d0,
    'mint': #0ff,
    'navy': #000080,
    'white': #fff,
    'silver': #c0c0c0,
    'black': #000,
);

$color-text-map: (
    'white': $color-white,
    'black': $color-black,
    'dark-grey': $color-dark-grey,
    'primary': $color-red
);

$color-backgrounds-map: (
    'white': $color-white,
    'light-grey': $color-sand,
    'black': $color-black,
);

