// Main styles.

main {
  flex: 1;
  padding-top: var(--main-spacing-top-mobile);

  @include screen-tablet-landscape-and-bigger {
    padding-top: var(--main-spacing-top);
  }
}
