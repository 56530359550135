// Style
@mixin button--tab {
  background-color: var(--color-button-primary-background);
  color: var(--color-button-primary-font);
  font-weight: $font-weight-bold;
  padding: $button-spacing;
  margin: 0 $global-spacing / 2;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @include hover-focus(false) {
    background-color: var(--color-button-primary-background-hover);
    outline: none;
  }

  &.c-button--active {
    background-color: var(--color-button-primary-background-active);
    color: var(--color-button-primary-font-active);

    @include hover-focus() {
      outline: none;
      box-shadow: none;
      background-color: var(--color-button-primary-background-active-hover);
    }

    @include keyboard-focus {
      outline: 2px solid $color-black;
      box-shadow: inset 0 0 0 2px $color-white;
    }
  }
}
