.edit-profile-form__subscribe {
  @include screen-tablet-portrait-and-smaller {
    order: 1;
  }
}

.edit-profile-form__ctas {
  @include screen-tablet-portrait-and-smaller {
    order: 2;
  }
}
