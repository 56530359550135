$radio-size: 18px;
$radio-border-radius: 50%;
$radio-transition-time: 200ms;
$radio-transition-ease: $ease-in-out-quad;
$radio-position-top: 10px;

@import 'mixin/mixin.radio-checked';
@import 'mixin/mixin.radio-disabled';

.c-radio {
  text-decoration: none;
}

.radio__input {
  @include sr-only;

  &:checked {
    + label {
      @include radio-checked;
    }
  }

  &.disabled,
  &[disabled] {
    + label {
      @include radio-disabled;
    }
  }

  @include hover-focus {
    &:not([disabled]) {
      + label::after {
        border-color: var(--color-form-item-hover);
      }
    }
  }
}

.radio__label {
  color: var(--color-form-item-placeholder);
  display: block;
  position: relative;
  cursor: pointer;
  padding: $global-spacing-xs 0 $global-spacing-xs ($radio-size + $global-spacing-xs);
  margin-bottom: $global-spacing-xs;
  font-size: $font-size-body;

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: $radio-size;
    height: $radio-size;
    border-radius: $radio-border-radius;
    left: 0;
    top: calc(#{$radio-size} / 2);
    transform-origin: center center;
    cursor: pointer;
  }

  @include screen-mobile-plus-and-smaller {
    .radio--flip-mobile & {
      left: auto;
      right: 0;
      padding: $global-spacing-xs ($radio-size + $global-spacing-sm) $global-spacing-xs 0;

      &::before,
      &::after {
        left: auto;
        right: 0;
      }
    }
  }

  &::before {
    background-image: var(--form-checkbox-icon);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px auto;
    background-color: transparent;
    color: var(--color-form-item-default);
    text-align: center;
    z-index: 1;
    transform: scale(0);
    transition: $radio-transition-time $radio-transition-ease;
    transition-property: transform, background-color;
  }

  &::after {
    transition: $radio-transition-time $radio-transition-ease;
    transition-property: border-color;
    border: $input-border-width solid var(--color-form-item-default);

    .form__item--error & {
      border-color: var(--color-error);
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.radio__text {
  color: var(--color-form-item-placeholder);
}

.radio__icon {
  position: absolute;
  right: 0;
  top: 0;
}

.radio__subtext {
  display: block;
  @include text-xsmall;
}

.radio__focus-helper {
  display: block;
  width: $radio-size;
  height: $radio-size;
  position: absolute;
  top: calc(#{$radio-size} / 2);
  left: 0;
  z-index: -1;
}

.radio--horizontal {
  .radio__wrapper {
    margin-bottom: -$global-spacing;

    @include screen-tablet-portrait-and-bigger {
      margin-left: -$global-spacing-md;
      margin-right: -$global-spacing-md;
      display: flex;
      flex-wrap: wrap;
    }

    @include screen-tablet-landscape-and-bigger {
      margin-left: -$global-spacing-lg;
      margin-right: -$global-spacing-lg;
    }
  }

  .radio__label {
    @include screen-tablet-portrait-and-bigger {
      margin-left: $global-spacing-md;
      margin-right: $global-spacing-md;
    }

    @include screen-tablet-landscape-and-bigger {
      margin-left: $global-spacing-lg;
      margin-right: $global-spacing-lg;
    }
  }
}
