@keyframes designholding-loader-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-180deg);
  }
}

@keyframes video-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes focus-pulse-scale {
  0% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(0.85);
  }
  50% {
    opacity: 0.3;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(0.85);
  }
}

@keyframes focus-pulse-grow {
  0% {
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
  50% {
    transform: translate3d(-50%, -50%, 0) scale(1.2);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
}

@keyframes focus-pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
  }
}


//@keyframes fade-in-from-bottom {
//  0% {
//    opacity: 0;
//    transform: translateY($global-spacing-xs);
//  }
//  100% {
//    opacity: 1;
//    transform: translateY(0);
//  }
//}

//@keyframes move-in-from-bottom {
//  0% {
//    transform: translateY(100%);
//  }
//  100% {
//    transform: translateY(0);
//  }
//}

//@keyframes move-in-from-right {
//  0% {
//    transform: translateX(0);
//  }
//  100% {
//    transform: translateX(-100%);
//  }
//}
//
//@keyframes reveal-from-left {
//  0% {
//    transform: translateX(-20px);
//    clip-path: polygon(0 0, 0 0, 0 101%, 0 101%);
//  }
//
//  100% {
//    transform: translateX(0);
//    clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
//  }
//}
