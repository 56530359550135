// sass-lint:disable no-vendor-prefixes

@mixin scrollbars($width, $track-color, $background-color) {
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $background-color;
    border-radius: 0;
  }
}
