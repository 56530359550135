// Default spacing between (page designer) blocks on the page
@mixin default-block-space {
  margin-bottom: var(--default-block-space-mobile);

  .c-slider--page-designer & {
    margin-bottom: 0;
  }

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: var(--default-block-space-desktop);
  }
}

@mixin default-block-space-small {
  margin-bottom: var(--default-block-space-small);
}

@mixin default-pd-asset-space {
  margin-bottom: var(--default-pd-asset-space);
}

@mixin remove-gutters {
  // for fendi this is needed
  // for ref app it won't add any styling
}
