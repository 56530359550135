.embeddedServiceHelpButton {
  //.helpButtonDisabled {}

  .helpButton {
    bottom: 0 !important;
    right: 0 !important;
  }

  .uiButton {
    border-radius: 0 !important;
    box-shadow: none !important;
  }
}

.embeddedServiceSidebar {
  .dockableContainer {
    border-radius: 0 !important;
  }
}

button.closeButton,
button.minimizeButton {
  margin-top: -4px;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover[embeddedService-chatHeader_chatHeader] {
    &::before {
      right: 0 !important;
      top: 4px !important;
    }
  }
}

button.minimizeButton {
  svg {
    margin-top: -10px;
  }
}

//.embeddedServiceHelpButton .helpButton {
//  bottom: 0px;
//  right: 20px;
//}
//
//.embeddedServiceHelpButton .helpButton .uiButton, .embeddedServiceHelpButton .helpButton .uiButton:focus {
//  border-radius: 50px;
//  border: 1px solid #000000;
//  background-color: #000000;
//  bottom: 20px;
//  right: 20px;
//  padding: 11px 5px; /* Let's Chat button centering and button size */
//  box-shadow: none;
//  max-width: 126;
//  font-weight: 400;
//  color: white;
//  font-family: "sans-serif", "HelveticaNeue", Helvetica, Arial;
//  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1);  /* the shadow of the let's chat button */
//
//}
//
//.embeddedServiceHelpButton .uiButton .helpButtonLabel {
//  line-height: unset;
//}
//
//.embeddedServiceHelpButton .helpButton .uiButton:focus {
//  outline: transparent;
//}
//
//.embeddedServiceHelpButton .uiButton .helpButtonLabel .message {
//  overflow: unset;
//  background-color: transparent;
//  border-style: hidden;
//  border-width: 1px;
//  color: #fff;
//  padding: inherit;
//}
//
//span .message {
//  font-size: 14px;
//
//}
//
//span .embeddedServiceIcon {
//  background: url('/resource/1578666491000/Chat_Icon?');
//  data-icon: url('/resource/1578666491000/Chat_Icon?');
//}
//
//.embeddedServiceSidebarButton {
//  background: black;
//  font-family: "sans-serif", "HelveticaNeue", Helvetica, Arial, sans-serif;
//}
//
//.embeddedServiceIcon svg {
//  fill: white;
//}
//
//.embeddedServiceSidebarHeader .headerText {
//  color: white;
//  background-position: center;
//}
//
//.embeddedServiceSidebarForm .backgroundImg, .embeddedServiceSidebarForm .backgroundImgColorMask {
//  background-repeat: no-repeat;
//  background-position: center;
//  background-size: contain;
//  height: 100px;
//  width: 100%;
//}
//
//.embeddedServiceSidebarHeader.noAnimate .extendedHeader, .embeddedServiceSidebarHeader.noAnimate .fade, .embeddedServiceSidebarHeader.noAnimate .extendedHeader .embeddedServiceSidebarExtendedHeader .backgroundPattern {
//  background-repeat: no-repeat;
//}
//
//.embeddedServiceSidebarExtendedHeader {
//  color: white;
//}
//
//.embeddedServiceSidebarButton.uiButton--inverse .label {
//  color: white;
//  font-family: "sans-serif", "HelveticaNeue", Helvetica, Arial, sans-serif;
//}
//
//.embeddedServiceSidebarButton.uiButton--inverse, .embeddedServiceSidebarButton.uiButton--inverse:disabled {
//  background: #000000;
//}
//
//.embeddedServiceSidebarButton .label, .embeddedServiceSidebarButton:disabled .label {
//  color: white;
//}
//
//.embeddedServiceSidebarButton {
//  background: #000000;
//}
//
//.embeddedServiceSidebarButton:not(:disabled):focus, .embeddedServiceSidebarButton:not(:disabled):hover {
//  background: #000000;
//}
//
//.uiButton--default:hover .label, .uiButton--default:focus .label {
//  color: white;
//  font-family: "sans-serif", "HelveticaNeue", Helvetica, Arial, sans-serif;
//}
//
//.embeddedServiceSidebarHeader .shortHeader {
//  background-color: #000000;
//}
//
//.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):focus, .embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):hover {
//  background-color: #000000;
//  text-decoration: none;
//  /* box-shadow: none; */
//}
//
//.embeddedServiceHelpButton .helpButton .uiButton:not(:disabled):focus, .embeddedServiceHelpButton .helpButton .uiButton:not(:disabled):hover {
//  background-color: #000000;
//  text-decoration: none;
//  /* box-shadow: none; */
//}
//
//.embeddedServiceHelpButton .helpButton .uiButton:not(:disabled):focus, .embeddedServiceHelpButton .helpButton .uiButton:not(:disabled):before {
//  background-color: #000000;
//  text-decoration: none;
//  /* box-shadow: none; */
//}
//
//.embeddedServiceLiveAgentStateChatAvatar.isLightningOutContext .agentIconColor0 {
//  display: none;
//}
//
//.embeddedServiceLiveAgentStateChatHeader:not(.alert) .message {
//  display: none;
//}
//
//.embeddedServiceLiveAgentStateChatHeaderOption {
//  color: black;
//}
//
//.embeddedServiceLiveAgentStateChatHeader .content {
//  height: 100px;
//}
//
//.embeddedServiceSidebarHeader .headerButton {
//  color: black;
//}
//
//.embeddedServiceLiveAgentStateChatHeader .content {
//  background-color: #000000;
//}
//
//.embeddedServiceLiveAgentStateChatItem.agent .agentName {
//  margin-left: 0px;
//}
//
//.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
//  color: black;
//}
//
//.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.plaintextContent {
//  margin-left: 0px;
//}
//
//.embeddedServiceSidebarMinimizedDefaultUI.helpButton {
//  border-radius: 50px;
//  border: 1px solid #000000;
//  background-color: #000000;
//  bottom: 20px;
//  right: 20px;
//  padding: 11px 5px; /* Let's Chat button centering and button size */
//  box-shadow: none;
//  width: none;
//  font-weight: 400;
//  color: white;
//  font-family: "sans-serif", "HelveticaNeue", Helvetica, Arial;
//  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1);  /* shadow of the let's chat button */
//}
//
//body button, body .x-btn, body .btn, body .btnDisabled, body .btnCancel, body .menuButton .menuButtonButton {
//  background: unset;
//}
//
//.message {
//  background: unset;
//  background-color: #000000;
//  border-style: unset;
//}
//
//.embeddedServiceSidebarForm .fieldList .uiInputText:last-child input {
//  font-size: .75em;
//}
//
//.embeddedServiceSidebarFormField .has-error .slds-style-inputtext:not(:focus) {
//  font-size: .75em;
//}
//
//.embeddedServiceSidebarFormField .slds-style-inputtext, .embeddedServiceSidebarFormField .slds-style-select {
//  font-size: .75em;
//}
//
//.embeddedServiceLiveAgentStateChatHeaderOption {
//  display: none;
//}
//
//.embeddedServiceLiveAgentStateChat .messageArea {
//  padding-top: 0px !important;
//}
//
//.embeddedServiceLiveAgentStateChatHeader .content {
//  height: 0px;
//}
//
//.embeddedServiceSidebarHeader .headerButton {
//  display: none;
//}
//
//.embeddedServiceLiveAgentStateChatItem.agent .agentName {
//  display: none;
//}
//
//.embeddedServiceSidebarFormField .uiInput .uiLabel-left {
//  font-family: "sans-serif", "HelveticaNeue", Helvetica, Arial, sans-serif; /* font of the fields Name, Surname, etc ... to be filled in */
//
//}
//
//.helpButtonDisabled {
//  display: none !important; /* hides the Agent Offline button that appears in place of Let's Chat */
//}
//
//.embeddedServiceLiveAgentStateWaitingHeader .waitingGreeting {
//  //display: none!important; /* hides the text Hello, name that appears when waiting for the agent */
//}
//
//.embeddedServiceHelpButton .embeddedServiceIcon {
//  display: none !important; /* hides the chat icon next to the text let's chat */
//}
//
//.embeddedServiceSidebarMinimizedDefaultUI .embeddedServiceIcon {
//  display: none !important; /* hides the chat icon next to the text let's chat in the reduced version */
//}
//
//.embeddedServiceSidebarMinimizedDefaultUI .minimizedText .message {
//  font-family: "sans-serif", "HelveticaNeue", Helvetica, Arial, sans-serif;
//  color: white;
//}
//
//.embeddedServiceSidebarMinimizedDefaultUI .minimizedText {
//  overflow: visible !important; /* it is used to prevent the waiting text from being cut in the reduced version */
//}

