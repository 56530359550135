.c-product-tile {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding-bottom: $global-spacing-md;

  &:not(.c-product-tile--fade-animation) {
    @include hover-focus {
      .product-tile__image .image__default {
        transform: scale(1.05);
      }
    }
  }
}

.product-tile__wrapper {
  .product {
    height: 100%;
  }
}

.product-tile__anchor {
  text-decoration: none;
  background-image: none;
  color: $color-black;
  display: flex;
  flex-direction: column;
  height: 100%;
  outline: none;
}

//.product-tile__image-wrapper {}

.product-tile__badges {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  width: calc(100% - 10px);
  top: 10px;
  left: 10px;
  opacity: 1;
  transition: 300ms opacity $ease-in-out-quad;

  .c-product-tile--swatch-is-active & {
    opacity: 0;
  }
}

.product-tile__image {
  overflow: hidden;

  .image__default {
    transition: 400ms transform $ease-out-quad, opacity 200ms ease-out;
  }
}

.product-tile__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: $global-spacing-sm;
  height: 100%;
  position: relative;
}

.product-tile__title {
  color: var(--color-text);
  font-weight: $font-weight-regular;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: $global-spacing-xs;
}

.product-tile__designer {
  color: var(--color-text);
  margin-bottom: $global-spacing-xxs;
}

.product-tile__variations {
  margin-top: auto;
  color: var(--color-text-light);
}

.product-tile__pricing {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.product-tile__hover-indicator {
  display: none;
  position: absolute;
  left: 0;
  bottom: -$global-spacing;
  width: 24px;
  height: 1px;
  background-color: $color-black;
  transition: 300ms transform $ease-in-out-quart;

  @include screen-laptop-and-bigger {
    display: block;
  }
}
