.c-download-link {
  margin-bottom: 0;

  .download-link__url-content {
    transform: translateY(rem(-3));
    padding-left: rem(12);

    @include screen-tablet-landscape-and-bigger {
      padding-left: 0;
    }
  }

  .download-link__text-content {
    p {
      margin-bottom: 0;
    }
  }

  .c-button--underline {
    @include multi-line-text--underline(var(--color-button-ghost-underline));

    color: currentColor;

    @include hover-focus {
      color: currentColor !important;

      .button__label {
        @include multi-line-text--underline(currentColor);
        background-image: linear-gradient(currentColor, currentColor) !important;
      }
    }

    .button__icon {
      transform: translateY(rem(3));
    }

    .button__label {
      margin-right: rem(9);

      @include hover-focus {
        border: none;
      }
    }
  }
}
