.c-payment-methods {
  position: relative;
}

.c-payment-method {
  background-color: $color-white;
  border: 1px solid $color-grey;
  padding-bottom: $global-spacing;
  position: relative;
  transition: border-color 300ms $ease-out-quad;

  &:not(:last-child) {
    margin-bottom: $global-spacing-sm;
  }

  &:not(.is--active) {
    cursor: pointer;

    .payment-method__wrapper {
      cursor: pointer;
    }

    @include hover-focus {
      border-color: $color-dark-grey;
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: $radio-size;
    height: $radio-size;
    border-radius: $radio-border-radius;
    left: $global-spacing-md;
    top: $global-spacing-md;
    transform-origin: center center;
    pointer-events: none;
  }

  &::before {
    background-image: var(--form-checkbox-icon);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px auto;
    background-color: transparent;
    color: var(--color-form-item-default);
    text-align: center;
    z-index: 1;
    transform: scale(0);
    transition: $radio-transition-time $radio-transition-ease;
    transition-property: transform, background-color;
  }

  &::after {
    transition: $radio-transition-time $radio-transition-ease;
    transition-property: border-color;
    border: $input-border-width solid var(--color-form-item-default);

    .form__item--error & {
      border-color: var(--color-error);
    }
  }

  &.is--active {
    @include radio-checked;
  }
}

.payment-method__wrapper {
  padding: 22px $global-spacing-md 0 $global-spacing-xxxlg;
  display: flex;
  width: 100%;
}

.payment-method__body {
  flex: 1;
  padding-right: $global-spacing-md;
}

.payment-method__label {
  display: block;
  font-weight: $font-weight-bold;
  margin-bottom: $global-spacing-sm;
}

.payment-method__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.payment-method__additional-fields {
  padding: $global-spacing-md $global-spacing-md $global-spacing-sm $global-spacing-md;
}

.payment-method__additional-field {
  position: relative;
}

.payment-method__additional-field--tooltip {
  padding-right: $global-spacing-lg;
}

.payment-method__additional-field--icon {
  padding-right: $global-spacing-xlg;
}

.payment-method__tooltip {
  position: absolute;
  right: 0;
  top: 3px;
  text-align: left;
  z-index: 2;

  &.toggle--is-active  {
    .payment-method__tooltip-text {
      visibility: visible;
      opacity: 1;
      transition: opacity 400ms ease;
    }
  }
}

.payment-method__tooltip-icon {
  background-color: var(--color-background-html);
}

.payment-method__tooltip-text {
  position: absolute;
  bottom: 27px;
  right: 0;
  background-color: var(--color-background-html);
  padding: $global-spacing $global-spacing-sm;
  box-shadow: 0 0 4px 0 rgba($color-black, .15);
  width: 150px;
  @include text-small;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease, visibility 0ms ease 300ms;
}

.payment-method__cc-icons {
  border-radius: 3px;
  overflow: hidden;
  width: 27px;
  height: 18px;
  line-height: 0;
  background-color: var(--color-background-html);
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 2;
}

.payment-method__cc-icon {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 300ms;

  &.is--active {
    opacity: 1;
  }
}
