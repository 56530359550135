//.c-checkout {}

.checkout__form {
  fieldset {
    position: relative;
  }

  .icon--lock {
    margin-left: $global-spacing;
    transform: translate3d(0, -4px, 0);
  }
}

.checkout__button-edit-address {
  margin-bottom: $global-spacing-xlg;
  margin-top: -$global-spacing-sm;

  @include button-underline-color-update(var(--color-text-light), var(--color-text));

  @include screen-tablet-landscape-and-bigger {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.checkout__actions {
  .form__item {
    margin: 0;
  }
  
  .checkbox__label {
    padding: $global-spacing-xs 0 $global-spacing-xs $global-spacing-lg;
  }
  
  .icon--lock {
    margin-left: 0;
    transform: initial;
  }
}

.checkout__actions-adyen-button-holder {
  &[disabled] {
    opacity: .5;
    pointer-events: none;
  }
}
