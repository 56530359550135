// sass-lint:disable mixin-name-format, no-warn, quotes, function-name-format, brace-style, trailing-semicolon, indentation
$container-base-class: 'o-container' !default;
$grid-base-class: 'o-grid' !default;
$grid-column-base-class: 'o-col' !default;

// Defines whether to add grid--fill and grid--middle helper class
// .grid--fill and .grid--middle can be set on any grid,
//      - .grid--fill forces the columns to take equal heights.
//      - .grid--middle forces the columns to align vertically.
// Both helpers are automatically reset at every breakpoint as they can only be used on single row grids.
// E.g.:    <div class="grid-12 grid--fill grid-md--fill"> ...
$grid-add-fill: true !default;
$grid-add-middle: true !default;
$grid-fill-modifier: 'fill' !default;
$grid-middle-modifier: 'middle' !default;

//  A mixin that creates the base of the grid. It includes default widths for its columns.
@mixin grid-create-base(
  $max-width,
  $container-class,
  $grid-class,
  $column-class,
  $container-gutter-size,
  $grid-gutter-size
) {
  .#{$container-class} {
    margin-right: auto;
    margin-left: auto;
    max-width: $max-width;
    width: 100%;
    padding-right: map_get($container-gutter-size, 'xs');
    padding-left: map_get($container-gutter-size, 'xs');

    @each $size-modifier, $breakpoint in $grid-breakpoints {
      @media only screen and (min-width: $breakpoint) {
        padding-right: map_get($container-gutter-size, $size-modifier);
        padding-left: map_get($container-gutter-size, $size-modifier);
      }
    }
  }

  .#{$grid-class} {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + #{map_get($grid-gutter-size, 'xs')});
    margin-right: map_get($grid-gutter-size, 'xs') * 0.5 * -1;
    margin-left: map_get($grid-gutter-size, 'xs') * 0.5 * -1;

    @each $size-modifier, $breakpoint in $grid-breakpoints {
      @media only screen and (min-width: $breakpoint) {
        width: calc(100% + #{map_get($grid-gutter-size, $size-modifier)});
        margin-right: map_get($grid-gutter-size, $size-modifier) * 0.5 * -1;
        margin-left: map_get($grid-gutter-size, $size-modifier) * 0.5 * -1;
      }
    }
  }

  .#{$grid-class}.#{$grid-class}--reverse {
    flex-direction: row-reverse;
  }

  .#{$column-class}.#{$column-class}--reverse {
    flex-direction: column-reverse;
  }
}

@mixin grid-column-base($grid-gutter-size) {
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: map_get($grid-gutter-size, 'xs') * 0.5;
  padding-left: map_get($grid-gutter-size, 'xs') * 0.5;

  @each $size-modifier, $breakpoint in $grid-breakpoints {
    @media only screen and (min-width: $breakpoint) {
      padding-right: map_get($grid-gutter-size, $size-modifier) * 0.5;
      padding-left: map_get($grid-gutter-size, $size-modifier) * 0.5;
    }
  }
}

@mixin grid-create-base-columns($columns, $pre-post, $push-pull, $grid-gutter-size) {
  .#{$grid-column-base-class} {
    @include grid-column-base($grid-gutter-size);
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  @for $column from 0 through $columns {
    $column-width: 100% / $columns * $column;

    .#{$grid-column-base-class}-#{$column} {
      @include grid-column-base($grid-gutter-size);
      flex-basis: $column-width;
      max-width: $column-width;
    }

    @if ($push-pull) {
      @include grid-create-push-pull($column, $column-width);
    }

    @if ($pre-post) {
      @include grid-create-pre-post($column, $column-width);
    }
  }
}

@mixin grid-create-responsive-columns(
  $breakpoints,
  $columns,
  $pre-post,
  $push-pull,
  $grid-gutter-size
) {
  @each $size-modifier, $breakpoint in $breakpoints {
    @media only screen and (min-width: $breakpoint) {
      .#{$grid-column-base-class}--#{$size-modifier} {
        @include grid-column-base($grid-gutter-size);
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
      }

      @for $column from 0 through $columns {
        $column-width: 100% / $columns * $column;

        .#{$grid-column-base-class}-#{$column}--#{$size-modifier} {
          @include grid-column-base($grid-gutter-size);
          flex-basis: $column-width;
          max-width: $column-width;
        }

        @if ($push-pull) {
          @include grid-create-push-pull($column, $column-width, $size-modifier);
        }

        @if ($pre-post) {
          @include grid-create-pre-post($column, $column-width, $size-modifier);
        }
      }
    }
  }
}

@mixin grid-create-push-pull($column, $column-width, $size-modifier: false) {
  @include grid-create-offset-utilities(
    'push',
    'right',
    $column,
    $column-width,
    $size-modifier,
    '-'
  );
  @include grid-create-offset-utilities(
    'pull',
    'left',
    $column,
    $column-width,
    $size-modifier,
    '-'
  );
}

@mixin grid-create-pre-post($column, $column-width, $size-modifier: false) {
  @include grid-create-offset-utilities(
    'pre',
    'margin-left',
    $column,
    $column-width,
    $size-modifier
  );
  @include grid-create-offset-utilities(
    'post',
    'margin-right',
    $column,
    $column-width,
    $size-modifier
  );
}

@mixin grid-create-offset-utilities(
  $offset-name,
  $offset-value,
  $column,
  $column-width,
  $size-modifier: false,
  $value-prefix: false
) {
  @if $size-modifier {
    $size-modifier: --#{$size-modifier};
  } @else {
    $size-modifier: '';
  }

  @if $value-prefix == '-' {
    $column-width: $column-width * -1;
  }
  $column: -#{$column};

  .u-#{$offset-name}#{$column}#{$size-modifier} {
    position: relative;

    @if $column == 0 {
      #{$offset-value}: 0;
    } @else {
      #{$offset-value}: $column-width;
    }
  }
}

//  The main mixin that will parse the config and create all the grids.
@mixin grid-create($config) {
  $max-width: map_get($config, 'max-width');
  $container-class: $container-base-class;
  $grid-class: $grid-base-class;
  $column-class: $grid-column-base-class;
  $columns: map_get($config, 'columns');
  $grid-gutter-size: map_get($config, 'grid-gutter');
  $container-gutter-size: map_get($config, 'container-gutter');
  $push-pull: map_get($config, 'add-push-pull');
  $pre-post: map_get($config, 'add-pre-post');

  // Create grid base
  @include grid-create-base(
    $max-width,
    $container-class,
    $grid-class,
    $column-class,
    $container-gutter-size,
    $grid-gutter-size
  );

  // Create grid base columns
  @include grid-create-base-columns($columns, $pre-post, $push-pull, $grid-gutter-size);

  // Create grid responsive columns
  @include grid-create-responsive-columns(
    $grid-breakpoints,
    $columns,
    $pre-post,
    $push-pull,
    $grid-gutter-size
  );
}
