.c-hubspot-form {
  @include default-pd-asset-space;

  > *:last-child {
    margin-bottom: 0;
  }
}

.hs-form {
  .inputs-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.hs-form-field {
  margin-bottom: $global-spacing-md;

  @include hover-focus(false) {
    label:not(.hs-error-msg) {
      color: var(--color-form-item-hover-text);
    }

    .hs-input {
      border-color: var(--color-form-item-hover);
    }
  }
}

.hs-input[type='text'],
.hs-input[type='email'],
.hs-input[type='password'] {
  @include placeholder(var(--color-form-item-placeholder));

  width: 100%;
  background-color: transparent;
  font-family: $font-stack-body;
  font-size: $font-size-input;
  color: var(--color-form-item-focus-text);
  border-radius: 0;
  border: 0;
  outline: none;
  appearance: none;
  padding: $global-spacing 0;
  border-bottom: 1px solid var(--color-form-item-default);
  transition: border-color $input-transition-time $input-transition-ease;

  &[disabled],
  &[readonly] {
    color: var(--color-form-item-disabled-text);
    border-color: var(--color-form-item-disabled);
    cursor: default;
  }

  &::-ms-expand {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &:-webkit-auto-fill,
  &:-internal-autofill-selected {
    font-size: $font-size-input;
    font-family: $font-stack-body;
  }
}

.hs-error-msgs {
  z-index: 0;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-top: $global-spacing;
    display: block;
    @include text-xsmall;
    color: var(--color-error-text);

  }
}

.hs_error_rollup {
  .hs-error-msgs li {
    padding: $global-spacing $global-spacing * 1.5;
    background-color: var(--color-error-20);
    margin-top: $global-spacing-md;
  }
}

.hs-form-booleancheckbox {
  .hs-input {
    @include sr-only;

    &:checked {
      + span {
        @include checkbox-checked;
      }
    }
  }

  .hs-input + span {
    display: block;
    position: relative;
    color: var(--color-form-item-placeholder);
    cursor: pointer;
    padding: $global-spacing-xs 0 $global-spacing-xs 28px;
    margin-bottom: $global-spacing-xs;
    font-size: $font-size-small;


    &::before,
    &::after {
      position: absolute;
      content: '';
      width: $checkbox-size;
      height: $checkbox-size;
      border-radius: $checkbox-border-radius;
      left: 0;
      top: $checkbox-position-top;
      transform-origin: center center;
      cursor: pointer;
    }

    &::before {
      background-image: var(--form-checkbox-icon);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 10px auto;
      background-color: transparent;
      color: var(--color-form-item-default);
      text-align: center;
      z-index: 1;
      transform: scale(0);
      transition: $checkbox-transition-time $checkbox-transition-ease;
      transition-property: transform, background-color;
    }

    &::after {
      transition: $checkbox-transition-time $checkbox-transition-ease;
      transition-property: border-color;
      border: $input-border-width solid var(--color-form-item-default);
    }
  }

  @include hover-focus(false) {
    .hs-input + span::after {
      border-color: var(--color-form-item-hover);
    }
  }
}

.hs-submit {
  margin-top: $global-spacing-sm;
}

.hs-button {
  @include button;
  cursor: pointer;

  &.primary {
    @include button--primary;
    @include button--small;
  }
}

.hbspt-form .submitted-message {
  color: var(--color-text);
  position: relative;
  z-index: 2;
  width: 100%;
  padding: $global-spacing-sm $global-spacing-md;
  display: flex;
  align-items: center;
  background-color: var(--color-success);
  @include text-small;
  font-weight: $font-weight-medium;
}
