// --------------------------
// TOOLTIP
// --------------------------
$tooltip-background: #7691B0;
$tooltip-color: $color-black;
$tooltip-padding: 15px;

// --------------------------
// TRIGGER
// --------------------------
.c-tooltip-trigger {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $color-grey;
  border-radius: 100%;
  padding: 12px;
  cursor: pointer;

  @include keyboard-focus {
    .tooltip-trigger__icon {
      position: relative;
      @include focus-state-pulse($round: true);
    }
  }
}

.tooltip-trigger__icon {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

// --------------------------
// TOOLTIP THEME
// --------------------------
.tippy-tooltip {
  &.tippy-tooltip--dept-theme {
    background-color: $tooltip-background;
    color: $tooltip-color;
    border-radius: 0;
    padding: 0;
    @include z(tipsy);

    &[datc-animatefill] {
      background-color: transparent;
    }

    // &[x-placement^='top'] .tippy-arrow {
    //   border-top-color: $tooltip-background;
    // }
    // &[x-placement^='bottom'] .tippy-arrow {
    //   border-bottom-color: $tooltip-background;
    // }
    // &[x-placement^='left'] .tippy-arrow {
    //   border-left-color: $tooltip-background;
    // }
    // &[x-placement^='right'] .tippy-arrow {
    //   border-right-color: $tooltip-background;
    // }
    // .tippy-backdrop {
    //   background-color: $tooltip-background;
    // }
    .tippy-roundarrow {
      fill: $tooltip-background;
    }
  }
}

.tippy-arrow {
  display: none;
}
// --------------------------
// TOOLTIP POPUP
// --------------------------
.tooltip__inner {
  color: $tooltip-color;
  background: $tooltip-background;
  padding: $tooltip-padding;
}

.tooltip__title {
  text-transform: uppercase;
  text-align: left;
  margin-bottom: $global-spacing;
  display: block;
}

.tooltip__content {
  @include p;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  font-size: 14px;
  font-weight: $font-weight-regular;
}
