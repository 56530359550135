.u-font--primary {
  @include generate-mediaquery-rules {
    font-family: $font-stack-primary;
  }
}

.u-font--secondary {
  @include generate-mediaquery-rules {
    font-family: $font-stack-secondary;
  }
}
