$large-logo-ratio: 38%;
$large-logo-spacing: $global-spacing-xlg;
$large-logo-spacing-mobile: $global-spacing-sm;

.c-large-logo {
  position: sticky;
  top: 0;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;

  .o-col-24 {
    height: 0;
    padding-bottom: calc(#{$large-logo-ratio} + #{$large-logo-spacing * 2});
  }

  @include screen-tablet-portrait-and-smaller {
    .o-col-24 {
      padding-bottom: calc(#{$large-logo-ratio} + #{$large-logo-spacing-mobile * 2});
    }
  }
}

.large-logo__container {
  overflow: hidden;
  //  absolute height is set in JS
}

.large-logo__icon {
  display: block;
  transform: translate3d(0, 0, 0);
  transition: 100ms ease-in-out;
  transition-property: opacity;
  height: 0;
  padding-bottom: $large-logo-ratio;

  svg {
    margin-top: $large-logo-spacing;
    margin-bottom: $large-logo-spacing;
  }

  @include screen-tablet-portrait-and-smaller {
    svg {
      margin-top: $large-logo-spacing-mobile;
      margin-bottom: $large-logo-spacing-mobile;
    }
  }

}
