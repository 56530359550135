.paged-navigation__navigation-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: $font-weight-regular;
  text-decoration: none;
  margin-bottom: $gs24;
  text-transform: uppercase;

  @include screen-tablet-landscape-and-bigger {
    padding: 28px 0;
  }

  @include hover-focus {
    text-shadow: 0 1px currentColor;
  }

  .c-tag {
    margin-bottom: 0;
  }
}
