//
//    Allows setting the width of components by an Nth amount of grid columns without using actual grid elements.
//
//    @param columns      { number }             - amount of columns to be offset
//
//
//    example usage:
//    --------------
//
//    .foo {
//      @include width-override(left, 2)
//    }
//

$grid-max-width: map_get($grid-config, 'max-width');
$grid-column-count: map_get($grid-config, 'columns');

$grid-container-padding-override-xs: #{strip-unit(
    map-deep-get($grid-config, 'container-gutter', 'xs') * 2
  )}px;
$grid-container-padding-override-md: #{strip-unit(
    map-deep-get($grid-config, 'container-gutter', 'md') * 2
  )}px;

@mixin width-override($columns: '1', $property: 'width') {
  #{$property}: calc((100vw - #{$grid-container-padding-override-xs}) / #{$grid-column-count / $columns});

  @include screen-tablet-landscape-and-bigger {
    #{$property}: calc(
      (100vw - #{$grid-container-padding-override-md}) / #{$grid-column-count / $columns}
    );
  }
}
