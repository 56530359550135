$divider-width: 40px;

.c-navigation-links {
  text-align: center;

  .c-button--title {
    @include button--underline;
    @include h3;
    text-decoration: none;
    transition: 300ms ease opacity;
    @include hover-focus {
      opacity: 0.6;
    }
  }
}

.navigation-links__button {
  display: inline-block;
  white-space: nowrap;
  padding: $global-spacing 0;

  &::after {
    @include h3;
    content: '/';
    display: inline-block;
    width: $divider-width;
    text-align: center;
  }

  &:last-child {
    &::after {
      color: transparent
    }
  }

  &.is--last-in-line {
    &::after {
      color: transparent
    }
  }

  .experience-component & {
    &:last-child {
      &::after {
        color: transparent
      }
    }
  }

  @include screen-laptop-and-bigger {
    padding: $global-spacing-sm 0;
  }
}

.c-navigation-links--title {
  padding-left: $divider-width;
}
