// Font stacks

$font-stack-primary: 'Plain';
$font-stack-secondary: 'Calson 540 LT';

// Font settings

$font-stack-body: $font-stack-primary;
$font-stack-buttons: $font-stack-primary;

// Font sizes

$font-size-root: 10px; // The font size set on the root html element.
$font-size-small: 1.3rem;
$font-size-medium: 1.4rem; // Medium font size
$font-size-body: 1.6rem; // Regular font size
$font-size-input: $font-size-body; // Input font size
$font-size-input-label: $font-size-small; // Input font size

// Font size sets
$font-size-set-h1: (
  'xs': 4rem,
  'xxlg': 4rem
);

$font-size-set-h2: (
  'xs': 2rem,
  'xxlg': 2rem
);

$font-size-set-h3: (
  'xs': 1.6rem,
  'xxlg': 1.6rem
);

$font-size-set-h3-secondary: (
  'xs': 1.6rem,
  'xxlg': 1.6rem
);

$font-size-set-h4: (
  'xs': 1.6rem,
  'xxlg': 1.6rem
);

$font-size-set-h4-secondary: 1.6rem;

$font-size-set-h5: (
  'xs': 1.3rem,
  'xxlg': 1.3rem
);

$font-size-set-h5-secondary: 1.3rem;

$font-size-set-h6: 1.3rem;

$font-size-set-h6-secondary: 1.3rem;

$font-size-set-h7: 1rem;

$font-size-set-text: 1.6rem;

$font-size-set-text-small: 1.4rem;

$font-size-set-text-xsmall: 1.2rem;

$font-size-set-text-xxsmall: 1rem;

$font-size-set-text-large: 1.9rem;

$fs9: 0.9rem;
$fs24: 2.4rem;
$fs20: 2rem;
$fs35: 3.5rem;
$fs36: 3.6rem;
$fs48: 4.8rem;
$fs50: 5rem;
$fs80: 8rem;

// Font line height sets
// Divide the the given line height by the font size to give an unitless line height
// @example {design line height} / {font size} = {unitless line height}
//
// NOTE: Never use px as line-height value!!!

$line-height-body: 21 / 16;

$line-height-set-h1: (
  'xs': 40 / 40,
  'md': 40 / 40
);

$line-height-set-h2: (
  'xs': 24 / 20,
  'md': 24 / 20
);

$line-height-set-h3: 20 / 16;

$line-height-set-h3-secondary: (
    'xs': 20 / 16,
    'md': 20 / 16
);

$line-height-set-h4: (
    'xs': 20 / 16,
    'md': 20 / 16
);

$line-height-set-h4-secondary: 20 / 16;

$line-height-set-h5: 18 / 13;

$line-height-set-h5-secondary: 18 / 13;

$line-height-h6: 18 / 13;

$line-height-set-h6-secondary: 18 / 13;

$line-height-set-h7: 12 / 10;

$line-height-set-text: 20 / 16;

$line-height-set-text-small: 20 / 14;

$line-height-set-text-xsmall: 12 / 12;

$line-height-set-text-xxsmall: 12 / 10;

$line-height-set-text-large: 25 / 19;

// Font weights

$font-weight-regular: 300;

$font-weight-semi-medium: 400;

$font-weight-medium: 600;

$font-weight-bold: 600;


