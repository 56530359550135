.search__toggle-button {
  margin-top: $gs8;
  padding: 0 !important;

  .button__icon {
    transition: $button-transition-time $button-transition-ease;
    transition-property: color;
  }

  .button__label {
    @include screen-mobile-plus-and-smaller {
      @include sr-only;
    }
  }

}
