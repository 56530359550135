// -----------------------------------------------
// Z-INDEX
// Z-indices defined globally
// @TODO: Implement globally (commented = uninmplemented :shrug:)
// -----------------------------------------------
$zindex: (
  cookies: 1050,
  loader: 1010,
  progress: 1004,
  browserNotice: 1003,
  search: 1002,
  modalPriority: 1001,
  modal: 1000,
  mobileNavigation: 999,
  headerTop: 990,
  headerSearchForm: 980,
  mobileNavigationToggleButton: 970,
  header: 960,
  headerOpen: 962,
  refinements: 950,
  refinementsOpen: 961,
  fixedBottomBar: 600,
  tipsy: 555,
  promote: 100,
);

@mixin z($label) {
  z-index: map-get($zindex, $label);
}
