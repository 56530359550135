.promo-code-form__content {
  display: flex;
  align-items: flex-end;
  flex-direction: row;

  .form__item-error {
    position: absolute;
  }
}

.promo-code-form__field {
  width: 100%;
  margin: 0;

  .input__input {
    padding-right: $global-spacing;
  }

  &.form__item--error:not(.c-select) .form__item-feedback-icon--error {
    display: none;
  }
}

.promo-code-form__button {
  margin: 0;
  // sass-lint:disable no-important
  width: auto !important; //is otherwisw overridden by form.scss
  font-weight: bold;
}

.promo-code__discount {
  .c-loader {
    background-color: rgba(var(--color-success), .8);
  }
}

.promo-code-form__button-remove {
  transform: translate3d(10px, 0, 0);
}
