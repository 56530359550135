// sass-lint:disable no-important

//Adyen html
#paymentMethodsList,
#paymentMethodsList2 /*needed for custom payment options*/
{
  list-style: none;
  margin: 0;
  transition: opacity 200ms;

  &.is--disabled {
    pointer-events: none;
    opacity: .3;
  }
}

#paymentMethodsList2 {
  margin-top: $global-spacing-sm;
}

#paymentMethodsList.country-be > li:first-child { display: none; }

.c-adyen-payment-method,
.c-payment-method {
  background-color: $color-white;
  border: 1px solid $color-grey;
  padding-bottom: $global-spacing;
  position: relative;
  transition: border-color 300ms $ease-out-quad;

  &:not(:last-child) {
    margin-bottom: $global-spacing-sm;
  }

  &:not(.is--active) {
    cursor: pointer;

    .adyen-payment-method__wrapper,
    .payment-method__wrapper {
      cursor: pointer;
    }

    @include hover-focus {
      border-color: $color-dark-grey;
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: $radio-size;
    height: $radio-size;
    border-radius: $radio-border-radius;
    left: $global-spacing-md;
    top: $global-spacing-md;
    transform-origin: center center;
    pointer-events: none;
  }

  &::before {
    background-image: var(--form-checkbox-icon);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px auto;
    background-color: transparent;
    color: var(--color-form-item-default);
    text-align: center;
    z-index: 1;
    transform: scale(0);
    transition: $radio-transition-time $radio-transition-ease;
    transition-property: transform, background-color;
  }

  &::after {
    transition: $radio-transition-time $radio-transition-ease;
    transition-property: border-color;
    border: $input-border-width solid var(--color-form-item-default);

    .form__item--error & {
      border-color: var(--color-error);
    }
  }

  &.is--active {
    @include radio-checked;
  }

  .adyen-payment-method__input,
  .payment-method__input {
    display: none;
  }

  label:not(.adyen-checkout__label) {
    display: flex;
    width: 100%;
  }

  .adyen-payment-method__wrapper,
  .payment-method__wrapper {
    padding: $global-spacing-md $global-spacing-md 0 $global-spacing-xxxlg;
  }

  .adyen-payment-method__body,
  .payment-method__body {
    flex: 1;
    padding-right: $global-spacing-md;
  }

  .adyen-payment-method__label {
    display: block;
    font-weight: $font-weight-bold;
    margin-bottom: $global-spacing-sm;
  }

  .adyen-payment-method__additional-fields#component_ideal {
    margin: 0 $global-spacing-md;
  }

  .adyen-payment-method__description,
  .payment-method__description {
    @include text-small;
    margin-left: -$global-spacing-xlg;
    margin-bottom: $global-spacing-sm;
    color: var(--color-text-light);
    width: calc(100% + 110px);
  }

  .adyen-payment-method__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    img {
      max-width: 50px;
      max-height: 50px;
      transform: translate3d(8px, -6px, 0);
      image-rendering: auto;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  //.adyen-payment-method__additional-fields {}

  .adyen-checkout__card-input {
    padding: $global-spacing-md $global-spacing-md $global-spacing-sm $global-spacing-md;
  }

  .adyen-checkout__field {
    margin-bottom: $global-spacing-md;
  }

  //.adyen-checkout__label {}

  .adyen-checkout__label__text--error,
  .adyen-checkout__label__text {
    @include text-xsmall;
    color: var(--color-form-item-placeholder);
  }

  .adyen-checkout__input {
    width: 100%;
    background-color: transparent;
    font-family: $font-stack-body;
    font-size: $font-size-input;
    font-weight: $font-weight-medium;
    color: var(--color-form-item-focus-text);
    border-radius: 0;
    border: 0;
    outline: none;
    appearance: none;
    padding: $global-spacing 0;
    border-bottom: 1px solid var(--color-form-item-default);
    transition: border-color $input-transition-time $input-transition-ease;

    // some fields have styling within the iframe
    // so matching the other to that color for consistancy
    @include placeholder(rgb(112, 112, 112));

    &[disabled],
    &[readonly] {
      color: var(--color-form-item-disabled-text);
      border-color: var(--color-form-item-disabled);
      cursor: default;
    }

    &::-ms-expand {
      display: none;
    }

    &::-ms-clear {
      display: none;
    }

    &.adyen-checkout__input--focus,
    &.adyen-checkout__input--focus:hover,
    &:focus,
    &:focus:hover,
    &:active,
    &:active:hover {
      outline: none;
      box-shadow: none;
      border: none;
      border-bottom: 1px solid var(--color-form-item-default);
    }

    &:-webkit-auto-fill,
    &:-internal-autofill-selected {
      font-size: $font-size-input;
      font-family: $font-stack-body;
    }
  }

  .adyen-checkout__input--error,
  .adyen-checkout__input--error:hover,
  .adyen-checkout__input--invalid,
  .adyen-checkout__input--invalid:hover {
    border-color: var(--color-form-item-default);
    border-bottom-color: var(--color-error);
  }

  .adyen-checkout__input--valid {
    border-bottom-color: var(--color-form-item-default);
  }

  .adyen-checkout__icon {
    display: none;
  }

  .adyen-checkout__error-text {
    color: var(--color-error-text);
  }

  .adyen-checkout__label--focused {
    .adyen-checkout__label__text {
      color: var(--color-text);
    }
  }

  .adyen-checkout__dropdown__button {
    padding: $global-spacing $global-spacing-md $global-spacing 0;
    border: 0;
    border-bottom: 1px solid var(--color-form-item-default);
    font-size: $font-size-body;
    text-overflow: ellipsis;
    border-radius: 0;
    margin-right: $global-spacing-md;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' class='c-icon icon--chevron-down' viewBox='0 0 16 8' width='16' height='8'%3E%3Cpolygon fill='currentColor' points='15.3,0 8,6.7 0.7,0 0,0.6 8,8 16,0.6 '%3E%3C/polygon%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 18px;
    outline: 0 !important;
    box-shadow: none !important;
    border-color: var(--color-form-item-default) !important;

    &::after {
      display: none;
    }
  }
  .adyen-checkout__dropdown__list {
    border-radius: 0;
    box-shadow: 0 7px 7px rgba($color-black, .07);
  }

  .adyen-checkout__dropdown__element {
    padding: $global-spacing-xs $global-spacing-xs $global-spacing-xs $global-spacing-sm;
    font-size: $font-size-body;
  }
}

//overrides for bank transfer looking like a checkbox
.c-payment-method {
  cursor: pointer;

  &::before,
  &::after {
    border-radius: 0;
  }
}

.adyen-checkout__card__form {
  .adyen-checkout__card__brands {
    display: none !important;
  }
}
