.c-icon {
  &.icon--loggedin {
    .icon__circle {
      fill: var(--color-text);
    }

    .icon__checkmark {
      stroke: var(--color-text-inverted);
    }
  }
}
