.c-contact-form__company {
    display: none;
}

.c-contact-form {
    &[dwfrm_contact_userProfile="professional"] {
        .c-contact-form__company {
            display: block;
        }
    }

    .form__item {
        margin-bottom: rem(60);
    }

    .form__item-error {
        font-size: rem(10);
    }

    .radio__input:checked + label::before {
        transform: translate(rem(4), rem(4));
    }

    .radio--horizontal {
        margin-bottom: $gs40;

        @include screen-tablet-landscape-and-bigger {
            margin-bottom: rem(66);
        }
    }

    .contact-form__gender {
        .radio__label {
            margin: 0 rem(14) 0 0 !important;
        }
    }

    .contact-form__account-type {
        .radio__label {
            margin: 0 rem(30) 0 0 !important;
        }

        .radio__subtext {
            margin-top: $gs4;
        }

        .radio--horizontal {
            margin-bottom: $gs40;
    
            @include screen-tablet-landscape-and-bigger {
                margin-bottom: rem(20);
            }
        }
    }

    .radio__label {
        color: $color-dark-black;
        display: inline-block;

        &::before {
            background-image: none;
            width: rem(10);
            height: rem(10);
            background-color: $color-web-grey;
        }

        &::after {
            border: rem(1) solid $color-web-grey;
        }
    }

    .input__label,
    .input__input::placeholder {
        color: $color-dark-grey;
    }

    .radio__wrapper {
        margin: 0 !important;
    }

    .form__item--error {
        .input__input,
        .select__input {
            border-bottom: rem(1) solid $color-red-error;
        }
    }

    .input__input,
    .select__input {
        padding-left: $gs8;
        border-bottom: rem(1) solid $color-black;
    }

    .form__item.input__textarea {
        margin-bottom: rem(62);

        @include screen-tablet-landscape-and-bigger {
            margin-bottom: $gs48;
        }
    }

    .form__item.input--textarea {
        margin-bottom: rem(36);

        @include screen-tablet-landscape-and-bigger {
            margin-bottom: rem(33);
        }

        .icon--error {
            bottom: rem(11);
            right: rem(6);
        }
    }

    .input__textarea {
        border: rem(1) solid $color-dark-grey;

        &::placeholder {
            color: $color-dark-grey;
        }
    }

    .contact-form__gender-label {
        opacity: 0.4;
    }

    .csb__list {
        max-height: rem(400);
        overflow-y: auto;
    }

    .contact-form__newsletter-consents,
    .contact-form__contact-consents {
        .form__item {
            margin-bottom: 0;
        }

        .checkbox__label {
            font-size: rem(10);
            line-height: rem(12);
            color: $color-medium-grey-secondary;
            font-weight: $font-weight-semi-medium;
        }

        .icon--error {
            display: none;
        }
    }

    .contact-form__newsletter-consents {
        margin-bottom: rem(94);

        @include screen-tablet-landscape-and-bigger {
            margin-bottom: rem(116);
        }
    }

    .contact-form__contact-consents {
        margin-bottom: rem(38);
    }

    .contact-form__newsletter-user-profile {
        .form__item--user-profile {
            margin-bottom: rem(57);
        }
    }

    .form__item--user-profile {
        margin-bottom: rem(87);

        .select__label {
            color: $color-black;

            &::placeholder {
                color: $color-black;
            }
        }
    }

    .form__item--user-profile,
    .form__item--theme,
    .form__item--country {
        .select__label {
            padding-left: rem(8);
            margin-bottom: 0;
        }
    }

    .form__item--country {
        .select__label {
            color: $color-dark-grey;

            &::placeholder {
                color: $color-dark-grey;
            }
        }
    }

    .contact-form__submit {
        @include screen-tablet-landscape-and-bigger {
            min-width: rem(323);
        }
    }
}
