$button-small-spacing: 13px 16px 10px 16px;

@mixin button--small {
  font-size: 1.4rem;
  padding: $button-small-spacing;

  &.button--icon.button--icon-only {
    padding: 5px;
  }
}
