.cart-summary__wrapper {
  position: relative;
  margin-top: var(--main-spacing-top-negative);
  height: calc(100% + 32px);

  > .o-container {
    min-height: 100%;

    > .o-grid {
      min-height: 100%;
    }
  }
}

.c-cart-summary {
  position: relative;
  height: 100%;
  z-index: 0;
  background-color: var(--color-background-column);

  @include screen-tablet-portrait-and-smaller {
    padding-left: $global-spacing-md;
    padding-right: $global-spacing-md;
  }

  @include screen-tablet-landscape-and-bigger {
    padding-left: $global-spacing-xlg;
    padding-right: $global-spacing-xlg;
  }
}

.cart-summary__create-account-trigger {
  padding: $global-spacing-sm;
  border: 1px solid var(--color-divider-line);
  @include text-small(false);
}

.cart-summary__create-account-trigger-button {
  color: var(--color-text-light)
}
