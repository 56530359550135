$drawer-transition-time: 400ms;
$drawer-transition-easing: $ease-in-out-sine;

@mixin sub-category-link {
  @include button;
  width: 100%;
  text-align: left;
  border-top: $accordion-border;
  font-weight: $font-weight-medium;
  padding: $accordion-item-header-padding !important;
  color: var(--color-text);
}

@mixin accordion-sub-category-link {
  padding: $button-spacing;
  background-color: $color-grey;
  border-top: none;

  &:not(:last-of-type) {
    margin-bottom: $global-spacing / 4;
  }
}

.c-mobile-navigation {
  @include z(mobileNavigation);
  display: none;
  position: fixed;
  top: $header-height-bottom;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background-color: var(--color-background-html);
  transition: $drawer-transition-time $drawer-transition-easing;
  transition-property: opacity;
  opacity: 0;
  padding: 0 $global-spacing;
  color: var(--color-text);

  .accordion__item-content {
    padding-left: 0;
    padding-right: 0;

    .mobile-navigation__sub-category-link {
      @include accordion-sub-category-link;
    }
  }

  &::after {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    content: '';
    background-color: rgba($color-black, 0.1);
    transition: $drawer-transition-time $drawer-transition-easing;
    transition-property: opacity;
    opacity: 0;
  }

  &[aria-hidden='false'] {
    display: block;
  }

  &.is--mobile-navigation-open {
    opacity: 1;
    display: block;
  }

  &.is--drawer-open {
    &::after {
      opacity: 1;
    }
  }

  @include screen-tablet-landscape-and-bigger {
    &[aria-hidden='false'] {
      display: none;
    }

    .header--mobile-nav-open & {
      &[aria-hidden='false'] {
        display: none;
      }
    }
  }
}

.mobile-navigation__scroll-helper {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.mobile-navigation__main-category {
  border-top: 1px solid $color-grey;
  border-bottom: 1px solid $color-grey;
}

.mobile-navigation__main-category-button[class*="c-button"] {
  width: 100%;
  justify-content: space-between;
  padding-top: $global-spacing * 2;
  padding-bottom: $global-spacing * 2;
}

.mobile-navigation__drawer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% + var(--header-container-height));
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: $color-white;
  transform: translate3d(100%, 0, 0);
  transition: $drawer-transition-time $drawer-transition-easing;
  transition-property: transform;
  z-index: 1;
  padding: 0 $global-spacing;

  &[aria-hidden='false'] {
    transform: translate3d(0, 0, 0);
  }

  @include screen-tablet-landscape-and-bigger {
    padding: 0 $global-spacing * 3;
  }
}

.mobile-navigation__drawer-content {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}

.mobile-navigation__back-button[class*="c-button"] {
  padding: $accordion-item-header-padding;
  width: 100%;
}

.mobile-navigation__sub-category-link[class*="c-button"] {
  @include sub-category-link;
}
