//.order-confirmation__hero {}

.order-confirmation__introduction {
  @include screen-tablet-landscape-and-bigger {
    max-width: 65%;
  }
}

.order-confirmation__delivery-address {
  //@include text-small;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0.5em;
  }

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row;
  }
}

//.order-confirmation__order-details {}

.order-confirmation__table {
  width: auto;

  td {
    padding-right: $global-spacing;
  }
}

.order-confirmation__table--bank-transfer {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.order-confirmation__divider {
  min-height: 1px;
  width: 100%;
  @include divider-line;
}

.order-confirmation__estimated-arrival {
  width: 100%;

  @include screen-tablet-landscape-and-bigger {
    width: 55%;
    padding-right: $global-spacing * 2;
  }
}

.order-confirmation__address-summary {
  width: 100%;
  margin-top: $global-spacing * 3;

  @include screen-tablet-landscape-and-bigger {
    margin-top: 0;
  }
}
