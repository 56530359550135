.c-promotion-banner {
  @include z(search);
  position: relative;
  padding: $global-spacing * 2 0;
  text-align: center;

  a {
    text-decoration: none;

    @include hover-focus(false) {
      text-decoration: underline;
    }
  }
}

.promotion-banner__container.o-container {
  position: relative;
  padding: 0 $global-spacing * 6;
}

.promotion-banner__close-button {
  position: absolute;
  right: $global-spacing * 2;
  top: 50%;
  transform: translateY(-50%);

  @include keyboard-focus {
    outline: none;
    @include focus-state-pulse($round: true);
  }
}
