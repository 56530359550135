.c-map {
  position: relative;
  width: 100%;
  height: inherit;
  background-color: $color-grey;
}

.map--no-api-key,
.map--no-consent {
  background-color: $color-grey;

  > div {
    z-index: 1;
  }

  &::before,
  &::after {
    width: 90%;
    display: block;
    text-align: center;
    color: $color-black;
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &::before {
    @include h3;
    top: 50%;
  }

  &::after {
    top: 60%;
  }
}

.map--no-consent {
  &::before {
    content: attr(data-title-no-consent);
  }

  &::after {
    content: attr(data-text-no-consent);
  }
}

.map--no-api-key {
  &::before {
    content: attr(data-title-no-api-key);
  }

  &::after {
    content: attr(data-text-no-api-key);
  }
}
